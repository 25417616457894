import { Injectable } from '@angular/core';

const SERVICES = ['cloudfront', 'youtube', 'vimeo'];

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  constructor() {}

  getServiceVideo(url: string): string {
    let serviceFound = '';
    if (this.isURL(url)) {
      for (let i = 0; i <= SERVICES.length - 1; i++) {
        if (url.indexOf(SERVICES[i]) !== -1) {
          serviceFound = SERVICES[i];
          break;
        }
      }
    } else {
      serviceFound = '';
    }
    return serviceFound;
  }

  isURL(str: string): boolean {
    const regexp =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (regexp.test(str)) {
      return true;
    } else {
      return false;
    }
  }

  getDefaultTemplate(
    url: string,
    height: string,
    width: string,
    autoplay: boolean,
    videoCategory: string,
    videoTitle?: string
  ) {
    // cloudfront
    const type = url.split('.').pop();
    const video = window.document.createElement('VIDEO') as HTMLMediaElement;

    if (video.canPlayType('video/' + type)) {
      video.setAttribute('src', url);
    }

    video.setAttribute('width', width);
    video.setAttribute('height', height);
    video.setAttribute('controls', 'controls');
    if (autoplay) {
      video.setAttribute('autoplay', 'autoplay');
    }

    video.onplay = () => {
      if (videoCategory && url) {
        // this.googleAnalytics.trackEvent(videoCategory, 'play', videoTitle);
      }
    };
    video.onended = () => {
      // console.log('HTML 5 Ended');
    };

    window.document.getElementById('player')!.innerHTML = '';
    window.document.getElementById('player')?.appendChild(video);
  }
}
