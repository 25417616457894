<div *ngIf="hasAlerts" class="card justify-content-center mb-4">
  <div class="d-flex">
    <!-- Yellow Alert Container -->
    <div class="bg-warning d-flex align-items-center">
      <div
        class="card-body d-flex align-items-center justify-content-center py-2 px-3"
      >
        <h3 class="card-title f-font my-0">
          <i class="fa-solid fa-triangle-exclamation me-2"></i>Alerts
        </h3>
      </div>
    </div>
    <!-- Alerts -->
    <div class="card-body p-2">
      <div class="row">
        <!-- Supplier Response Required Alert -->
        <div class="col-4">
          <app-po-alert-item
            [data]="alertsObject.supplierResponseRequired"
            (onClickView)="onFilterByAlert($event)"
          ></app-po-alert-item>
        </div>
        <!-- Update Needed Alert -->
        <div class="col-4">
          <app-po-alert-item
            [data]="alertsObject.updateNeeded"
            [separator]="true"
            (onClickView)="onFilterByAlert($event)"
          ></app-po-alert-item>
        </div>
        <!-- Upcoming Ship Date Alert -->
        <div class="col-4">
          <app-po-alert-item
            [data]="alertsObject.upcomingShipDate"
            [separator]="true"
            (onClickView)="onFilterByAlert($event)"
          ></app-po-alert-item>
        </div>
      </div>
    </div>
  </div>
</div>
