import { IMainMenu } from 'src/app/models/main-menu';

export const mainMenuOptions: IMainMenu[] = [
  //  ======== Events ========
  {
    name: 'Events',
    icon: 'fa-solid fa-calendar-check',
    visible: false,
    route: 'events',
    subItems: [],
  },
  //  ======== Documents ========
  {
    name: 'Documents',
    icon: 'fa-solid fa-file',
    route: 'documents',
    visible: true,
  },
  //  ======== FAQ / Help ========
  {
    name: 'FAQ / Help',
    icon: 'fa-solid fa-circle-question',
    route: 'help',
    visible: true,
  },
  //  ======== Purchase Orders ========
  {
    name: 'Purchase Orders',
    icon: 'fa-solid fa-clipboard',
    route: 'purchase-orders',
    visible: true,
    subItems: [
      {
        title: 'By PO #',
        icon: 'fa-regular fa-file-lines',
        route: 'by-po',
        visible: false,
      },
      {
        title: 'By Model #',
        icon: 'fa-solid fa-list',
        route: 'by-model',
        visible: false,
      },
      {
        title: 'Summary',
        icon: 'f-icon f-purchase-order-icon mb-3',
        route: 'summary',
        visible: false,
      },
    ],
  },
  //  ======== Lookup ========
  {
    name: 'Lookup',
    icon: 'fa-solid fa-barcode',
    route: 'lookup',
    visible: true,
  },
];

export const supplierFactoryMainMenuOptions: IMainMenu[] = [
  //  ======== Events ========
  {
    name: 'Lookup',
    icon: 'fa-solid fa-barcode',
    route: 'lookup',
    visible: true,
  },
];
