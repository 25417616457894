<!-- <app-widgets-manager></app-widgets-manager> -->
<ng-container *ngIf="!loadingDashboard; else loading">
  <div class="container-fluid mt-3">
    <div class="row">
      <app-site-messages [siteMessages]="siteMessages"></app-site-messages>
    </div>
    <div class="row gy-3">
      <div class="col-12 col-xl-6">
        <app-my-announcements
          [isWidget]="true"
          [widgetAnnouncements]="widgetAnnouncements"
          [announcementLoader]="announcementLoader"
          (callGetAnnouncements)="getAnnouncements()"
        ></app-my-announcements>
      </div>
      <div class="col-12 col-xl-6">
        <div class="mb-3">
          <app-my-documents
            [isWidget]="true"
            [userRole]="userRole"
          ></app-my-documents>
        </div>
        <div class="mb-3">
          <app-my-lookup></app-my-lookup>
        </div>
      </div>
      <div class="col-12 col-xl-6"></div>
    </div>
  </div>
</ng-container>
<!-- Loading Dashboard -->
<ng-template #loading>
  <div class="d-flex justify-content-center mt-5">
    <div class="py-5 my-5 text-center f-font">
      <app-fender-tone-loading-div [black]="true"></app-fender-tone-loading-div>
    </div>
  </div>
</ng-template>
