
<!-- <img src="https://dldxryo3ar29q.cloudfront.net/qa/images/guitar-head.jpg" alt="Guitar image B&W" class="w-100"> -->
<div class="footer-safespace"></div>
<footer class="pb-3 pt-4 w-100 position-absolute bottom-0" >
    <div class="container-fluid">
        <div class="text-white text-end text-sm-center text-md-end f-font" >
            <div>
                <span id="copyright-title">Copyright © {{ year }}.</span>&nbsp;
                <span id="copyright-company">Fender Musical Instruments Corporation ©</span>&nbsp;
                <span id="copyright-rights">All rights reserved.</span> </div>
            <p>
                <a id="terms-of-use" href="/documents/TermsofUse-SupplierPortal.pdf"
                target="_blank" class="text-decoration-none">Terms of Use</a>,
                <a id="privacy-policy" href="/documents/PrivacyPolicy-SupplierPortal.pdf"
                target="_blank" class="text-decoration-none">Privacy Policy</a>
            </p>
        </div>
    </div>
</footer>