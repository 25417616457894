import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-menu-sub-menu',
  templateUrl: './main-menu-sub-menu.component.html',
})
export class MainMenuSubMenuComponent implements OnInit {
  @Input() subItems: string[] | undefined = [];
  subMenu: any = [{ title: 'title One' }];

  constructor() {}

  ngOnInit(): void {}

  onClick(subItem: any) {
    // this.trackClicks.emit(subItem);
    // this.hideMobile.emit(subItem);
    console.log('onClick');
  }

  customTrigger(subItem: any) {
    // this.widgetsSelected.emit(true);
    this.onClick(subItem);
    console.log('custom');
  }
}
