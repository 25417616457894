<div
  class="container-fluid py-5"
  [ngClass]="{ 'pt-4': pageIsSummary && hasAlerts }"
>
  <!-- Alerts -->
  <ng-container *ngIf="pageIsSummary && hasAlerts">
    <app-po-alert-banner
      (onHasAlerts)="initAlertBannner($event)"
      (onClickView)="filterByAlert($event)"
    ></app-po-alert-banner>
  </ng-container>

  <!-- Card Header -->
  <div class="card po">
    <div class="card-header small-header-dark">
      <span *ngIf="pageIsByPo" class="card-title f-font m-0"
        >Purchase Orders By PO #</span
      >
      <span *ngIf="pageIsByModel" class="card-title f-font m-0"
        >Purchase Orders By Model #</span
      >
      <span *ngIf="pageIsSummary" class="card-title f-font m-0"
        >Summary
        <span *ngIf="purchaseOrderTotal">
          ( {{ purchaseOrderTotal | number }} open orders found
          <span *ngIf="openQuantityTotal"
            >| Open Qty Total: {{ openQuantityTotal | number }}
          </span>
          )
        </span>
      </span>
    </div>

    <!-- Card Body -->
    <div class="card-body">
      <!-- Filters Container -->
      <div class="d-flex flex-column flex-xl-row justify-content-between mb-3">
        <!-- Search Input Container [Start] -->
        <div class="input-container me-md-2 mb-2 mb-xl-0">
          <div class="input-group">
            <input
              type="text"
              class="form-control f-font"
              placeholder="{{
                pageIsByPo
                  ? 'PO'
                  : pageIsByModel
                  ? 'PO, Model'
                  : pageIsSummary
                  ? 'Search by PO or Supplier'
                  : ''
              }}"
              [formControl]="filterStringControl"
            />
            <button
              class="btn f-btn f-blue f-xs"
              type="button"
              id="button-addon2"
              (click)="getPurchaseOrders()"
            >
              <i class="fa fa-search me-2"></i>
              <span>Search</span>
            </button>
          </div>
        </div>
        <!-- // Search Input Container [End] -->

        <!-- ====== RIGHT Filters ====== -->
        <div class="d-flex flex-column flex-lg-row align-items-start">
          <div
            class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center mb-sm-0"
          >
            <!-- Purcheser Select -->
            <ng-container *ngIf="pageIsSummary">
              <div class="d-flex align-items-center me-3 mb-2 mb-lg-0">
                <label class="f-font text-nowrap me-2">Purchaser:</label>
                <app-new-ui-select
                  #purchaserSelect
                  [options]="purchaserOptions"
                  (onSelection)="filterByPurchaser($event)"
                ></app-new-ui-select>
              </div>
            </ng-container>

            <!-- Create Date Filter -->
            <ng-container *ngIf="pageIsSummary">
              <div class="d-flex align-items-center me-3 mb-2 mb-lg-0">
                <label class="f-font text-nowrap me-2">Date:</label>
                <app-po-dropdown-date-filter
                  #dateRangeFilterDropdown
                  (onDatePickerChange)="filterByDateRangeDropdown($event)"
                ></app-po-dropdown-date-filter>
              </div>
            </ng-container>

            <!-- Status Filter -->
            <ng-container *ngIf="pageIsSummary || pageIsByPo">
              <div class="d-flex align-items-center me-3 mb-2 mb-lg-0">
                <label class="f-font text-nowrap me-2">Status:</label>
                <app-new-ui-select
                  #statusSelect
                  [options]="statusOptions"
                  (onSelection)="filterByStatus($event)"
                ></app-new-ui-select>
              </div>
            </ng-container>

            <!-- Plant Filter Container -->
            <ng-container>
              <div class="d-flex align-items-center me-2 mb-2 mb-lg-0">
                <label class="f-font text-nowrap me-2">Delivery Plant:</label>
                <app-multiselect-dropdown
                  [items]="plantNames"
                  [labelText]="plantLabels"
                  [preselectedValues]="preselectedPlant"
                  [isPO]="true"
                  [loadingOptions]="loadingPlants"
                  [maxPreview]="3"
                  (save)="filterByPlant($event)"
                ></app-multiselect-dropdown>
              </div>
            </ng-container>

            <!-- Product Line Container -->
            <ng-container>
              <div class="d-flex align-items-center me-2 mb-2 mb-lg-0">
                <label class="f-font text-nowrap me-2">Product Line:</label>
                <app-product-line-filter
                  #productLineFilter
                  (checkboxChange)="filterByProductLine($event)"
                ></app-product-line-filter>
              </div>
            </ng-container>

            <!-- Select Create Date Container -->
            <ng-container *ngIf="pageIsByPo || pageIsByModel">
              <div class="d-flex align-items-center">
                <label class="f-font text-nowrap me-2">Create Date:</label>
                <select
                  class="form-select"
                  [(ngModel)]="poFilterParams.createDate"
                  (ngModelChange)="filterByDateRange()"
                >
                  <option
                    value="{{ dateRange.value }}"
                    *ngFor="let dateRange of dateRangeOptions"
                  >
                    {{ dateRange.label }}
                  </option>
                </select>
              </div>
            </ng-container>
          </div>
          <!-- Clear Filters Container -->
          <div class="ms-0 mt-2 ms-lg-3 mt-lg-0 h-100">
            <button
              class="f-btn f-xs clear-filter-btn"
              (click)="clearFilters()"
            >
              Clear Filters
            </button>
          </div>
        </div>
      </div>
      <!-- Purchase Orders Count Banner & Pagination [ MODEL & PO] -->
      <ng-container *ngIf="pageIsByModel || pageIsByPo">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-center count-banner mb-3"
        >
          <div
            class="d-flex flex-column flex-md-row text-center f-font m-0 mb-2 mb-md-0"
          >
            <h3 class="mb-0 me-2">Purchase Orders</h3>
            <small
              class="mb-0"
              *ngIf="purchaseOrdersList.length && !loadingTable"
              >( {{ purchaseOrderTotal }} open orders found )</small
            >
          </div>
          <pagination
            class="m-0 pagination-sm"
            [totalItems]="purchaseOrderTotal"
            [itemsPerPage]="50"
            [maxSize]="5"
            previousText="&lsaquo;"
            nextText="&rsaquo;"
            [(ngModel)]="currentPage"
            (pageChanged)="pageChanged($event)"
            class="fender-pagination pagination-sm dark mb-0"
          >
          </pagination>
        </div>
      </ng-container>
      <!-- Pagination & Export [PAGE SUMMARY] -->
      <ng-container *ngIf="pageIsSummary">
        <div class="d-flex justify-content-end my-4 align-items-center">
          <button class="f-btn f-green f-xs me-3" (click)="donwloadPoReports()">
            <i
              *ngIf="!loadingReport.loader"
              class="fa-regular fa-file-excel"
            ></i>
            <span *ngIf="loadingReport.loader">
              <app-fender-tone-loading-div
                [inline]="true"
              ></app-fender-tone-loading-div>
            </span>
            Export Open Orders
          </button>
          <pagination
            class="m-0 pagination-sm"
            [totalItems]="purchaseOrderTotal"
            [itemsPerPage]="50"
            [maxSize]="5"
            previousText="&lsaquo;"
            nextText="&rsaquo;"
            [(ngModel)]="currentPage"
            (pageChanged)="pageChanged($event)"
            class="fender-pagination pagination-sm dark mb-0"
          >
          </pagination>
        </div>
      </ng-container>

      <!-- Export Button Container -->
      <ng-container *ngIf="pageIsByModel || pageIsByPo">
        <div class="d-flex justify-content-end mb-3">
          <button class="f-btn f-green f-xs" (click)="donwloadPoReports()">
            <i
              *ngIf="!loadingReport.loader"
              class="fa-regular fa-file-excel"
            ></i>
            <span *ngIf="loadingReport.loader">
              <app-fender-tone-loading-div
                [inline]="true"
              ></app-fender-tone-loading-div>
            </span>
            Export Open Orders
          </button>
        </div>
      </ng-container>

      <!-- Table Container -->
      <div>
        <table
          class="f-table table-responsive-xl f-font"
          [class.table-responsive-xxl]="pageIsSummary"
        >
          <thead>
            <tr>
              <!-- Purchaser -->
              <ng-container *ngIf="pageIsSummary">
                <th>
                  <span class="no-sort-table-heading"> Purchaser </span>
                </th>
              </ng-container>
              <!-- PO # -->
              <th>
                <span
                  id="table-heading-ponumber-srt"
                  class="sort-table-heading"
                  role="button"
                  (click)="changeSort('poNumber')"
                  [ngClass]="{ 'f-bold': poFilterParams.sortBy == 'poNumber' }"
                >
                  PO #
                  <i
                    *ngIf="poFilterParams.sortBy == 'poNumber'"
                    class="fa-solid fa-xs"
                    [ngClass]="{
                      'fa-sort-up': poFilterParams.sortType === 'asc',
                      'fa-sort-down': poFilterParams.sortType === 'desc'
                    }"
                  ></i>
                </span>
              </th>
              <!-- Supplier Name -->
              <ng-container *ngIf="pageIsSummary">
                <th>
                  <span class="no-sort-table-heading"> Supplier Name </span>
                </th>
              </ng-container>
              <!-- Supplier Number -->
              <ng-container *ngIf="pageIsSummary">
                <th>
                  <span class="no-sort-table-heading"> Supplier Number </span>
                </th>
              </ng-container>
              <!-- Item # -->
              <ng-container *ngIf="pageIsByModel">
                <th>
                  <span class="no-sort-table-heading"> Item # </span>
                </th>
              </ng-container>
              <!-- PO Create Date -->
              <th>
                <span
                  id="table-heading-createDate-srt"
                  class="sort-table-heading"
                  role="button"
                  (click)="changeSort('createDate')"
                  [ngClass]="{
                    'f-bold': poFilterParams.sortBy == 'createDate'
                  }"
                >
                  PO Create Date
                  <i
                    *ngIf="poFilterParams.sortBy == 'createDate'"
                    class="fa-solid fa-xs"
                    [ngClass]="{
                      'fa-sort-up': poFilterParams.sortType === 'asc',
                      'fa-sort-down': poFilterParams.sortType === 'desc'
                    }"
                  ></i>
                </span>
              </th>
              <!-- Status -->
              <ng-container *ngIf="pageIsByPo">
                <th>
                  <span
                    id="table-heading-status-srt"
                    class="sort-table-heading"
                    role="button"
                    (click)="changeSort('status')"
                    [ngClass]="{ 'f-bold': poFilterParams.sortBy == 'status' }"
                  >
                    Status
                    <i
                      *ngIf="poFilterParams.sortBy == 'status'"
                      class="fa-solid fa-xs"
                      [ngClass]="{
                        'fa-sort-up': poFilterParams.sortType === 'asc',
                        'fa-sort-down': poFilterParams.sortType === 'desc'
                      }"
                    ></i>
                  </span>
                </th>
              </ng-container>
              <!-- Model # -->
              <ng-container *ngIf="pageIsByModel">
                <th>
                  <span
                    id="table-heading-modelNumber-srt"
                    class="sort-table-heading"
                    role="button"
                    (click)="changeSort('modelNumber')"
                    [ngClass]="{
                      'f-bold': poFilterParams.sortBy == 'modelNumber'
                    }"
                  >
                    Model #
                    <i
                      *ngIf="poFilterParams.sortBy == 'modelNumber'"
                      class="fa-solid fa-xs"
                      [ngClass]="{
                        'fa-sort-up': poFilterParams.sortType === 'asc',
                        'fa-sort-down': poFilterParams.sortType === 'desc'
                      }"
                    ></i>
                  </span>
                </th>
              </ng-container>
              <!-- Model Name -->
              <ng-container *ngIf="pageIsByModel">
                <th>
                  <span class="no-sort-table-heading"> Model Name </span>
                </th>
              </ng-container>
              <!-- Plant -->
              <th>
                <span
                  id="table-heading-plant-srt"
                  class="sort-table-heading"
                  role="button"
                  (click)="changeSort('plant')"
                  [ngClass]="{ 'f-bold': poFilterParams.sortBy == 'plant' }"
                >
                  Delivery Plant
                  <i
                    *ngIf="poFilterParams.sortBy == 'plant'"
                    class="fa-solid fa-xs"
                    [ngClass]="{
                      'fa-sort-up': poFilterParams.sortType === 'asc',
                      'fa-sort-down': poFilterParams.sortType === 'desc'
                    }"
                  ></i>
                </span>
              </th>
              <!-- Original Ship Date [ Summary ] -->
              <ng-container *ngIf="pageIsSummary">
                <th>
                  <span class="no-sort-table-heading">
                    Original Ship Date
                  </span>
                </th>
              </ng-container>
              <!-- Current Ship Date [ Summary ] -->
              <ng-container *ngIf="pageIsSummary">
                <th>
                  <span class="no-sort-table-heading"> Current Ship Date </span>
                </th>
              </ng-container>
              <!-- Production Month  -->
              <ng-container *ngIf="pageIsSummary">
                <th>
                  <span
                    id="table-heading-productionMonth-srt"
                    class="sort-table-heading w-25"
                    role="button"
                    (click)="changeSort('productionMonth')"
                    [ngClass]="{
                      'f-bold': poFilterParams.sortBy == 'productionMonth'
                    }"
                  >
                    Production Month
                    <i
                      *ngIf="poFilterParams.sortBy == 'productionMonth'"
                      class="fa-solid fa-xs"
                      [ngClass]="{
                        'fa-sort-up': poFilterParams.sortType === 'asc',
                        'fa-sort-down': poFilterParams.sortType === 'desc'
                      }"
                    ></i>
                  </span>
                </th>
              </ng-container>

              <!-- Open Qty -->
              <th>
                <span
                  id="table-heading-openQuantity-srt"
                  class="sort-table-heading"
                  role="button"
                  (click)="changeSort('openQuantity')"
                  [ngClass]="{
                    'f-bold': poFilterParams.sortBy == 'openQuantity'
                  }"
                >
                  Open Qty
                  <i
                    *ngIf="poFilterParams.sortBy == 'openQuantity'"
                    class="fa-solid fa-xs"
                    [ngClass]="{
                      'fa-sort-up': poFilterParams.sortType === 'asc',
                      'fa-sort-down': poFilterParams.sortType === 'desc'
                    }"
                  ></i>
                </span>
              </th>
              <!-- Product Line -->
              <ng-container *ngIf="pageIsSummary">
                <th>
                  <span
                    id="table-heading-productLines-srt"
                    class="no-sort-table-heading"
                    [ngClass]="{
                      'f-bold': poFilterParams.sortBy == 'productLines'
                    }"
                  >
                    Product Line
                    <i
                      *ngIf="poFilterParams.sortBy == 'productLines'"
                      class="fa-solid fa-xs"
                      [ngClass]="{
                        'fa-sort-up': poFilterParams.sortType === 'asc',
                        'fa-sort-down': poFilterParams.sortType === 'desc'
                      }"
                    ></i>
                  </span>
                </th>
              </ng-container>

              <!-- Open Value -->
              <th>
                <span
                  id="table-heading-openValue-srt"
                  class="sort-table-heading"
                  role="button"
                  (click)="changeSort('openValue')"
                  [ngClass]="{ 'f-bold': poFilterParams.sortBy == 'openValue' }"
                >
                  Open Value
                  <i
                    *ngIf="poFilterParams.sortBy == 'openValue'"
                    class="fa-solid fa-xs"
                    [ngClass]="{
                      'fa-sort-up': poFilterParams.sortType === 'asc',
                      'fa-sort-down': poFilterParams.sortType === 'desc'
                    }"
                  ></i>
                </span>
              </th>
              <!-- Production Month -->
              <ng-container *ngIf="pageIsByModel">
                <th>
                  <span
                    id="table-heading-productionMonth-srt"
                    class="sort-table-heading w-25"
                    role="button"
                    (click)="changeSort('productionMonth')"
                    [ngClass]="{
                      'f-bold': poFilterParams.sortBy == 'productionMonth'
                    }"
                  >
                    Production Month
                    <i
                      *ngIf="poFilterParams.sortBy == 'productionMonth'"
                      class="fa-solid fa-xs"
                      [ngClass]="{
                        'fa-sort-up': poFilterParams.sortType === 'asc',
                        'fa-sort-down': poFilterParams.sortType === 'desc'
                      }"
                    ></i>
                  </span>
                </th>
              </ng-container>

              <!-- Original Ship Date -->
              <ng-container *ngIf="pageIsByModel">
                <th>
                  <span
                    id="table-heading-originalShipDate-srt"
                    class="sort-table-heading w-25"
                    role="button"
                    (click)="changeSort('originalShipDate')"
                    [ngClass]="{
                      'f-bold': poFilterParams.sortBy == 'originalShipDate'
                    }"
                  >
                    Original Ship Date
                    <i
                      *ngIf="poFilterParams.sortBy == 'originalShipDate'"
                      class="fa-solid fa-xs"
                      [ngClass]="{
                        'fa-sort-up': poFilterParams.sortType === 'asc',
                        'fa-sort-down': poFilterParams.sortType === 'desc'
                      }"
                    ></i>
                  </span>
                </th>
              </ng-container>
              <!-- Current Ship Date -->
              <ng-container *ngIf="pageIsByModel">
                <th>
                  <span
                    id="table-heading-currentShipDate-srt"
                    class="sort-table-heading w-25"
                    role="button"
                    (click)="changeSort('currentShipDate')"
                    [ngClass]="{
                      'f-bold': poFilterParams.sortBy == 'currentShipDate'
                    }"
                  >
                    Current Ship Date
                    <i
                      *ngIf="poFilterParams.sortBy == 'currentShipDate'"
                      class="fa-solid fa-xs"
                      [ngClass]="{
                        'fa-sort-up': poFilterParams.sortType === 'asc',
                        'fa-sort-down': poFilterParams.sortType === 'desc'
                      }"
                    ></i>
                  </span>
                </th>
              </ng-container>
              <!-- Shipped Qty -->
              <th>
                <!-- <span class="no-sort-table-heading w-25"> Shipped Qty </span> -->
                <span
                  id="table-heading-shippedQuantity-srt"
                  class="sort-table-heading"
                  role="button"
                  (click)="changeSort('shippedQuantity')"
                  [ngClass]="{
                    'f-bold': poFilterParams.sortBy == 'shippedQuantity'
                  }"
                >
                  Shipped Qty
                  <i
                    *ngIf="poFilterParams.sortBy == 'shippedQuantity'"
                    class="fa-solid fa-xs"
                    [ngClass]="{
                      'fa-sort-up': poFilterParams.sortType === 'asc',
                      'fa-sort-down': poFilterParams.sortType === 'desc'
                    }"
                  ></i>
                </span>
              </th>
              <!-- Status -->
              <ng-container *ngIf="pageIsSummary">
                <th>
                  <span class="no-sort-table-heading"> Status</span>
                </th>
              </ng-container>

              <!-- Download PDF -->
              <th [ngClass]="{ 'width-120': pageIsByPo }">
                <span class="no-sort-table-heading"> Download PDF</span>
              </th>
            </tr>
          </thead>
          <!-- ======================== Table Body======================== -->
          <tbody>
            <tr
              *ngFor="
                let purchaseOrder of purchaseOrdersList;
                let resultIndex = index
              "
            >
              <!-- Purchaser -->
              <ng-container *ngIf="pageIsSummary">
                <td data-label="Purchaser">
                  {{
                    purchaseOrder.purchaserName
                      ? purchaseOrder.purchaserName
                      : purchaseOrder.emailId
                      ? purchaseOrder.emailId.split("@")[0].toLowerCase() +
                        "&#8203;@" +
                        purchaseOrder.emailId.split("@")[1].toLowerCase()
                      : " &nbsp;"
                  }}
                </td>
              </ng-container>

              <!-- PO # -->
              <td data-label="PO#">
                <a
                  class="f-font cursor-pointer"
                  (click)="
                    goToPurchaseOrderDetail(
                      purchaseOrder.poNumber,
                      pageIsSummary ? purchaseOrder.supplierNumber : ''
                    )
                  "
                  >{{ purchaseOrder.poNumber || "&nbsp;" }}</a
                >
              </td>
              <!-- Supplier Name -->
              <ng-container *ngIf="pageIsSummary">
                <td data-label="Purchaser">
                  {{ purchaseOrder.supplierName || "&nbsp;" }}
                </td>
              </ng-container>
              <!-- Supplier Number -->
              <ng-container *ngIf="pageIsSummary">
                <td data-label="Purchaser">
                  {{ purchaseOrder.supplierNumber || "&nbsp;" }}
                </td>
              </ng-container>
              <!-- Item # -->
              <ng-container *ngIf="pageIsByModel">
                <td data-label="Item #">
                  {{ purchaseOrder.poItem | number : "1.0-0" || "&nbsp;" }}
                </td>
              </ng-container>
              <!-- PO Create Date -->
              <td data-label="PO Create Date">
                {{ purchaseOrder.createDate | date : dateFormat || "&nbsp;" }}
              </td>
              <!-- Status -->
              <ng-container *ngIf="pageIsByPo">
                <td data-label="Status">
                  {{ purchaseOrder.status || "&nbsp;" }}
                </td>
              </ng-container>
              <!-- Model # -->
              <ng-container *ngIf="pageIsByModel">
                <td data-label="Model #">
                  {{ purchaseOrder.modelNumber || "&nbsp;" }}
                </td>
              </ng-container>
              <!-- Model Name -->
              <ng-container *ngIf="pageIsByModel">
                <td data-label="Model Name">
                  {{ purchaseOrder.modelName || "&nbsp;" }}
                </td>
              </ng-container>
              <!-- Delivery Plant -->
              <td data-label="Delivery Plant">
                {{ purchaseOrder.plant || "&nbsp;" }}
              </td>
              <!-- Original Ship Date -->
              <ng-container *ngIf="pageIsSummary">
                <td data-label="Open Qty">
                  {{
                    purchaseOrder.originalShipDate
                      | date : dateFormat || "&nbsp;"
                  }}
                </td>
              </ng-container>

              <!-- Current Ship Date -->
              <ng-container *ngIf="pageIsSummary">
                <td data-label="Open Qty">
                  {{
                    purchaseOrder.currentShipDate
                      | date : dateFormat || "&nbsp;"
                  }}
                </td>
              </ng-container>
              <!-- Production Month -->
              <ng-container *ngIf="pageIsSummary">
                <td data-label="Production Month">
                  {{
                    purchaseOrder.productionMonth
                      ? (purchaseOrder.productionMonth | date : "MMMM y")
                      : "&nbsp;"
                  }}
                </td>
              </ng-container>
              <!-- Open Qty -->
              <td data-label="Open Qty">
                {{ purchaseOrder.openQuantity | number : "1.0-0" || "&nbsp;" }}
              </td>
              <!-- Product Line -->
              <ng-container *ngIf="pageIsSummary">
                <td data-label="Product Line">
                  <ng-container *ngIf="purchaseOrder.productLines?.length">
                    <app-show-more
                      [showArray]="
                        purchaseOrder.productLines
                          ? purchaseOrder.productLines
                          : []
                      "
                    ></app-show-more>
                  </ng-container>
                </td>
              </ng-container>

              <!-- Open Value -->
              <td data-label="Open Value">
                {{
                  purchaseOrder.currency &&
                  currencyArray.includes(purchaseOrder.currency)
                    ? (purchaseOrder.openValue
                      | currency
                        : returnCurrencyFormat(purchaseOrder.currency, "code")
                        : "symbol"
                        : returnCurrencyFormat(
                            purchaseOrder.currency,
                            "decimal"
                          ))
                    : purchaseOrder.openValue
                }}
              </td>
              <!-- Delivery Date -->
              <ng-container *ngIf="pageIsByModel">
                <td data-label="Delivery Date">
                  {{
                    purchaseOrder.productionMonth | date : "MMMM y" || "&nbsp;"
                  }}
                </td>
              </ng-container>
              <!-- Original Ship Date -->
              <ng-container *ngIf="pageIsByModel">
                <td data-label="Original Ship Date">
                  {{
                    purchaseOrder.originalShipDate
                      | date : dateFormat || "&nbsp;"
                  }}
                </td>
              </ng-container>
              <!-- Current Ship Date -->
              <ng-container *ngIf="pageIsByModel">
                <td data-label="Current Ship Date">
                  {{
                    purchaseOrder.currentShipDate
                      | date : dateFormat || "&nbsp;"
                  }}
                </td>
              </ng-container>
              <!-- Shipped Qty -->
              <td data-label="Shipped Qty">
                {{
                  purchaseOrder.shippedQuantity | number : "1.0-0" || "&nbsp;"
                }}
              </td>
              <!-- Status -->
              <ng-container *ngIf="pageIsSummary">
                <td data-label="Status">
                  <app-po-status-select-table
                    [poInfo]="purchaseOrder"
                    [preselectedOption]="purchaseOrder.status"
                  ></app-po-status-select-table>
                </td>
              </ng-container>

              <!-- Download PDF -->
              <td
                data-label="Download PDF"
                class="text-end text-md-start text-xl-center"
              >
                <button
                  class="f-btn btn-std f-red f-xs has-gif smaller"
                  (click)="
                    donwloadPdf(purchaseOrder.poNumber || '', resultIndex)
                  "
                >
                  <span
                    *ngIf="
                      loadingPoPdf.loader && loadingPoPdfIndex === resultIndex
                    "
                  >
                    <app-fender-tone-loading-div
                      [inline]="true"
                      [whiteF]="true"
                    ></app-fender-tone-loading-div>
                  </span>
                  <i
                    *ngIf="
                      !loadingPoPdf.loader || loadingPoPdfIndex !== resultIndex
                    "
                    class="fa-solid fa-cloud-arrow-down m-0 fa-sm"
                  ></i>
                </button>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="loadingTable">
            <tr class="text-center">
              <td colspan="15" class="pt-3">
                <app-fender-tone-loading-div
                  [widget]="true"
                  [black]="true"
                ></app-fender-tone-loading-div>
                <h4 class="f-font mt-3">Loading PO Results</h4>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!purchaseOrdersList.length && !loadingTable">
            <tr>
              <td colspan="15">
                <div class="d-flex justify-content-center my-5">
                  <span class="f-font"> No results found </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <ng-container *ngIf="pageIsSummary">
        <div class="d-flex justify-content-end my-3">
          <pagination
            class="m-0 pagination-sm"
            [totalItems]="purchaseOrderTotal"
            [itemsPerPage]="50"
            [maxSize]="5"
            previousText="&lsaquo;"
            nextText="&rsaquo;"
            [(ngModel)]="currentPage"
            (pageChanged)="pageChanged($event)"
            class="fender-pagination pagination-sm dark mb-0"
          >
          </pagination>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<!-- ============= Toast Alerts ============ -->
<!-- Error Toast -->
<app-toast-alert
  #errorToast
  [selector]="'error'"
  [title]="'Alert'"
  [description]="toastErrorText"
  [style]="'bg-warning'"
></app-toast-alert>
