import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-widgets-manager',
  templateUrl: './widgets-manager.component.html',
})
export class WidgetsManagerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  showWidgetsConfig(): void {}
}
