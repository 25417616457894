<form [formGroup]="parentForm">
  <div formGroupName="shipmentQuestionForm">
    <!-- a. Shipment Type -->
    <div class="d-flex flex-column flex-lg-row align-items-lg-center mb-3">
      <label
        for="a"
        class="form-label f-font text-start required text-nowrap mb-1 mb-lg-0 me-2"
        >Shipment Type</label
      >
      <div class="input-group has-validation">
        <select
          class="form-select f-font"
          formControlName="shipmentType"
          [ngClass]="{
            'is-invalid':
              shipmentQuestionForm.get('shipmentType')?.invalid &&
              shipmentQuestionForm.get('shipmentType')?.touched
          }"
        >
          <option selected disabled>Select an option</option>
          <option *ngFor="let type of shipmentTypeSelect" value="{{ type }}">
            {{ type }}
          </option>
        </select>
        <div class="invalid-feedback">This field is required.</div>
      </div>
    </div>
    <!-- b. What is being shipped? -->
    <div class="d-flex flex-column flex-lg-row align-items-lg-center mb-3">
      <label
        for="a"
        class="form-label f-font text-start required text-nowrap mb-1 mb-lg-0 me-2"
        >What is being shipped?</label
      >
      <div class="input-group has-validation">
        <select
          class="form-select f-font"
          formControlName="shipmentItem"
          [ngClass]="{
            'is-invalid':
              shipmentQuestionForm.get('shipmentItem')?.invalid &&
              shipmentQuestionForm.get('shipmentItem')?.touched
          }"
        >
          <option selected disabled>Select an option</option>
          <option *ngFor="let item of shipmentItemSelect" value="{{ item }}">
            {{ item }}
          </option>
        </select>
        <div class="invalid-feedback">This field is required.</div>
      </div>
    </div>
    <!-- c. Does it contain lithium batteries? -->
    <div class="d-flex flex-column flex-lg-row align-items-lg-center py-1 mb-3">
      <label
        for="a"
        class="form-label f-font text-start required text-nowrap mb-1 mb-lg-0 me-2"
        >Does it contain lithium batteries?</label
      >
      <div class="d-flex">
        <div class="form-check me-3">
          <input
            class="form-check-input"
            type="radio"
            name="containLiBatteries"
            id="hazmat-yes"
            value="Yes"
            formControlName="containLiBatteries"
          />
          <label class="form-check-label" for="hazmat-yes"> Yes </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="containLiBatteries"
            id="hazmat-no"
            value="No"
            formControlName="containLiBatteries"
          />
          <label class="form-check-label" for="hazmat-no"> No </label>
        </div>
      </div>
    </div>
    <!-- Is this a hazmat/dangerous good? -->
    <div class="d-flex flex-column flex-lg-row align-items-lg-center py-1 mb-3">
      <label
        for="a"
        class="form-label f-font text-start required text-nowrap mb-1 mb-lg-0 me-2"
        >Is this a hazmat/dangerous good?</label
      >
      <div class="d-flex">
        <div class="form-check me-3">
          <input
            class="form-check-input"
            type="radio"
            name="isDangerItem"
            id="hazmat-yes"
            value="Yes"
            formControlName="isDangerItem"
          />
          <label class="form-check-label" for="hazmat-yes"> Yes </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="isDangerItem"
            id="hazmat-no"
            value="No"
            formControlName="isDangerItem"
          />
          <label class="form-check-label" for="hazmat-no"> No </label>
        </div>
      </div>
    </div>

    <!-- Ship from location -->
    <div class="d-flex flex-column flex-lg-row align-items-lg-center mb-3">
      <label
        for="a"
        class="form-label f-font text-start required text-nowrap mb-1 mb-lg-0 me-2"
        >Ship from location</label
      >
      <div class="input-group has-validation">
        <input
          type="text"
          class="form-control f-font"
          formControlName="fromLocation"
          [ngClass]="{
            'is-invalid':
              shipmentQuestionForm.get('fromLocation')?.invalid &&
              shipmentQuestionForm.get('fromLocation')?.touched
          }"
        />
        <div class="invalid-feedback">This field is required.</div>
      </div>
    </div>
    <!-- Padding Section -->
    <div class="px-5 my-4">
      <!-- Number of boxes -->
      <div class="d-flex flex-column flex-lg-row align-items-lg-center mb-3">
        <label
          for="a"
          class="form-label f-font text-start text-nowrap mb-1 mb-lg-0 me-2"
          >Number of boxes</label
        >
        <select
          class="form-select f-font number-select-input"
          formControlName="numberOfBoxes"
        >
          <option selected disabled>Select number</option>
          <option
            *ngFor="let item of [].constructor(20); let i = index"
            value="{{ i + 1 }}"
          >
            {{ i + 1 }}
          </option>
        </select>
      </div>
      <!-- Weight -->
      <div class="d-flex flex-column flex-lg-row align-items-lg-center mb-3">
        <label
          for="a"
          class="form-label f-font text-start text-nowrap mb-1 mb-lg-0 me-2"
          >Weight</label
        >
        <input
          type="text"
          class="form-control f-font"
          formControlName="weight"
          placeholder="Include unit of measurement. Ex: 50lbs"
        />
      </div>
      <!-- Dimension -->
      <div class="d-flex flex-column flex-lg-row align-items-lg-center mb-3">
        <label
          for="a"
          class="form-label f-font text-start text-nowrap mb-1 mb-lg-0 me-2"
          >Dimension</label
        >
        <div class="input-group has-validation">
          <input
            type="text"
            class="form-control f-font mb-2 mb-lg-0 me-lg-2"
            formControlName="length"
            placeholder="Length (Ex: 5ft.)"
            [ngClass]="{
              'is-invalid':
                shipmentQuestionForm.get('length')?.invalid &&
                shipmentQuestionForm.get('length')?.touched
            }"
          />
          <div class="invalid-feedback">
            <small>Must be less than 50 characters.</small>
          </div>
        </div>
        <div class="input-group has-validation">
          <input
            type="text"
            class="form-control f-font mb-2 mb-lg-0 me-lg-2"
            formControlName="width"
            placeholder="Width (Ex: 5ft.)"
            [ngClass]="{
              'is-invalid':
                shipmentQuestionForm.get('width')?.invalid &&
                shipmentQuestionForm.get('width')?.touched
            }"
          />
          <div class="invalid-feedback">
            <small>Must be less than 50 characters.</small>
          </div>
        </div>
        <div class="input-group has-validation">
          <input
            type="text"
            class="form-control f-font mb-2 mb-lg-0 me-lg-2"
            formControlName="height"
            placeholder="Height (Ex: 5ft.)"
            [ngClass]="{
              'is-invalid':
                shipmentQuestionForm.get('height')?.invalid &&
                shipmentQuestionForm.get('height')?.touched
            }"
          />
          <div class="invalid-feedback">
            <small>Must be less than 50 characters.</small>
          </div>
        </div>
        {{ dimensions }}
      </div>
      <!-- Brands Included -->
      <div
        class="d-flex flex-column flex-lg-row align-items-lg-center py-1 mb-3"
      >
        <label
          for="a"
          class="form-label f-font text-start text-nowrap mb-1 mb-lg-0 me-2"
          >Brands Included</label
        >
        <div *ngFor="let brand of brandsIcludedSelect" class="form-check me-3">
          <input
            class="form-check-input"
            type="checkbox"
            value="{{ brand }}"
            id="checkbox-{{ brand }}"
            formArrayName="brands"
            (change)="onBrandsCheckboxChange($event)"
          />
          <label class="form-check-label" for="checkbox-{{ brand }}">
            {{ brand }}
          </label>
        </div>
      </div>
    </div>
    <!-- Destination Location -->
    <div class="d-flex flex-column flex-lg-row align-items-lg-center mb-3">
      <label
        for="a"
        class="form-label f-font text-start required text-nowrap mb-1 mb-lg-0 me-2"
        >Destination Location</label
      >
      <div class="input-group has-validation">
        <input
          type="text"
          class="form-control f-font"
          formControlName="destinationLocation"
          [ngClass]="{
            'is-invalid':
              shipmentQuestionForm.get('destinationLocation')?.invalid &&
              shipmentQuestionForm.get('destinationLocation')?.touched
          }"
        />
        <div class="invalid-feedback">This field is required.</div>
      </div>
    </div>
  </div>
</form>
