<div class="container-fluid py-5">
  <div class="col-12">
    <app-my-documents
      *ngIf="mUser"
      [isWidget]="false"
      [userFormats]="userFormats"
      [userRole]="userRole"
    ></app-my-documents>
  </div>
</div>
