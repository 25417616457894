<div class="container mt-5 padding-bottom-auth">
  <div class="d-flex justify-content-center align-items-center">
    <div class="card width-auth align-center">
      <form
        id="resetpasswordform"
        name="resetpasswordform"
        [formGroup]="completePasswordForm"
      >
        <div class="card-header py-3 f-font">
          <h3 class="f-font">{{ "Reset Your Password" }}</h3>
        </div>
        <div class="card-body">
          <label for="newPasswordInput" class="f-font">{{
            "Enter new password"
          }}</label>
          <div class="input-group mb-3">
            <input
              id="newPasswordInput"
              name="password"
              type="password"
              autofocus
              class="form-control"
              formControlName="newPassword"
              [ngClass]="{
                'is-invalid':
                  completePasswordForm.get('newPassword')?.invalid &&
                  completePasswordForm.get('newPassword')?.touched
              }"
            />
            <span class="input-group-text">
              <i class="fa-solid fa-lock"></i>
            </span>
          </div>

          <label for="confirmPasswordInput" class="f-font"
            >Confirm new password</label
          >
          <div class="input-group mb-3">
            <input
              id="confirmPasswordInput"
              name="password"
              type="password"
              class="form-control"
              formControlName="confirmPassword"
              [ngClass]="{
                'is-invalid':
                  completePasswordForm.hasError('passwordsMismatch') &&
                  completePasswordForm.get('confirmPassword')?.touched
              }"
            />
            <span class="input-group-text">
              <i class="fa-solid fa-lock"></i>
            </span>
          </div>
          <div>
            <a
              class="f-font"
              data-bs-toggle="popover"
              data-bs-trigger="hover focus"
              data-bs-placement="bottom"
              data-bs-content="Password must be at least 8 characters in length, and must contain at least 1 character from each of the following categories: Uppercase letters (A-Z), Lowercase letters (a-z) Numbers (Base 10 digits 0 €&quot; 9), and special characters from this list ('~!@#$%^&*-+=|[]{}:;&lt;>,.?/)."
            >
              Password Policies
              <i class="fa-solid fa-circle-question"></i>
            </a>
          </div>
          <div class="mb-3">
            <a href="login" class="f-font">{{ "Return To Login" }}</a>
          </div>

          <div
            class="alert alert-danger lh-sm my-3"
            role="alert"
            *ngIf="
              completePasswordForm.get('newPassword')?.invalid &&
              completePasswordForm.get('newPassword')?.touched
            "
          >
            <span class="f-font">
              Password does not comply with FMIC password policies.
            </span>
          </div>
          <div
            class="alert alert-danger lh-sm my-3"
            role="alert"
            *ngIf="
              completePasswordForm.hasError('passwordsMismatch') &&
              completePasswordForm.get('confirmPassword')?.touched
            "
          >
            <span class="f-font"> Passwords must match. </span>
          </div>
          <div
            class="alert alert-success lh-sm my-3"
            role="alert"
            *ngIf="isSuccess"
          >
            <span class="f-font">
              Password Successfully Updated You Will Be Redirected to Login.
            </span>
          </div>
        </div>

        <div class="card-footer d-flex justify-content-end py-3">
          <button
            id="submitPasswordButton"
            class="f-btn f-xs f-blue"
            [disabled]="!completePasswordForm.valid"
            (click)="completePassword()"
            (keyup.enter)="completePassword()"
          >
            <span> <i class="f-icon f-revert f-white"></i>Send </span>
            <span *ngIf="loading">
              <app-fender-tone-loading-div
                [inline]="true"
              ></app-fender-tone-loading-div>
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
