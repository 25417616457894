import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SupplierService } from '../suppliers/supplier.service';
import { ISupplier } from 'src/app/models/supplier';

@Injectable({
  providedIn: 'root',
})
export class SelectSupplierHelperService {
  public selectedSupplier: BehaviorSubject<string | undefined>;
  public selectedSupplierName: BehaviorSubject<string>;
  constructor(private supplierService: SupplierService) {
    this.selectedSupplier = new BehaviorSubject<string | undefined>('');
    this.selectedSupplierName = new BehaviorSubject<string>('');
  }

  // Supplier Number Value
  getValue(): Observable<string | undefined> {
    return this.selectedSupplier.asObservable();
  }
  setValue(newSupplierNumber: string | undefined): void {
    this.selectedSupplier.next(newSupplierNumber);
    this.supplierService.getSupplier(newSupplierNumber!).subscribe({
      next: (supplier: ISupplier) => {
        this.setNameValue(supplier.name!);
      },
    });
  }
  // Supplier Name Value
  setNameValue(supplierName: string): void {
    this.selectedSupplierName.next(supplierName);
  }
}
