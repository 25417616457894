import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-pixel-stepper',
  templateUrl: './pixel-stepper.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PixelStepperComponent),
      multi: true,
    },
  ],
})
export class PixelStepperComponent implements OnInit {
  constructor() {}

  public currentValue = 0;
  public isDisabled: boolean = false;
  public invalidPixels: boolean = false;

  ngOnInit(): void {}

  onChange = (_: any) => {};
  onTouch = () => {};

  validatePixels(pixels: any, input: any): void {
    if (input.value !== '') {
      const qty = parseInt(pixels) || 0;
      if (qty < 100 && qty > 0) {
        this.writeValue(pixels);
      } else if (qty > 100) {
        this.writeValue(100);
        input.value = this.currentValue;
      } else if (qty < 0) {
        this.currentValue = 0;
        input.value = this.currentValue;
      }
    } else {
      this.currentValue = 0;
      input.value = this.currentValue;
    }
    this.onTouch();
    this.onChange(this.currentValue);
  }

  add(): void {
    if (this.currentValue < 100) {
      this.currentValue = this.currentValue + 1;
      this.onTouch();
      this.onChange(this.currentValue);
    }
  }

  sub(): void {
    if (this.currentValue > 0) {
      this.currentValue = this.currentValue - 1;
      this.onTouch();
      this.onChange(this.currentValue);
    }
  }

  writeValue(value: number): void {
    if (value) {
      this.currentValue = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
