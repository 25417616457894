import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPoAlertsForUi } from 'src/app/models/purchase-orders';

@Component({
  selector: 'app-po-alert-item',
  templateUrl: './po-alert-item.component.html',
})
export class PoAlertItemComponent implements OnInit {
  @Input() data: IPoAlertsForUi | undefined;
  @Input() separator: boolean = false;

  @Output() onClickView = new EventEmitter<IPoAlertsForUi>();

  constructor() {}

  ngOnInit(): void {}

  onClick(alert: IPoAlertsForUi) {
    this.onClickView.emit(alert);
  }
}
