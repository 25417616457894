import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';

import { PurchaseOrdersRoutingModule } from './purchase-orders-routing.module';
import { PurchaseOrderDetailsComponent } from './purchase-order-details/purchase-order-details.component';
import { PurchaseOrdersPageComponent } from './purchase-orders-page/purchase-orders-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { UiModule } from 'src/app/ui/ui.module';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProductLineFilterComponent } from './components/product-line-filter/product-line-filter.component';
import { ProductLineCheckboxComponent } from './components/product-line-checkbox/product-line-checkbox.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';

@NgModule({
  declarations: [
    PurchaseOrdersPageComponent,
    PurchaseOrderDetailsComponent,
    ProductLineFilterComponent,
    ProductLineCheckboxComponent,
  ],
  imports: [
    CommonModule,
    PurchaseOrdersRoutingModule,
    UiModule,
    FormsModule,
    ReactiveFormsModule,
    PaginationModule.forRoot(),
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
  ],
  providers: [CurrencyPipe],
})
export class PurchaseOrdersModule {}
