import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectionStrategy,
  OnChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
// import { LocalizeService } from '../../../../../services/_common/localize.service';

@Component({
  selector: 'circle-progress-bar',
  templateUrl: 'circle-progress-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircleProgressBarComponent
  implements OnInit, OnDestroy, OnChanges {
  @Input() progressPercentage: number = 0;

  public radius = 40;
  public circumference = this.radius * 2 * Math.PI;
  public progress: number = 0;

  // public translatedText: {[key: string]: any};

  private sub: Subscription = new Subscription();

  constructor() // private localizeService: LocalizeService
  { }

  ngOnChanges(changes: any) {
    if (changes.progressPercentage.currentValue) {
      this.progressPercentage = changes.progressPercentage.currentValue;
      this.calcPercentProgress();
    }
  }

  ngOnInit() {
    // this.getTranslations();
    this.calcPercentProgress();
  }

  getTranslations() {
    // this.sub.add(
    //     this.localizeService.translations$.subscribe((translatedText) => {
    //         this.translatedText = translatedText || {};
    //     })
    // );
  }

  calcPercentProgress() {
    if(!this.progressPercentage){
      this.progressPercentage = 0;
    }
    if (typeof this.progressPercentage === 'string') {
      this.progressPercentage = parseInt(this.progressPercentage);
    }
    this.progress = this.circumference - this.progressPercentage / 100 * this.circumference;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
