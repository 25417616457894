<div class="modal-header border-bottom-0">
  <h3 class="modal-title pull-left f-font f-bold">Add a Note</h3>
  <div type="button" aria-label="Close" (click)="closeNoteModal()">
    <i class="fa-regular fa-circle-xmark fa-xl"></i>
  </div>
</div>
<div class="modal-body">
  <div>
    <p class="f-font"><span class="f-bold">PO#: </span>{{ poNumber }}</p>
  </div>
  <div>
    <div class="form-group">
      <textarea
        #noteTextArea
        class="form-control"
        [formControl]="noteControl"
        rows="6"
        [ngClass]="{
          'is-invalid': noteTextArea.value.length > 500
        }"
      ></textarea>
      <div class="d-flex justify-content-end mt-1">
        <small
          class="f-font m-0"
          [ngClass]="{
            'text-danger': noteTextArea.value.length > 500
          }"
          >Characters Remaining:&nbsp;{{
            500 - noteTextArea.value.length
          }}/500</small
        >
      </div>
    </div>
  </div>
</div>
<div class="modal-footer border-top-0">
  <div class="d-flex">
    <button
      class="f-btn f-green f-xs me-2"
      [disabled]="!noteControl.valid || noteControl.pristine"
      (click)="saveNote()"
    >
      <i class="fa-regular fa-floppy-disk"></i>
      <span class="me-2">Save</span>
    </button>
    <button class="f-btn f-black f-xs" (click)="closeNoteModal()">
      Cancel
    </button>
  </div>
</div>
