import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ILookupImages } from '../../models/lookup';

@Component({
  selector: 'app-image-slideshow',
  templateUrl: './image-slideshow.component.html',
})
export class ImageSlideshowComponent implements OnInit {
  @Input() images!: ILookupImages;
  @Input() lookupArrangement: boolean = false;
  @Input() mainMaterialInfo: any = {};
  @Output() thumbnailClick = new EventEmitter<string>();
  @Output() zoomClick = new EventEmitter<string>();
  modalRef?: BsModalRef;
  imageSelected: any = 0;
  imageLoading: boolean = true;
  imageObject: any = {
    arrayPosition: 0,
    mainThumbnailAll: '',
    mainZoomAll: '',
    mainMedAll: '',
    mainLgAll: '',
    mainXLgAll: '',
  };
  imageUrl: string = 'https://www.fmicassets.com/Damroot/';
  noImages: boolean = false;
  constructor(private modalService: BsModalService) {}

  ngOnInit(): void {
    this.setImageSelectedObject(this.imageSelected);
  }

  selectImage(values: any) {
    if (this.imageSelected != values.image) {
      this.imageLoading = true;
    }
    const { image, path } = values;
    this.imageSelected = image;
    this.setImageSelectedObject(image);
    this.thumbnailClick.emit(path);
  }

  setImageSelectedObject(imageSelected: any) {
    if (this.hasImages(this.images)) {
      this.imageObject = {
        imagePosition: imageSelected,
        mainThumbnailAll: this.images.mainThumbnailAll
          ? this.images.mainThumbnailAll[0]
          : '',
        mainZoomAll: this.images.mainZoomAll
          ? this.images.mainZoomAll[imageSelected]
          : '',
        mainMedAll: this.images.mainMedAll
          ? this.images.mainMedAll[imageSelected]
          : '',
        mainLgAll: this.images.mainLgAll
          ? this.images.mainLgAll[imageSelected]
          : '',
        mainXLgAll: this.images.mainXLgAll
          ? this.images.mainXLgAll[imageSelected]
          : '',
      };
      this.noImages = false;
    } else {
      this.noImages = true;
    }
  }

  hasImages(images: ILookupImages) {
    if (
      !Object.keys(this.images).length ||
      !Array.isArray(images.mainThumbnailAll) ||
      !Array.isArray(images.mainZoomAll) ||
      !Array.isArray(images.mainLgAll) ||
      !images.mainThumbnailAll.length ||
      !images.mainZoomAll.length ||
      !images.mainLgAll.length
    ) {
      return false;
    } else {
      return true;
    }
  }

  showZoomImage(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'modal-responsive' })
    );

    this.zoomClick.emit(this.imageObject.mainZoomAll);
  }

  nextZoomImage() {
    if (this.imageObject.imagePosition < this.images.mainZoomAll.length - 1) {
      this.setImageSelectedObject(this.imageObject.imagePosition + 1);
    } else if (
      this.imageObject.imagePosition ==
      this.images.mainZoomAll.length - 1
    ) {
      this.setImageSelectedObject(0);
    }
  }
  previousZoomImage() {
    if (this.imageObject.imagePosition > 0) {
      this.setImageSelectedObject(this.imageObject.imagePosition - 1);
    } else if (this.imageObject.imagePosition == 0) {
      this.setImageSelectedObject(this.images.mainZoomAll.length - 1);
    }
  }
  openImageInNewTab(path: any) {
    window.open(this.imageUrl + path, '_blank')?.focus();
  }

  imageOnLoad() {
    console.log('loading');
    this.imageLoading = false;
  }
}
