<!-- BUTTON -->
<div class="p-1 f-btn" (click)="onContactUs()">
  <h4 class="f-font text-white m-0">
    <i class="fa-solid fa-envelope me-1"></i>
    Contact Us
  </h4>
</div>
<!-- / END BUTTON -->

<!-- MODAL -->
<div
  bsModal
  #contactUsFormModal="bs-modal"
  class="modal fade"
  role="dialog"
  [config]="{ backdrop: 'static' }"
  aria-labelledby="contactUs"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <!-- MODAL HEADER -->
      <div class="modal-header">
        <h3 id="contactUs" class="modal-title pull-left f-font">Contact Us</h3>
        <div type="button" class="py-1 px-2" (click)="onCloseModal()">
          <i class="fa-solid fa-xmark"></i>
        </div>
      </div>
      <!-- MODAL BODY -->
      <div class="modal-body pb-5 contact-us-body">
        <form [formGroup]="constactUsForm">
          <div class="row mb-3">
            <!-- First Name -->
            <div class="col-12 col-sm-6 mb-2">
              <label for="firstName" class="form-label f-font required"
                >First Name</label
              >
              <input
                id="firstName"
                type="text"
                class="form-control f-font"
                [ngClass]="{
                  'is-invalid':
                    constactUsForm.get('firstName')?.invalid &&
                    constactUsForm.get('firstName')?.touched
                }"
                formControlName="firstName"
              />
              <div
                class="invalid-feedback"
                *ngIf="constactUsForm.get('firstName')?.hasError('required')"
              >
                Your first name is required.
              </div>
              <div
                class="invalid-feedback"
                *ngIf="constactUsForm.get('firstName')?.hasError('maxlength')"
              >
                Must be less tha 50 characters.
              </div>
            </div>
            <!-- Last Name -->
            <div class="col-12 col-sm-6 mb-2">
              <label for="lastName" class="form-label f-font">Last Name</label>
              <input
                id="lastName"
                type="text"
                class="form-control f-font"
                formControlName="lastName"
                [ngClass]="{
                  'is-invalid':
                    constactUsForm.get('lastName')?.invalid &&
                    constactUsForm.get('lastName')?.touched
                }"
              />
              <div class="invalid-feedback">
                Must be less tha 50 characters.
              </div>
            </div>
            <!-- Email -->
            <div class="col-12 col-sm-6 mb-2">
              <label for="email" class="form-label f-font">Email</label>
              <input
                readonly
                placeholder="{{ mUser.email }}"
                id="email"
                type="email"
                class="form-control f-font"
              />
            </div>
            <!-- Phone -->
            <div class="col-12 col-sm-6 mb-2">
              <label for="phone" class="form-label f-font">Phone</label>
              <input
                id="phone"
                type="text"
                class="form-control f-font"
                formControlName="phoneNumber"
                [ngClass]="{
                  'is-invalid':
                    constactUsForm.get('phoneNumber')?.invalid &&
                    constactUsForm.get('phoneNumber')?.touched
                }"
                [validation]="false"
                [dropSpecialCharacters]="false"
                [patterns]="customPatterns"
                mask="A{51}"
              />
              <div class="invalid-feedback">
                Must be less tha 50 characters.
              </div>
            </div>
          </div>
          <!-- Message -->
          <div class="alert border text-center department-message" role="alert">
            <span class="f-font f-bold">
              To help us connect you with the correct department, please select
              from the options below:
            </span>
          </div>
          <!-- Select -->
          <div class="col-12 mb-4 f-font">
            <select
              id="type"
              #type
              class="form-select"
              aria-label="Default select example"
              (change)="onTypeChange(type.value)"
              formControlName="issueType"
            >
              <option disabled readonly value="">Select Issue</option>
              <option *ngFor="let issue of issueTypes" value="{{ issue.code }}">
                {{ issue.description }}
              </option>
            </select>
          </div>
          <!-- ================= START Changing form container ================= -->
          <!-- ===== Purchase Orders ===== -->
          <div
            *ngIf="displayPurchaseOrders"
            class="col-12 col-sm-6 mb-2 d-flex align-items-center"
          >
            <label
              for="purchaseOrderNumber"
              class="form-label f-font text-nowrap required mb-0 me-3"
              >PO #</label
            >
            <div>
              <input
                id="purchaseOrderNumber"
                type="text"
                class="form-control f-font"
                [ngClass]="{
                  'is-invalid':
                    constactUsForm.get('purchaseOrderNumber')?.invalid &&
                    constactUsForm.get('purchaseOrderNumber')?.touched
                }"
                formControlName="purchaseOrderNumber"
              />
              <div class="invalid-feedback">
                Purchase order number is required.
              </div>
            </div>
          </div>
          <!-- ===== Invoice Payments ===== -->
          <app-invoice-payments-contact-us
            *ngIf="displayInvoicePayments"
            [parentForm]="constactUsForm"
            [invoicePaymentsForm]="invoicePaymentsForm"
            (getUploadedBillOfLadingName)="getUploadedBillOfLadingName($event)"
            (getUploadedPackingListName)="getUploadedPackingListName($event)"
          ></app-invoice-payments-contact-us>
          <!-- ===== Shipment Question ===== -->
          <app-shipment-question
            [parentForm]="constactUsForm"
            [shipmentQuestionForm]="shipmentQuestionForm"
            *ngIf="displayShipmentQuestion"
          ></app-shipment-question>
          <!-- ===== Suggestion ===== -->
          <div *ngIf="displaySuggestion" class="px-4">
            <p class="f-font comment-indicator">
              Please indicate what your suggestion is
            </p>
          </div>
          <!-- ===== Other ===== -->
          <div *ngIf="displayOther" class="px-4">
            <p class="f-font comment-indicator">
              Please indicate what your issue or comment is
            </p>
          </div>
          <!-- ================= / END Changing form container ================= -->
          <!-- Comments -->
          <div class="col-12 my-2">
            <label
              for="questionTitle"
              class="form-label f-font"
              [ngClass]="{ required: displaySuggestion || displayOther }"
              >Comments</label
            >
            <textarea
              class="form-control f-font"
              [ngClass]="{
                'is-invalid':
                  constactUsForm.get('comments')?.invalid &&
                  constactUsForm.get('comments')?.touched
              }"
              id="exampleFormControlTextarea1"
              rows="3"
              formControlName="comments"
            ></textarea>
            <div
              class="invalid-feedback"
              *ngIf="constactUsForm.get('comments')?.hasError('required')"
            >
              This option requires the comment field.
            </div>
            <div
              class="invalid-feedback"
              *ngIf="constactUsForm.get('comments')?.hasError('maxlength')"
            >
              Must be less than 1000 characters.
            </div>
          </div>
        </form>
      </div>
      <!-- MODAL FOOTER -->
      <div class="modal-footer">
        <div>
          <button
            type="button"
            class="f-btn f-xs f-blue"
            (click)="onSubmitForm()"
            [disabled]="!constactUsForm.valid || submitingLoading"
          >
            <i class="fa-solid fa-paper-plane me-2"></i
            ><span class="f-font">Submit</span>
            <span class="ms-2" *ngIf="submitingLoading">
              <app-fender-tone-loading-div
                [inline]="true"
              ></app-fender-tone-loading-div>
            </span>
          </button>
        </div>
        <!-- GenericError Alert - Document Error -->
        <div
          class="alert alert-danger mt-3 w-100"
          role="alert"
          *ngIf="genericError"
        >
          <h3 class="alert-heading f-font f-bold">
            <i class="fa-solid fa-triangle-exclamation"></i>
            Unable to complete.
          </h3>
          <p class="f-font mb-1">We have ecounter an error.</p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Notification Dialog Component -->
<app-notification-dialog
  [title]="'Alert'"
  [description]="'Do you want to submit this form?'"
  *ngIf="showSubmitAlert"
  (confirmFunction)="confirmSubmitForm($event)"
></app-notification-dialog>

<app-notification-dialog
  [title]="'Alert'"
  [description]="'Are you sure you want to close the form?'"
  *ngIf="showDiscardAlert"
  (confirmFunction)="confirmCloseModal($event)"
></app-notification-dialog>

<!-- ============= Toast Alerts ============ -->
<!-- Sent Toast -->
<app-toast-alert
  #sentToast
  [selector]="'sent'"
  [title]="'Alert'"
  [description]="'Your form has been sent'"
  [style]="'bg-success'"
></app-toast-alert>
