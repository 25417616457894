<ng-container *ngIf="visible">
  <div class="py-3 bg-white select-supplier" *ngIf="!selectedSupplier">
    <div
      class="container-fluid d-flex align-items-md-center flex-column flex-md-row"
    >
      <!-- Supplier Number Input -->
      <div class="input me-3">
        <div class="input-group">
          <input
            type="text"
            [(ngModel)]="supplierNumberText"
            class="form-control f-font"
            placeholder="Type Supplier Number"
            (keyup.enter)="getSupplier(supplierNumberText)"
            data-cy="select-supplier_input"
          />
          <button
            class="btn btn-dark f-font"
            type="button"
            (click)="getSupplier(supplierNumberText)"
            data-cy="select-supplier_search-btn"
          >
            Search
          </button>
        </div>
      </div>

      <!-- Loading Supplier -->
      <ng-template [ngIf]="loadingSupplier">
        <div class="f-btn f-lg loader-container">
          <app-fender-tone-loading-div
            [inline]="true"
            [black]="true"
          ></app-fender-tone-loading-div>
        </div>
      </ng-template>

      <!-- Poetential Supplier Result -->
      <div
        class="d-flex align-items-sm-center mt-3 mt-md-0 ms-md-3 flex-column flex-sm-row"
        *ngIf="potentialSupplier"
      >
        <p class="f-font my-0 me-3" data-cy="potential-supplier_text">
          {{ potentialSupplier.supplierNumber }} {{ potentialSupplier.name }}
        </p>
        <div class="mt-2 mt-sm-0">
          <button
            class="f-btn f-xs f-black me-2"
            (click)="setSupplieNumber()"
            data-cy="potential-supplier_select-btn"
          >
            Select
          </button>
          <button
            class="f-btn f-xs f-black"
            (click)="resetSupplierNumber()"
            data-cy="potential-supplier_clear-btn"
          >
            Clear
          </button>
        </div>
      </div>
      <span
        *ngIf="notFoundMessage"
        class="f-font mx-2 mt-3 mt-md-0"
        data-cy="potential-supplier_not-found"
        >Supplier Not Found</span
      >
    </div>
  </div>

  <!-- Selected Supplier Container -->
  <div
    class="container-fluid d-flex justify-content-center py-2 selected-dealer-container"
    *ngIf="selectedSupplier"
  >
    <span class="f-font me-3" data-cy="selected-supplier_text"
      >Supplier Selected:&nbsp;{{ selectedSupplier.supplierNumber }}
      {{ selectedSupplier.name
      }}<a
        class="f-font cursor-pointer ms-2"
        (click)="resetSupplierNumber()"
        data-cy="selected-supplier_clear-btn"
        >clear</a
      ></span
    >
  </div>
</ng-container>
