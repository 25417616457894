<div class="featured__body add-padding-top-10">
    <div class="row">
        <div class="col-xs-12 text-center">
            <div class="iframe-container">
                <!-- 1. The <iframe> (and video player) will replace this <div> tag. -->
                <div id="bitmovinplayer" data-cy="bitmovin-player" (click)="stopClickPropagation($event)"></div>
                <div class="close-btn" (click)="closeVideo()">
                    <img src="/assets/images/close.svg" ref="close-btn" />
                </div>
            </div>
        </div>
    </div>
</div>
