import { HttpHelperService } from './../helper/http-helper.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, Observable, of, throwError, timeout } from 'rxjs';
import { IDocument } from 'src/app/models/document';
@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(private httpHelperService: HttpHelperService) {}

  getDocumentList(): Observable<IDocument[]> {
    return this.httpHelperService.get(`/documents`).pipe(
      catchError((err) => {
        throw new Error('Documents API. Details: ' + err.error.message);
      })
    );
  }
  getActiveDocuments(): Observable<IDocument[]> {
    return this.httpHelperService.get(`/documents/active`).pipe(
      catchError((err) => {
        throw new Error('Documents API. Details: ' + err.error.message);
      })
    );
  }
  getActiveDocumentsBySupplier(
    supplierNumber: string
  ): Observable<IDocument[]> {
    return this.httpHelperService
      .get(`/documents/active/suppliers/${supplierNumber}`)
      .pipe(
        catchError((err) => {
          throw new Error('Documents API. Details: ' + err.error.message);
        })
      );
  }

  getDocument(id: number): Observable<IDocument> {
    if (!id) {
      return throwError(() => new Error('Id Must Be Provided'));
    }

    return this.httpHelperService.get(`/documents/${id}`).pipe(
      catchError((err) => {
        throw new Error('Documents API. Details: ' + err.error.message);
      })
    );
  }

  createDocument(document: any, filename?: string | undefined) {
    if (!document) {
      return throwError(() => new Error('Parameters Must Be Provided'));
    }
    return this.httpHelperService
      .post(`/documents`, this.prepareDocument(document, filename))
      .pipe(
        catchError((err) => {
          throw new Error('Documents API. Details: ' + err.error.message);
        })
      );
  }

  updateDocument(id: any, document: IDocument, filename: string | undefined) {
    if (!document || !id) {
      return throwError(() => new Error('Parameters Must Be Provided'));
    }
    return this.httpHelperService
      .put(`/documents/${id}`, this.prepareDocument(document, filename))
      .pipe(
        catchError((err) => {
          throw new Error('Documents API. Details: ' + err.error.message);
        })
      );
  }

  deleteDocument(id: number) {
    if (!id) {
      return throwError(() => new Error('Id Must Be Provided'));
    }
    return this.httpHelperService.delete(`/documents/${id}`).pipe(
      catchError((err) => {
        throw new Error('Documents API. Details: ' + err.error.message);
      })
    );
  }

  // Private Functions
  private prepareDocument(document: any, filename?: string): IDocument | null {
    if (!document) {
      return null;
    }
    const documentObject: IDocument = {
      type: document.type,
      title: document.title,
      description: document.description,
      enable: document.enable,
      roleIds: document.roles,
      filename: filename || undefined,
      url: document.url || undefined,
    };
    if (document.supplierNumber) {
      documentObject.supplierNumbers = [document.supplierNumber];
    } else if (document.supplierNumber === '') {
      documentObject.supplierNumbers = [];
    }
    return documentObject;
  }
}
