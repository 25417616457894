<div class="btn-group" dropdown [insideClick]="true">
  <!-- Select Input -->
  <div class="input-group" [ngClass]="{ 'input-group-sm': false }">
    <input
      class="form-control bg-white input-with-chevron cursor-default text-dark"
      value=" {{ dateRangeFilterForm.dirty ? 'Dates Selected' : 'Select' }}"
      readonly
      disabled
      dropdownToggle
    />
  </div>
  <!-- Dropdown Options -->
  <div
    *dropdownMenu
    class="dropdown-menu dropdown-menu-right py-2 px-3 f-font"
    [ngStyle]="{ width: '250px' }"
  >
    <app-po-date-range-section
      [title]="'PO Create Date'"
      [form]="getGroup('poCreateDate')"
    ></app-po-date-range-section>
    <app-po-date-range-section
      [title]="'Production Month'"
      [form]="getGroup('productionMonth')"
    ></app-po-date-range-section>
    <app-po-date-range-section
      [title]="'Original Ship Date'"
      [form]="getGroup('originalShipDate')"
    ></app-po-date-range-section>
    <app-po-date-range-section
      [title]="'Current Ship Date'"
      [form]="getGroup('currentShipDate')"
    ></app-po-date-range-section>
  </div>
</div>
