import { Component, OnInit, Output, Input, OnChanges, EventEmitter } from '@angular/core';

@Component({
  selector: 'show-video',
  templateUrl: './show-video.component.html'
})
export class ShowVideoComponent implements OnInit, OnChanges {

  @Input() widgetTitle: any;
  @Input() videoTitle: any;
  @Input() videoCategory: any;
  @Input() url: any;
  @Input() height: string | undefined;
  @Input() autoplay: any;
  @Input() hideClose: any;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onCloseVideo = new EventEmitter();
  public containerStyle: { height: any; } | undefined;

  constructor(
  ) { }

  ngOnInit() {
    this.height = this.height || '335px';
    this.containerStyle = {'height': this.height};
  }

  ngOnChanges() {
    this.height = this.height || '335px';
  }

  closeVideo() {
    this.onCloseVideo.emit('onCloseVideo');
  }
}
