<!-- <div class="col-md-12 show-video">
    <div class="widget-like">
        <header [hidden]="!widgetTitle">
            <h2>
                <i class="fa fa-video-camera"></i>
                {{ widgetTitle }}
            </h2>
        </header>
        <div class="featured__body add-padding-top-10 widget-body" [ngStyle]="containerStyle">
            <strong>
                <span id="video-title" [hidden]="!videoTitle" class="add-padding-left">{{ videoTitle }}</span>
                <button class="btn btn-xs btn-link pull-right" (click)="closeVideo()" *ngIf="!hideClose">X</button>
            </strong>
            <div class="row">
                <div class="col-xs-12">
                    <video-player
                        [videoCategory]="videoCategory"
                        [videoTitle]="videoTitle"
                        [url]="url"
                        [autoplay]="autoplay">
                    </video-player>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="show-video">
    <!-- <div [ngStyle]="containerStyle">
        <button class="btn btn-xs btn-link pull-right close-video-button mb-1" (click)="closeVideo()" *ngIf="!hideClose"><i class="fa fa-close text-white"></i></button>
        <video-player
            [videoCategory]="videoCategory"
            [videoTitle]="videoTitle"
            [url]="url"
            [autoplay]="autoplay">
        </video-player>
    </div> -->
</div>
