import { Injectable } from '@angular/core';
import {
  of,
  Observable,
  filter,
  tap,
  catchError,
  throwError,
  BehaviorSubject,
} from 'rxjs';
import * as events from '../../fixtures/events.json';
import { HttpHelperService } from '../helper/http-helper.service';
import { AuthenticationService } from '../users/authentication.service';

import { EventType, IOSEEvents } from '../../models/events';
import { HttpParams } from '@angular/common/http';
import { HttpUrlEncoding } from 'src/app/utils/http-url-encoding';
import * as event from '../../fixtures/event-pages.json';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  // Dynamic Main Menu
  private dynamicMainMenuSubject = new BehaviorSubject<Date | undefined>(
    undefined
  );

  constructor(
    private httpHelperService: HttpHelperService,
    private authenticationService: AuthenticationService
  ) {}

  getEvents(eventType?: string): Observable<any> {
    return this.httpHelperService.get(`/events`, { eventType }).pipe(
      catchError((err) => {
        throw new Error('Events API. Details: ' + err.error.message);
      })
    );
  }

  getEventById(id: string, pageIds?: string | null): Observable<IOSEEvents> {
    if (!id) {
      return throwError(() => new Error('Id Must Be Provided'));
    }
    return this.httpHelperService.get(`/events/${id}/pages/${pageIds}`).pipe(
      catchError((err) => {
        throw new Error('Events API. Details: ' + err.error.message);
      })
    );
  }

  getMockEventById() {
    return of(event);
  }

  createEvent(event: any, eventType?: string) {
    if (!event) {
      return throwError(() => new Error('Data Must Be Provided'));
    }
    return this.httpHelperService
      .post(`/events?eventType=${eventType}`, this.parseEvent(event, eventType))
      .pipe(
        catchError((err) => {
          throw new Error('Events API. Details: ' + err.error.message);
        })
      );
  }

  updateEvent(
    id: number,
    event: any,
    eventType?: string,
    toggleActive?: boolean | undefined
  ) {
    if (!event || !id) {
      return throwError(() => new Error('Parameters Must Be Provided'));
    }
    return this.httpHelperService
      .put(`/events/${id}`, this.parseEvent(event, eventType, toggleActive))
      .pipe(
        catchError((err) => {
          throw new Error('Events API. Details: ' + err.error.message);
        })
      );
  }

  deleteEvent(id: number) {
    if (!id) {
      return throwError(() => new Error('Id Must Be Provided'));
    }
    return this.httpHelperService.delete(`/events/${id}`).pipe(
      catchError((err) => {
        throw new Error('Events API. Details: ' + err.error.message);
      })
    );
  }

  duplicateEvent(id: number, pages?: string) {
    if (!id) {
      return throwError(() => new Error('Id Must Be Provided'));
    }
    return this.httpHelperService
      .post(`/events/${id}/duplicate/pages/${pages ? pages : '0'}`)
      .pipe(
        catchError((err) => {
          throw new Error('Events API. Details: ' + err.error.message);
        })
      );
  }

  downloadReport(id: number) {
    if (!id) {
      return throwError(() => new Error('Id Must Be Provided'));
    }
    let queryParams: HttpParams = new HttpParams({
      encoder: new HttpUrlEncoding(),
    });
    queryParams = queryParams.append('event', id);
    return this.httpHelperService
      .get(`/jobs/downloads/activity-report`, queryParams)
      .pipe(
        catchError((err) => {
          throw new Error('Events API. Details: ' + err.error.message);
        })
      );
  }

  // =============================== Events - Dashboard ===============================
  getActiveEvents(eventType?: string): Observable<any> {
    return this.httpHelperService
      .get(`/events/active?eventType=${eventType}`)
      .pipe(
        catchError((err) => {
          throw new Error('Events API. Details: ' + err.error.message);
        })
      );
  }

  // =============================== Pages ===============================
  async addPage(id: any, page: any) {
    if (!id || !page) {
      return throwError(() => new Error('Data Must Be Provided'));
    }
    await this.authenticationService.refreshExpiredToken();
    return this.httpHelperService
      .post(`/events/${id}/pages`, this.parsePage(page))
      .pipe(
        catchError((err) => {
          throw new Error('Events API. Details: ' + err.error.message);
        })
      );
  }

  async updatePage(eventId: any, pageId: any, page: any) {
    if (!eventId || !pageId || !page) {
      return throwError(() => new Error('Data Must Be Provided'));
    }
    await this.authenticationService.refreshExpiredToken();
    return this.httpHelperService
      .put(`/events/${eventId}/pages/${pageId}`, this.parsePage(page))
      .pipe(
        catchError((err) => {
          throw new Error('Events API. Details: ' + err.error.message);
        })
      );
  }

  deletePage(eventId: any, pageId: any) {
    if (!eventId || !pageId) {
      return throwError(() => new Error('Data Must Be Provided'));
    }
    return this.httpHelperService
      .delete(`/events/${eventId}/pages/${pageId}`)
      .pipe(
        catchError((err) => {
          throw new Error('Events API. Details: ' + err.error.message);
        })
      );
  }

  getActivePages(eventId: string, pageIds: string, eventType: string) {
    if (!eventId || !pageIds) {
      return throwError(() => new Error('Data Must Be Provided'));
    }
    return this.httpHelperService
      .get(`/events/active/${eventId}/pages/${pageIds}?eventType=${eventType}`)
      .pipe(
        catchError((err) => {
          throw new Error('Events API. Details: ' + err.error.message);
        })
      );
  }

  // Private Functions

  private parseEvent(form: any, eventType?: string, toggle?: boolean) {
    let objectEvent;
    if (toggle) {
      objectEvent = {
        enable: form.enable,
      };
      return objectEvent;
    }
    objectEvent = {
      title: form.title,
      enable: form.active,
      roleIds: form.roles,
      supplierNumbers: this.parseInviteList(form.inviteList),
      // eventType: eventType,
    };
    if (eventType === EventType.OSE) {
      Object.assign(objectEvent, {
        startsAt: this.formatDateTimeValues(form.startDate, form.startTime),
        endsAt: this.formatDateTimeValues(form.endDate, form.endTime),
      });
    }
    if (eventType === EventType.CUSTOM_PAGES && form.composedIconFileName) {
      Object.assign(objectEvent, {
        navigationIcon: form.composedIconFileName,
      });
    }
    return objectEvent;
  }

  private parseInviteList(inviteList: any) {
    const parsedInciteList: string[] = [];

    inviteList.forEach((invite: any) => {
      if (typeof invite === 'string') {
        parsedInciteList.push(invite);
      }
    });

    return parsedInciteList;
  }
  private formatDateTimeValues(date: Date, time: Date) {
    const newUTCDate = moment(date).format('YYYY-MM-DD');
    const newUTCTime = moment(time).format('HH:mm:ss');
    let formatedDate = `${newUTCDate}T${newUTCTime}`;
    return formatedDate;
  }

  private parsePage(page: any) {
    if (!page) {
      return null;
    }
    const pageObject: any = {};
    if (page.id) {
      pageObject.id = page.id;
    }
    pageObject.title = page.title;
    pageObject.pageType = page.pageType;
    if (page.pageType === 'Content') {
      pageObject.sections = this.parseSections(page.sections);
    } else {
      pageObject.sections = this.parseSectionPDF(page.sections[0]);
    }
    return pageObject;
  }

  private parseSectionPDF(section: any) {
    const sectionsArray: any = [];
    const sectionObject: any = {};
    sectionObject.action = section.action;
    if (section.filename) {
      sectionObject.filename = section.filenamePath;
    }
    sectionObject.sectionType = section.sectionType;
    sectionsArray.push(sectionObject);
    return sectionsArray;
  }

  private parseSections(sections: any) {
    const sectionsArray: any = [];
    sections.forEach((section: any) => {
      const sectionObject: any = {};
      if (section.id) {
        sectionObject.id = section.id;
      }
      if (section.action) {
        sectionObject.action = section.action;
      }
      if (section.description) {
        sectionObject.description = section.description;
      }
      if (section.sectionType) {
        sectionObject.sectionType = section.sectionType;
      }
      if (section.backgroundType) {
        sectionObject.backgroundType = section.backgroundType;
      }
      if (section.background) {
        if (section.backgroundType === 'RGB') {
          sectionObject.background = section.background;
        } else if (
          section.backgroundType === 'ImageFile' &&
          section.backgroundPath
        ) {
          sectionObject.background = section.backgroundPath;
        }
      }
      if (section.paddingSections) {
        sectionObject.paddingSections = section.paddingSections;
      }
      if (section.filename && section.filenamePath) {
        sectionObject.filename = section.filenamePath;
      }
      if (section.filename && section.sectionType === 'Video') {
        sectionObject.filename = section.filename;
      }
      if (section.videoTitle) {
        sectionObject.videoTitle = section.videoTitle;
      }
      if (section.videoThumbnailPath) {
        sectionObject.videoThumbnail = section.videoThumbnailPath;
      }
      if (section.subtitles.length) {
        sectionObject.subtitles = this.parseSubtitles(section.subtitles);
      }
      sectionsArray.push(sectionObject);
    });
    return sectionsArray;
  }

  private parseSubtitles(subtitles: any) {
    const subtitlesArray: any = [];
    subtitles.forEach((subtitle: any) => {
      const subtitleObject: any = {};
      subtitleObject.action = subtitle.action;
      subtitleObject.id = subtitle.id;
      subtitleObject.url = subtitle.urlPath;
      subtitleObject.label = subtitle.label;
      subtitleObject.langCode = subtitle.langCode;
      subtitleObject.kind = subtitle.kind;
      subtitleObject.createdAt = subtitle.createdAt;
      subtitleObject.updatedAt = subtitle.updatedAt;
      subtitlesArray.push(subtitleObject);
    });
    return subtitlesArray;
  }

  trackActivityReport(
    eventId: string | null,
    sectionId: string,
    activity: any
  ): Observable<any> {
    return this.httpHelperService
      .post(`/events/${eventId}/videos/${sectionId}/activity`, activity)
      .pipe(
        catchError((err) => {
          throw new Error('Events API. Details: ' + err.error.message);
        })
      );
  }

  // Dynamic Main Menu
  triggerDynamicMainMenu(param: Date) {
    this.dynamicMainMenuSubject.next(param);
  }
  getDynamicMainMenuSubject(): BehaviorSubject<Date | undefined> {
    return this.dynamicMainMenuSubject;
  }
}
