<ng-template #inviteListModal>
  <div class="modal-header">
    <h4 class="modal-title f-font">{{ "Invite List" }}</h4>
    <button
      type="button"
      class="btn"
      data-dismiss="modal"
      aria-label="Close"
      (click)="modalRef.hide()"
      data-cy="invite_list_modal-close_btn"
    >
      <i class="fa-solid fa-xmark"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-xs-12 col-xs-left">
        <textarea
          [(ngModel)]="invitedSuppliers"
          class="form-control"
          id="invite-list"
          data-cy="invite-list"
          rows="10"
          placeholder="{{
            'Enter Supplier Number with or without leading 0’s\nEnter each Supplier Number on a separate line or in a comma-separated list\n\nEx:\n12345678\n98765432\n11223344\nOr\n12345678,98765432,11223344'
          }}"
          data-cy="invite_list-textarea"
        >
        </textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-xs-12 d-flex flex-wrap justify-content-end">
        <button
          type="button"
          class="f-btn f-xs me-2 mb-2"
          (click)="modalRef.hide()"
          data-cy="invite_list-close_btn"
        >
          <i class="fa fa-arrow-left"></i>
          {{ "Back" }}
        </button>
        <button
          type="button"
          class="f-btn f-xs f-yellow me-2 mb-2"
          (click)="clear()"
          data-cy="invite_list-clear_btn"
        >
          <i class="fa fa-refresh"></i>
          {{ "Clear" }}
        </button>
        <button
          type="button"
          class="f-btn f-xs f-blue mb-2"
          (click)="save()"
          data-cy="invite_list-save_btn"
        >
          <i class="fa fa-save"></i>
          {{ "Save" }}
        </button>
        <!-- invitedDealers?.length > 0 && -->
        <button
          *ngIf="invitedSuppliers && isSavedList"
          type="button"
          class="f-btn f-xs f-red ms-2 mb-2"
          (click)="delete()"
          data-cy="invite_list-delete_btn"
        >
          <i class="fa fa-trash"></i>
          {{ "Remove" }} {{ "Invite List" }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
