import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-thumbnail-carousel',
  templateUrl: './thumbnail-carousel.component.html',
})
export class ThumbnailCarouselComponent implements OnInit {
  @Input() thumbnails: string[] = [];
  @Input() imageSelected: any = 0;
  @Output() selectImage = new EventEmitter<{ image: string; path: string }>();
  constructor() {}

  ngOnInit(): void {}

  onImageSelection(image: any, path: string) {
    this.selectImage.emit({ image, path });
  }
}
