import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-table-active-toggle',
  templateUrl: './table-active-toggle.component.html',
  styles: [],
})
export class TableActiveToggleComponent implements OnInit {
  @Input() status: boolean = false;
  @Input() preventActive?: string | undefined = undefined;
  @Output() onToggleStatus: EventEmitter<boolean> = new EventEmitter();
  loading: boolean = false;
  popOverString: string = '';

  constructor() {}

  ngOnChanges(change: SimpleChanges) {
    if (change['status'].currentValue != change['status'].previousValue) {
      this.loading = false;
      this.status = change['status'].currentValue;
    }
  }

  ngOnInit(): void {
    this.setPreventActive();
  }

  setPreventActive() {
    if (this.preventActive) {
      this.popOverString =
        'Title needs to be 20 characters or less in order to be active. Please edit this title.';
    }
  }

  toggleActive(status: boolean) {
    this.loading = true;
    this.onToggleStatus.emit(status);
  }
}
