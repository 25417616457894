<div class="slideshow-carousel">
  <!-- <div class="detail-image-prod-details" *ngFor="let image  of images | keyvalue: noSort"
        [class.selected]="image.value.name == imageSelected.name" (click)="onImageSelection(image.value)"> -->
  <div
    class="carousel-image"
    [class.selected]="imageIndex == imageSelected"
    *ngFor="let thumbnail of thumbnails; let imageIndex = index"
    (click)="onImageSelection(imageIndex, thumbnail)"
  >
    <img src="{{ 'https://www.fmicassets.com/Damroot/' + thumbnail }}" />
  </div>
</div>
