<div class="circle-progress-bar__outer">
    <div class="circle-progress-bar__inner">
        <svg
            data-cy="circle-progress-bar"
            class="progress-ring"
            viewBox="0 0 90 90" preserveAspectRatio="xMinYMin meet">

            <circle
                class="progress-ring__circle"
                fill="transparent"
                [attr.r]="radius" />

            <circle
                [ngStyle]="{
                    'stroke-dashoffset': progress,
                    'stroke-dasharray': circumference
                }"
                class="progress-ring__circle--front"
                fill="transparent"
                [attr.r]="radius" />

            <image
                class="play-icon"
                x="14%"
                y="0"
                width="65px"
                height="100%"
                xlink:href="../../../../assets/images/play-icon.svg"
                alt="Play Icon" />
        </svg>
    </div>
</div>
