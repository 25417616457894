import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtmlToTextPipe } from './html-to-text.pipe';
import { TruncatePipe } from './truncate.pipe';
import { NameInUrlPipe } from './name-in-url.pipe';

@NgModule({
  declarations: [HtmlToTextPipe, TruncatePipe, NameInUrlPipe],
  imports: [CommonModule],
  exports: [HtmlToTextPipe, TruncatePipe, NameInUrlPipe],
})
export class ApplicationPipesModule {}
