import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { IVideo } from 'src/app/models/videos';
import { VideoService } from '../../../services/common/video.service';

const DEFAULT_HEIGHT = '80px';
const DEFAULT_WIDTH = '140px';

@Component({
  selector: 'app-video-thumbnail',
  templateUrl: './video-thumbnail.component.html',
})
export class VideoThumbnailComponent implements OnInit {
  @Input() video!: IVideo | undefined;
  @Input() height = DEFAULT_HEIGHT;
  @Input() width = DEFAULT_WIDTH;
  @Input() maxHeight: string = '';
  @Input() maxWidth: string = '';
  @Input() canEdit = false;
  @Input() showTitles = true;
  @Input() showInline = false;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onSelectVideo = new EventEmitter();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onEditVideo = new EventEmitter();

  private videoStyle: any;
  public thumbnail: string = '';
  public showImage: boolean = false;
  public loading: boolean = false;

  imgPlaceholder: string = '../../../../assets/images/fender-black.svg';

  private subscriptions: Subscription = new Subscription();

  constructor(private videoService: VideoService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: any) {
    if (changes.width) {
      this.width = this.width || DEFAULT_WIDTH;
    }
    if (changes.height) {
      this.height = this.height || DEFAULT_HEIGHT;
    }
    if (changes.video) {
      if (changes.video.currentValue) {
        this.showThumbnail();
      } else {
        this.clearThumbnail();
      }
      this.loading = false;
    }
  }

  showThumbnail() {
    this.loading = true;
    this.width = this.width || DEFAULT_WIDTH;
    this.height = this.height || DEFAULT_HEIGHT;
    let videoId: string;

    const service = this.videoService.getServiceVideo(this.video?.link!);
    const videoThumbnail = {
      default: () => {
        this.showImage = true;
        if (this.video?.thumbnail) {
          this.thumbnail = this.video.thumbnail;
        } else {
          this.clearThumbnail();
        }
      },
    };
    videoThumbnail['default']();
  }

  clearThumbnail() {
    this.thumbnail = '';
  }

  selectVideo() {
    this.onSelectVideo.emit();
  }

  editVideo() {
    this.onEditVideo.emit();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
