import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
})
export class WidgetComponent implements OnChanges {
  @Input() widget: any;
  @Output() widgetClosed = new EventEmitter();

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['widget'] && this.widget) {
      this.widget.collapseLegend = this.getCollapseLegend(false);
      this.widget.fullscreenLegend = this.getFullscreenLegend(false); // By default Normal Size
    }
  }

  toggleCollapse() {
    this.widget.collapsed = !this.widget.collapsed;
    this.widget.collapseLegend = this.getCollapseLegend(this.widget.collapsed);
  }

  toggleFullscreen() {
    this.widget.fullscreen = !this.widget.fullscreen;
    this.widget.fullscreenLegend = this.getFullscreenLegend(
      this.widget.fullscreen
    );
  }

  hideWidget() {
    this.widget.visible = false;
    this.widgetClosed.emit(this.widget);
  }

  getCollapseLegend(collapsed: boolean) {
    return !collapsed ? 'Collapse' : 'Expand';
  }

  getFullscreenLegend(fullscreen: boolean) {
    return !fullscreen ? 'Fullscreen' : 'Normal Size';
  }
}
