import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IMainMenu, IMainSubMenu } from 'src/app/models/main-menu';

@Component({
  selector: 'app-option-main-menu',
  templateUrl: './option-main-menu.component.html',
  styles: [],
})
export class OptionMainMenuComponent implements OnInit {
  @Input() menuItem!: IMainMenu;
  @Output() selectSubItem: EventEmitter<any> = new EventEmitter();
  @Output() navigatePage: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onClickNavigatePage(menuItem: IMainMenu) {
    this.navigatePage.emit(menuItem);
  }

  onClickSelectSubItem(menuItem: IMainMenu, subItem: IMainSubMenu) {
    this.selectSubItem.emit({ menuItem, subItem });
  }
}
