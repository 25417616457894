import { Injectable } from '@angular/core';
import { catchError, Observable, of, timeout } from 'rxjs';
import { HttpHelperService } from '../helper/http-helper.service';
import { environment } from 'src/environments/environment';
import { HttpUrlEncoding } from 'src/app/utils/http-url-encoding';
import { HttpParams } from '@angular/common/http';
import {
  CurrencyCode,
  IPoFilterParams,
  IPurchaseOrdersListResponse,
  IStatusParams,
  PoStatusEndpointValue,
} from 'src/app/models/purchase-orders';
import { IUserListParams } from 'src/app/models/user';

@Injectable({
  providedIn: 'root',
})
export class PurchaseOrdersService {
  constructor(private httpHelperService: HttpHelperService) {}

  // Purchase Orders By Supplier Number
  getPurchaseOrdersBySupplierNumber(
    params: IPoFilterParams
  ): Observable<IPurchaseOrdersListResponse> {
    let queryParams = this.filteringParameters(params);

    return this.httpHelperService
      .get(`/purchasing-orders/suppliers/${params.supplierNumber}`, queryParams)
      .pipe(
        catchError((err) => {
          throw new Error('Purchase Orders API. Details: ' + err.error.message);
        })
      );
  }

  // Summary Purchase Orders
  getSummaryPurchaseOrders(
    params: IPoFilterParams
  ): Observable<IPurchaseOrdersListResponse> {
    let queryParams = this.filteringParameters(params);
    return this.httpHelperService
      .get(`/purchasing-orders/summary`, queryParams)
      .pipe(
        catchError((err) => {
          throw new Error('Purchase Orders API. Details: ' + err.error.message);
        })
      );
  }

  downloadPoPdf(poNumber: string): Observable<any> {
    let queryParams: HttpParams = new HttpParams({
      encoder: new HttpUrlEncoding(),
    });

    queryParams = queryParams.append('poNumber', poNumber);
    return this.httpHelperService
      .get(`/jobs/downloads/po-pdf`, queryParams)
      .pipe(
        timeout(environment.api.timeout),
        catchError((err) => {
          throw new Error('Purchase Orders API. Details: ' + err.error.message);
        })
      );
  }

  dowloadPoReport(params: IPoFilterParams) {
    let queryParams = this.filteringParameters(params);
    return this.httpHelperService
      .get(`/jobs/downloads/po-export-page`, queryParams)
      .pipe(
        timeout(environment.api.timeout),
        catchError((err) => {
          throw new Error('Purchase Orders API. Details: ' + err.error.message);
        })
      );
  }

  dowloadPoSummaryReport(params: IPoFilterParams) {
    let queryParams = this.filteringParameters(params);
    // queryParams = this.removeParamsForFiles(queryParams);
    // console.log(queryParams);
    return this.httpHelperService
      .get(`/jobs/downloads/po-summary-export`, queryParams)
      .pipe(
        timeout(environment.api.timeout),
        catchError((err) => {
          throw new Error('Purchase Orders API. Details: ' + err.error.message);
        })
      );
  }

  // Plants
  getPlantsBySupplier(supplierNumber: string) {
    return this.httpHelperService
      .get(`purchasing-orders/suppliers/${supplierNumber}/plants`)
      .pipe(
        timeout(environment.api.timeout),
        catchError((err) => {
          throw new Error('Purchase Orders API. Details: ' + err.error.message);
        })
      );
  }

  getAllPlants() {
    return this.httpHelperService.get(`purchasing-orders/summary/plants`).pipe(
      timeout(environment.api.timeout),
      catchError((err) => {
        throw new Error('Purchase Orders API. Details: ' + err.error.message);
      })
    );
  }

  // Purchasers
  getAllPurchasers() {
    return this.httpHelperService
      .get(`purchasing-orders/summary/purchasers`)
      .pipe(
        timeout(environment.api.timeout),
        catchError((err) => {
          throw new Error('Purchase Orders API. Details: ' + err.error.message);
        })
      );
  }

  // Alerts
  getSummaryAlerts(alertName: string) {
    return this.httpHelperService
      .get(`purchasing-orders/summary/alerts`, { status: alertName })
      .pipe(
        timeout(environment.api.timeout),
        catchError((err) => {
          throw new Error('Purchase Orders API. Details: ' + err.error.message);
        })
      );
  }

  // Product Lines
  getProductLines() {
    return this.httpHelperService
      .get(`purchasing-orders/summary/product-lines`)
      .pipe(
        timeout(environment.api.timeout),
        catchError((err) => {
          throw new Error('Purchase Orders API. Details: ' + err.error.message);
        })
      );
  }

  getDestination(supplierNumber: string, poNumber: string) {
    return this.httpHelperService
      .get(
        `purchasing-orders/suppliers/${supplierNumber}/${poNumber}/destination`
      )
      .pipe(
        timeout(environment.api.timeout),
        catchError((err) => {
          throw new Error('Purchase Orders API. Details: ' + err.error.message);
        })
      );
  }

  getPoDetails(
    supplierNumber: string,
    poNumber: string,
    sortBy?: string,
    sortType?: string
  ) {
    let queryParams: HttpParams = new HttpParams({
      encoder: new HttpUrlEncoding(),
    });
    if (sortBy && sortType) {
      queryParams = queryParams.append('sortBy', sortBy);
      queryParams = queryParams.append('sortType', sortType);
    }
    return this.httpHelperService
      .get(
        `purchasing-orders/suppliers/${supplierNumber}/${poNumber}`,
        queryParams
      )
      .pipe(
        timeout(environment.api.timeout),
        catchError((err) => {
          throw new Error('Purchase Orders API. Details: ' + err.error.message);
        })
      );
  }

  savePoStatus(
    supplierNumber: string,
    poNumber: string,
    status: string,
    params: any
  ) {
    return this.httpHelperService
      .post(
        `purchasing-orders/suppliers/${supplierNumber}/${poNumber}/${status}`,
        params
      )
      .pipe(
        timeout(environment.api.timeout),
        catchError((err) => {
          throw new Error('Purchase Orders API. Details: ' + err.error.message);
        })
      );
  }

  getPoStatus(supplierNumber: string | undefined, poNumber: string | null) {
    if (!supplierNumber || !poNumber) {
      return null;
    }
    return this.httpHelperService
      .get(`purchasing-orders/suppliers/${supplierNumber}/${poNumber}/status`)
      .pipe(
        timeout(environment.api.timeout),
        catchError((err) => {
          throw new Error('Purchase Orders API. Details: ' + err.error.message);
        })
      );
  }

  downloadDetailsReport(params: IPoFilterParams) {
    let queryParams = this.filteringParameters(params);
    return this.httpHelperService
      .get(`/jobs/downloads/po-export-details`, queryParams)
      .pipe(
        timeout(environment.api.timeout),
        catchError((err) => {
          throw new Error('Purchase Orders API. Details: ' + err.error.message);
        })
      );
  }

  filteringParameters(params: IPoFilterParams) {
    let queryParams: HttpParams = new HttpParams({
      encoder: new HttpUrlEncoding(),
    });
    if (params.limit) {
      queryParams = queryParams.append('limit', params.limit);
    }
    if (params.offset || params.offset == 0) {
      queryParams = queryParams.append('offset', params.offset);
    }
    if (params.supplierNumber) {
      queryParams = queryParams.append('supplierNumber', params.supplierNumber);
    }
    if (params.search) {
      queryParams = queryParams.append('search', params.search);
    }
    if (params.createDate) {
      queryParams = queryParams.append('createDate', params.createDate);
    }
    if (params.plant) {
      queryParams = queryParams.append('plant', params.plant.toString());
    }
    if (params.status) {
      queryParams = queryParams.append('status', params.status);
    }
    if (params.sortBy) {
      queryParams = queryParams.append('sortBy', params.sortBy);
    }
    if (params.sortType) {
      queryParams = queryParams.append('sortType', params.sortType);
    }
    if (params.poNumber) {
      queryParams = queryParams.append('poNumber', params.poNumber);
    }
    if (params.groupBy) {
      queryParams = queryParams.append('groupBy', params.groupBy);
    }
    if (params.emailId) {
      queryParams = queryParams.append('emailId', params.emailId);
    }
    if (params.alert) {
      queryParams = queryParams.append('alert', params.alert);
    }
    if (params.productLine) {
      queryParams = queryParams.append('productLines', params.productLine);
    }
    if (params.productionMonth) {
      queryParams = queryParams.append(
        'productionMonth',
        params.productionMonth
      );
    }
    if (params.originalShipDate) {
      queryParams = queryParams.append(
        'originalShipDate',
        params.originalShipDate
      );
    }
    if (params.currentShipDate) {
      queryParams = queryParams.append(
        'currentShipDate',
        params.currentShipDate
      );
    }
    return queryParams;
  }

  removeParamsForFiles(queryParams: any) {
    queryParams = queryParams.delete('sortBy');
    queryParams = queryParams.delete('offset');
    return queryParams;
  }

  getDateRageList() {
    const dateRangeOptions: any[] = [];
    dateRangeOptions.push({ label: 'All Orders', value: '' });
    dateRangeOptions.push({
      label: this.substractYearsLabel(2),
      value: this.substractYearsAndFormatDate(2),
    });
    dateRangeOptions.push({
      label: this.substractYearsLabel(1),
      value: this.substractYearsAndFormatDate(1),
    });
    dateRangeOptions.push({
      label: 'Year to date',
      value: this.substractYearsAndFormatDate(0),
    });
    dateRangeOptions.push({
      label: 'Last 180 days',
      value: this.substractDaysAndFormatDate(180),
    });
    dateRangeOptions.push({
      label: 'Last 60 days',
      value: this.substractDaysAndFormatDate(60),
    });
    dateRangeOptions.push({
      label: 'Last 30 days',
      value: this.substractDaysAndFormatDate(30),
    });
    dateRangeOptions.push({
      label: 'Last 7 days',
      value: this.substractDaysAndFormatDate(7),
    });

    return dateRangeOptions;
  }

  substractDaysAndFormatDate(daysToSubs: number) {
    const currentDate = new Date();
    const dateToSubstract = new Date();
    const formattedDate =
      new Date(dateToSubstract.setDate(new Date().getDate() - daysToSubs))
        .toISOString()
        .split('T')[0]
        .replace(/-/g, '') +
      ',' +
      currentDate.toISOString().split('T')[0].replace(/-/g, '');
    return formattedDate;
  }

  substractYearsLabel(yearsToSubs: number) {
    const currentDate = new Date();
    return currentDate.getFullYear() - yearsToSubs;
  }

  substractYearsAndFormatDate(yearsToSubs: number) {
    const currentDate = new Date();
    const formattedDate =
      currentDate.getFullYear() -
      yearsToSubs +
      '0101,' +
      (currentDate.getFullYear() - yearsToSubs) +
      '1231';
    return formattedDate;
  }

  getCurrencyCode(currency: any): string {
    if (currency === CurrencyCode.RMB) {
      return CurrencyCode.CNY;
    } else {
      return currency;
    }
  }

  getCurrencyDecimal(currency: string): string {
    if (currency === CurrencyCode.RMB || currency === CurrencyCode.CNY) {
      return '.2-2';
    } else {
      return '';
    }
  }
}
