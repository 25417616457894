import { IdentityService } from './../common/identity.service';
import { Injectable } from '@angular/core';
import Amplify, { Auth, Cache } from 'aws-amplify';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs';
import { UserService } from './user.service';
import { WebsocketService } from '../lib/websocket.service';
import { SelectSupplierHelperService } from '../helper/select-supplier-helper.service';
import { IUser } from 'src/app/models/user';
import { ROLES } from 'src/app/models/role';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalyticsService } from '../common/google-analytics.service';
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public loggedIn: boolean;

  constructor(
    private identityService: IdentityService,
    private webSocketService: WebsocketService,
    private selectSupplierHelperService: SelectSupplierHelperService,
    private userService: UserService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    Amplify.configure(environment.cognito);
    this.loggedIn = false;
  }
  public get currentUser$() {
    return this.identityService.currentUser$.pipe(filter((user) => !!user));
  }

  public async signIn(user: any) {
    try {
      const authUser = await Auth.signIn(
        user.email.toLowerCase(),
        user.password
      );
      // console.log('succesful sign in...', authUser);
      this.loggedIn = true;
      this.identityService.currentUser$.next(authUser);
      this.connectToWebSocket();
      return authUser;
    } catch (error: any) {
      console.log('error signing in...');
      console.error(error);
      this.loggedIn = false;
      throw error;
    }
  }

  async connectToWebSocket() {
    const authUser = await this.getCurrentAuthUser();
    const token = authUser.signInUserSession.idToken.jwtToken;

    this.userService.getMeUser().subscribe({
      next: (user) => {
        if (user) {
          this.webSocketService.init(token, user.email, user.role);
          this.identityService.currentMeUser$.next(user);
          this.setSupplierNumberFromUrl();
          this.googleAnalyticsService.trackEvent(
            'login',
            'Successful',
            user.email
          );
        }
      },
    });
    this.getUserInfo().then((userInfo) => {
      this.identityService.currentUserInfo$.next(userInfo);
    });
  }

  public signOut() {
    return Auth.signOut().then(() => {
      this.loggedIn = false;
      this.identityService.currentUser$.next(null);
      this.identityService.currentMeUser$.next(null);
      this.selectSupplierHelperService.setValue(undefined);
      Cache.clear();
      this.webSocketService.onSignOut();
    });
  }

  public getUserInfo() {
    return Auth.currentUserInfo().then((user) => {
      return user;
    });
  }

  public async getCurrentAuthUser() {
    const userValue = this.identityService.currentUser$.getValue();
    if (userValue) {
      return await userValue;
    } else {
      return await Auth.currentAuthenticatedUser()
        .then((user) => {
          this.identityService.currentUser$.next(user);
          return user;
        })
        .catch((err) => {
          return err;
        });
    }
  }

  private async checkForToken() {
    const userError = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    }).catch((err) => {
      return err;
    });
    return userError;
  }

  public async refreshExpiredToken() {
    const authUser = await this.checkForToken();
    if (authUser === 'The user is not authenticated') {
      await Auth.currentSession();
      return await Auth.currentAuthenticatedUser()
        .then((user) => {
          this.identityService.currentUser$.next(user);
          return user;
        })
        .catch((err) => {
          return err;
        });
    }
  }

  public async getCurrentToken() {
    const userValue = this.identityService.currentUser$.getValue();
    try {
      if (userValue) {
        return userValue['signInUserSession']['accessToken']['jwtToken'];
      } else {
        return (await Auth.currentSession()).getIdToken().getJwtToken();
      }
    } catch (error: any) {
      return null;
    }
  }

  public async completeNewPassword(user: any, newPassword: string) {
    if (!user || !newPassword) {
      return null;
    }
    try {
      const { requiredAttributes } = user.challengeParam;
      return Auth.completeNewPassword(user, newPassword);
    } catch (error: any) {
      return null;
    }
  }
  public async forgotPassword(userName: string) {
    if (!userName) {
      return null;
    }
    try {
      return Auth.forgotPassword(userName);
    } catch (error: any) {
      return null;
    }
  }
  public async submitForgotPassword(
    username: string,
    code: string,
    newPassword: string
  ) {
    if (!username || !code || !newPassword) {
      return null;
    }
    try {
      return Auth.forgotPasswordSubmit(username, code, newPassword);
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  }

  public async userChangePassword(
    user: any,
    currentPassword: string,
    newPassword: string
  ) {
    if (!user || !currentPassword || !newPassword) {
      return null;
    }
    try {
      return Auth.changePassword(user, currentPassword, newPassword);
    } catch (error: any) {
      throw error;
    }
  }

  public async setSupplierNumberFromUrl() {
    // get userMe
    const userMe: IUser | null = this.identityService.currentMeUser$.getValue();
    if (
      userMe?.role === ROLES.FMIC_ADMIN ||
      userMe?.role === ROLES.FMIC_CUSTOMER_SERVICE
    ) {
      // get supplierNumber from URL
      let supplierNumber;
      this.route.queryParams.subscribe((params) => {
        supplierNumber = params['supplierNumber'];
      });
      if (supplierNumber) {
        // set supplierNumber Value
        this.selectSupplierHelperService.setValue(supplierNumber);
        // Remove param from URL
        this.router.navigate([], {
          queryParams: {
            yourParamName: null,
            youCanRemoveMultiple: null,
          },
        });
        // return value
        return supplierNumber;
      }
    }
    // Supplier Factory
    if (userMe?.role === ROLES.SUPPIER_FACTORY) {
      this.router.navigate(['/lookup']);
    }
  }
}
