import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-show-more',
  templateUrl: './show-more.component.html',
})
export class ShowMoreComponent implements OnChanges {
  ngOnInit(): void {}

  @Input() showArray: any[] = [];
  @Input() showQty = 3;

  showAll = false;
  showString: string = '';
  hiddenQty: number = 0;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['showArray'] && changes['showArray'].currentValue) {
      this.setShowAll(false);
    }
  }

  setShowAll(showAll: boolean) {
    this.showAll = showAll;
    this.hiddenQty =
      this.showArray.length > this.showQty
        ? this.showArray.length - this.showQty
        : 0;
    this.showString = !this.showAll
      ? this.showArray.slice(0, this.showQty).join(', ')
      : this.showArray.join(', ');
  }
}
