<div id="lookupCard" class="card">
  <!-- Card Header -->
  <div class="card-header small-header-dark">
    <!-- Title -->
    <div>
      <i class="fa-solid fa-barcode me-2"></i>
      <span class="card-title f-font"> {{ "Lookup" }}</span>
    </div>
  </div>
  <div class="lookup-widget widget" id="lookupCardBody">
    <div class="card-body h-100">
      <app-lookup-search></app-lookup-search>
    </div>
  </div>
</div>
