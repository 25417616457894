import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { HelperService } from '../../services/helper/helper.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'login-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  bannerBackgroundColor: string = '';
  imagePathLogo: string =
    '../../../assets/images/Fender_Logo_Transparent-White.png';
  constructor(private helperService: HelperService) {}

  ngOnInit(): void {
    this.bannerBackgroundColor = this.helperService.getEnviromentColor(
      environment.environment
    );
  }
}
