<li class="h-100">
  <a
    name="menuItem.name"
    class="py-3"
    (click)="onClickNavigatePage(menuItem)"
    [attr.data-cy]="'custom-page-menu-btn'"
  >
    <img
      class="custom-page-nav-icon mb-2"
      src="{{ menuItem.navigationIcon }}"
      alt="cp-icon"
    />
    <span class="f-font">{{ menuItem.name }}</span>
  </a>
</li>
