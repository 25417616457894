import { Component, OnInit } from '@angular/core';
import { IUserFormats } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/users/authentication.service';
import { IUser } from '../../models/user';
import { IdentityService } from '../../services/common/identity.service';
import { Subscription, filter } from 'rxjs';
import { AnnouncementType, IAnnouncement } from 'src/app/models/announcement';
import { AnnouncementsService } from '../../services/announcements/announcements.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  // Subscription
  subscription: Subscription = new Subscription();

  // User
  token: string | null = '';
  userFormats!: IUserFormats;
  userRole: string = '';
  mUser!: IUser;

  // Announcements
  widgetAnnouncements: IAnnouncement[] = [];
  siteMessages: IAnnouncement[] = [];
  announcementLoader: boolean = false;

  // Loading
  loadingDashboard: boolean = true;

  constructor(
    private authenticationService: AuthenticationService,
    private identityService: IdentityService,
    private announcementsService: AnnouncementsService
  ) {}

  async ngOnInit() {
    const user = await this.authenticationService.getCurrentAuthUser();
    this.token = user.signInUserSession.idToken.jwtToken;
    // Get Me
    this.identityService.getCurrentMeUser().subscribe((user) => {
      if (user) {
        this.setProperties(user);
        this.loadingDashboard = false;
      }
    });
  }

  setProperties(user: IUser) {
    this.mUser = user;
    this.userRole = user.role;
    this.userFormats = {
      timezone: this.mUser.timezone || '',
      unitOfMeasure: this.mUser.unitOfMeasure || '',
      dateFormat: this.mUser.dateFormat || 'MM-dd-yyyy',
      timeFormat: this.mUser.timeFormat || '',
    };
  }

  getAnnouncements() {
    this.announcementLoader = true;
    this.subscription.add(
      this.announcementsService.getActiveAnnouncements().subscribe({
        next: (list: IAnnouncement[]) => {
          this.widgetAnnouncements = list.filter(
            (ann: IAnnouncement) => ann.type !== AnnouncementType.SITE_MESSAGES
          );
          this.siteMessages = list.filter(
            (ann: IAnnouncement) => ann.type === AnnouncementType.SITE_MESSAGES
          );
        },
        complete: () => {
          this.announcementLoader = false;
        },
        error: () => {
          this.announcementLoader = false;
        },
      })
    );
  }
}
