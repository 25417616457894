import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  year: number = this.getDate();

  constructor() {}

  ngOnInit(): void {}

  getDate() {
    return new Date().getFullYear();
  }
}
