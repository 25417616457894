<form [formGroup]="parentForm">
  <div formGroupName="invoicePaymentsForm">
    <!-- a. Purchase Order Number -->
    <div class="col-12 col-sm-6 mb-3 d-flex align-items-center">
      <label
        for="purchaseOrderNumber"
        class="form-label f-font text-nowrap required mb-0 me-3"
        >PO #</label
      >
      <div>
        <input
          id="purchaseOrderNumber"
          type="text"
          class="form-control f-font"
          [ngClass]="{
            'is-invalid':
              invoicePaymentsForm.get('purchaseOrderNumber')?.invalid &&
              invoicePaymentsForm.get('purchaseOrderNumber')?.touched
          }"
          formControlName="purchaseOrderNumber"
        />
        <div class="invalid-feedback">Purchase order number is required.</div>
      </div>
    </div>
    <!-- b. Invoice Number -->
    <div class="col-12 col-sm-6 mb-3 d-flex align-items-center">
      <label
        for="invoiceNumber"
        class="form-label f-font text-nowrap required mb-0 me-3"
        >Invoice #</label
      >
      <div>
        <input
          id="invoiceNumber"
          type="text"
          class="form-control f-font"
          [ngClass]="{
            'is-invalid':
              invoicePaymentsForm.get('invoiceNumber')?.invalid &&
              invoicePaymentsForm.get('invoiceNumber')?.touched
          }"
          formControlName="invoiceNumber"
        />
        <div
          class="invalid-feedback"
          *ngIf="invoicePaymentsForm.get('invoiceNumber')?.hasError('required')"
        >
          <small>Invoice number is required.</small>
        </div>
        <div
          class="invalid-feedback"
          *ngIf="
            invoicePaymentsForm.get('invoiceNumber')?.hasError('maxlength')
          "
        >
          <small>Must be less tha 50 characters.</small>
        </div>
        <div
          class="invalid-feedback"
          *ngIf="invoicePaymentsForm.get('invoiceNumber')?.hasError('pattern')"
        >
          <small>Only accepts numbers.</small>
        </div>
      </div>
    </div>
    <!--  c. Bill of Lading -->
    <div>
      <app-reactive-file-input
        [maxMBs]="5"
        [controlName]="'billOfLading'"
        [labelText]="'Bill of Lading'"
        [required]="true"
        [formParent]="invoicePaymentsForm"
        [extensionsAllowed]="['pdf', 'png', 'jpg']"
        (uploadedFileName)="getUploadedBillOfLading($event)"
      ></app-reactive-file-input>
    </div>
    <!--  d. Packing List -->
    <div>
      <app-reactive-file-input
        [maxMBs]="5"
        [controlName]="'packingList'"
        [labelText]="'Packing List'"
        [required]="true"
        [formParent]="invoicePaymentsForm"
        [extensionsAllowed]="['pdf', 'png', 'jpg']"
        (uploadedFileName)="getUploadedPackingList($event)"
      ></app-reactive-file-input>
    </div>
  </div>
</form>
