import { HttpHelperService } from './../helper/http-helper.service';
import { Injectable } from '@angular/core';
import { rolesCatalog } from '../../fixtures/roles/rolesCatalog';
import { catchError, Observable, of, tap, throwError } from 'rxjs';
import { ICatalog } from 'src/app/models/catalog';
import { CacheService } from '../helper/cache.service';
import { IRole } from 'src/app/models/role';

// TODO Improve performance when calling this service
@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  constructor(
    private httpHelperService: HttpHelperService,
    private cacheService: CacheService
  ) {}

  getRolesCatalog() {
    return of([...rolesCatalog]);
  }

  getFormatsCatalog(): Observable<ICatalog> {
    const cacheCatalogs = this.cacheService.get('catalog-formats');
    if (cacheCatalogs) {
      return of(cacheCatalogs);
    }
    return this.httpHelperService.get('/catalogs/formats').pipe(
      tap((data: any) => {
        this.cacheService.set('catalog-formats', data);
      }),
      catchError((err) => {
        throw new Error('Catalog API. Details: ' + err.error.message);
      })
    );
  }

  getDateFormatsCatalog() {
    return this.httpHelperService.get('/catalogs/date-formats').pipe(
      catchError((err) => {
        throw new Error('Catalog API. Details: ' + err.error.message);
      })
    );
  }

  getTimeFormatsCatalog() {
    return this.httpHelperService.get('/catalogs/time-formats').pipe(
      catchError((err) => {
        throw new Error('Catalog API. Details: ' + err.error.message);
      })
    );
  }
  getTimeZonesCatalog() {
    return this.httpHelperService.get('/catalogs/timezones').pipe(
      catchError((err) => {
        throw new Error('Catalog API. Details: ' + err.error.message);
      })
    );
  }

  sortList(section: string, sorts: any[]) {
    if (!section || !sorts) {
      return throwError(() => new Error('Parameters Must Be Provided'));
    }
    return this.httpHelperService
      .post(`/catalogs/sorting/${section}`, { sorting: sorts })
      .pipe(
        catchError((err) => {
          throw new Error('Catalog API. Details: ' + err.error.message);
        })
      );
  }
}
