import {
  PoStatus,
  PoStatusEndpointValue,
} from 'src/app/models/purchase-orders';

export const AllStatusObject = [
  {
    text: PoStatus.NEW,
    value: PoStatusEndpointValue.NEW,
  },
  {
    text: PoStatus.ACCEPT,
    value: PoStatusEndpointValue.ACCEPT,
  },
  {
    text: PoStatus.REJECT,
    value: PoStatusEndpointValue.REJECT,
  },
  {
    text: PoStatus.UPDATE,
    value: PoStatusEndpointValue.UPDATE,
  },
  {
    text: PoStatus.REQ_DATE_CHANGE,
    value: PoStatusEndpointValue.REQ_DATE_CHANGE,
  },
  {
    text: PoStatus.REQ_LINE_ITEM_CHANGE,
    value: PoStatusEndpointValue.REQ_LINE_ITEM_CHANGE,
  },
];

export const AllTableStatusObject = [
  {
    text: PoStatus.NEW,
    value: PoStatusEndpointValue.NEW,
  },
  {
    text: PoStatus.ACCEPT,
    value: PoStatusEndpointValue.ACCEPT,
  },
  {
    text: PoStatus.REJECT,
    value: PoStatusEndpointValue.REJECT,
  },
  {
    text: PoStatus.UPDATE,
    value: PoStatusEndpointValue.UPDATE,
  },
  {
    text: PoStatus.REQ_DATE_CHANGE,
    value: PoStatusEndpointValue.REQ_DATE_CHANGE,
  },
  {
    text: PoStatus.REQ_LINE_ITEM_CHANGE,
    value: PoStatusEndpointValue.REQ_LINE_ITEM_CHANGE,
  },
];

export interface IAllStatusObject {
  text: string;
  value: string;
}
