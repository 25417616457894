<div class="pt-3">
  <div class="banner" [ngClass]="bannerBackgroundColor">
    <div class="container h-100 d-flex justify-content-center">
      <div class="logo-container text-center d-flex align-items-center">
        <img
          class="logo-image"
          src="{{ imagePathLogo }}"
          alt="Fender Logo White"
        />
        <span class="text-white f-font f-bold fst-italic supplier-text"
          >Supplier</span
        >
      </div>
    </div>
  </div>
</div>
