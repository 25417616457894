import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageManagerService } from '../managers/storage-manager.service';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '../helper/helper.service';
import Sockette from 'sockette';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  public ws: any;
  CHANNEL_LIST = ['downloads'];
  ROLES_ON_WEBSOCKET = [
    'FMIC-Admin',
    'FMIC-Customer-Service',
    'FMIC-Fender-Internal',
    'Supplier-Admin',
    'Supplier-Manager',
    'Supplier-Finance',
    'Supplier-Factory',
  ];
  currentRole: string = '';
  private websocketMessage = new BehaviorSubject<string>('');
  constructor(
    private storageManagerService: StorageManagerService,
    private httpClient: HttpClient,
    private helper: HelperService
  ) {}

  init(token: string, email: string | undefined, role: string) {
    this.currentRole = role ? role : '';
    if (this.ROLES_ON_WEBSOCKET.includes(role)) {
      this.ws = new Sockette(environment.websocket.url, {
        timeout: 5e3,
        maxAttempts: 10,
        onopen: (e: any) => {
          console.log('Connected');
          this.authenticate(token, email);
        },
        onmessage: this.onMessageHandler.bind(this),
        onreconnect: (e: any) => this.reconnect.bind(this),
        // onmaximum: (e: any) => console.log('Stop Attempting!', e),
        onclose: (e: any) => {
          // console.log('Closed!', e);
          this.reconnect();
        },
        onerror: (e: any) => console.log('Error:', e),
      });
    }
  }
  reconnect() {
    console.info('Reconnecting...');
    setTimeout(this.ws.reconnect, 500);
  }
  onSignOut() {
    if (this.ROLES_ON_WEBSOCKET.includes(this.currentRole)) {
      this.ws.close();
    }
  }
  subscribeToChannels() {
    this.ws.json({ action: 'subscribe', channels: this.CHANNEL_LIST });
  }
  authenticate(token: string, email: string | undefined) {
    console.log('Authenticated');
    this.ws.json({ action: 'subscribe', channels: this.CHANNEL_LIST });
    this.ws.json({
      action: 'authenticate',
      data: {
        token: token,
        user: { email: email },
      },
    });
  }
  onMessageHandler(_event: any) {
    const event = JSON.parse(_event.data);
    console.log('Message', event);
    if (event.url) {
      this.helper.createDownloadLink(event.url);
    }
    this.setHandlerMessage(event.message);
  }

  setHandlerMessage(message: string) {
    this.websocketMessage.next(message);
  }
  returnWebsocketMessageHandler() {
    return this.websocketMessage.asObservable();
  }
}
