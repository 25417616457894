import { Component, OnInit } from '@angular/core';
import { LookupService } from 'src/app/services/lookup/lookup.service';
import { ILookupRecentSearches } from '../../../models/lookup';

@Component({
  selector: 'app-my-lookup',
  templateUrl: './my-lookup.component.html',
  styles: [],
})
export class MyLookupComponent implements OnInit {
  constructor(private lookupService: LookupService) {}

  recentSearchesList: ILookupRecentSearches[] = [];

  ngOnInit(): void {
    // this.getRecentSearches();
  }

  // getRecentSearches() {
  //   console.log('gotRecent');
  //   this.lookupService.getRecentSearches().subscribe({
  //     next: (data) => {
  //       console.log(data);
  //       this.recentSearchesList = data;
  //     },
  //     error: () => {},
  //     complete: () => {},
  //   });
  // }
}
