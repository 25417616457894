<div class="form-check mb-4" *ngIf="roles; else loadingRoles">
  <!-- Select All Checkbox -->
  <input
    class="form-check-input"
    type="checkbox"
    id="selectAllCheckbox"
    [(ngModel)]="allRolesCheckbox"
    (change)="checkAllRolesToggle()"
    data-cy="role-selector_select-all"
  />
  <span class="form-check-label f-font" for="selectAllCheckbox">
    Select all
  </span>

  <div class="row ps-4">
    <div class="col-lg-3 col-sm-6 col-12 mt-2">
      <!-- Supplier All Checkbox -->
      <input
        class="form-check-input"
        type="checkbox"
        id="supplierAllCheckbox"
        [(ngModel)]="supplierRolesCheckbox"
        (change)="checkDealerFenderRolesToggle('Supplier')"
        data-cy="role-selector_select-supplier-all"
      />
      <span class="form-check-label f-font" for="supplierAllCheckbox">
        Supplier
      </span>
      <div
        class="ps-4"
        *ngFor="let role of roles | rolesFilter : 'Supplier'; let i = index"
      >
        <input
          class="form-check-input"
          type="checkbox"
          [(ngModel)]="role.checked"
          (change)="checkSelectedRoles()"
          [attr.data-cy]="'role-selector_select-' + role.code"
        />
        <span class="form-check-label f-font" for="supplierAllCheckbox">
          {{ role.role }}
        </span>
      </div>
    </div>

    <div class="col-lg-3 col-sm-6 col-12 mt-2">
      <!-- Fender All Checkbox -->
      <input
        class="form-check-input"
        type="checkbox"
        id="fenderAllCheckbox"
        [(ngModel)]="fenderRolesCheckbox"
        (change)="checkDealerFenderRolesToggle('Fender')"
        data-cy="role-selector_select-fmic-all"
      />
      <span class="form-check-label f-font" for="fenderAllCheckbox">
        Fender
      </span>
      <div
        class="ps-4"
        *ngFor="let role of roles | rolesFilter : 'Fender'; let i = index"
      >
        <input
          class="form-check-input"
          type="checkbox"
          [(ngModel)]="role.checked"
          (change)="checkSelectedRoles()"
          [attr.data-cy]="'role-selector_select-' + role.code"
        />
        <span class="form-check-label f-font" for="fenderAllCheckbox">
          {{ role.role }}
        </span>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingRoles>
  <div class="m-4">
    <app-fender-tone-loading-div [black]="true"></app-fender-tone-loading-div>
  </div>
</ng-template>
