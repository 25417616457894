<div class="d-flex align-items-center lookup">
  <i class="fa-solid fa-barcode fa-2xl me-3"></i>
  <div class="form-group w-100 me-3">
    <div class="input-group">
      <input
        #search
        type="text"
        class="form-control f-font"
        data-cy="lookup_widget-search_input"
        [formControl]="searchQuery"
        placeholder="Scan or Type Model/SKU # or UPC"
        (keyup.enter)="onSearch(searchQuery.value)"
      />
    </div>
  </div>
  <button
    class="f-btn f-xs f-blue"
    data-cy="lookup_widget-search_btn"
    (click)="onSearch(searchQuery.value)"
  >
    <i class="fa-solid fa-magnifying-glass"></i> Search
  </button>
</div>
<!-- NO RESULT - Label -->
<ng-container *ngIf="noResultAlert && !loadingSearches">
  <h4 class="f-font mt-2 text-center">No Results</h4>
</ng-container>
<!-- NO No Recent Seraches - Label -->
<div
  class="card-body h-75 d-flex justify-content-center align-items-center f-font"
  *ngIf="
    recentSearchesList?.length == 0 &&
    !selectedProduct &&
    !loadingSearches &&
    !noResultAlert
  "
>
  No Recent Searches
</div>
<!-- Loading Animation -->
<div
  class="card-body h-75 d-flex justify-content-center align-items-center"
  *ngIf="loadingSearches"
>
  <app-fender-tone-loading-div [black]="true"></app-fender-tone-loading-div>
</div>

<!-- LIST RESULTS -->
<div
  class="card-body h-75 d-flex justify-content-center align-items-center"
  *ngIf="serachesListEmpty"
>
  <span class="f-font">No recent searches</span>
</div>

<ng-container
  *ngIf="recentSearchesList?.length && !loadingSearches && !selectedProduct"
>
  <div class="f-font mt-3">
    <h4 class="f-bold m-0">Recent Searches</h4>
    <hr class="my-1" />
  </div>
  <div
    class="lookup-widget-card-height f-font overflow-auto"
    [ngClass]="{ 'no-results-alert': noResultAlert }"
  >
    <table class="f-table table-responsive-small lookup-table">
      <tbody>
        <tr *ngFor="let recentSearch of recentSearchesList">
          <td data-label="Number">
            <span>{{ recentSearch.productNumber }}</span>
          </td>
          <td data-label="Name" class="td-max-trunc">
            <div class="d-flex">
              <div class="long-and-truncated-parent">
                <p class="f-uppercase m-0 long-and-truncated">
                  {{ recentSearch.productDisplayName }}
                </p>
              </div>
            </div>
          </td>
          <td data-label="Date">
            <span class="text-nowrap">{{
              recentSearch.searchedAt | date : userDateFormat
            }}</span>
          </td>
          <td data-label="Search">
            <span>
              <a
                class="f-btn btn-xs f-blue btn-std"
                data-cy="lookup_widget-serach_recent_btn"
                type="button"
                (click)="searchFromRecent(recentSearch.productNumber)"
              >
                <i class="fa-solid fa-magnifying-glass fa-white"></i>
              </a>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>

<!-- SELECTED RESULT  -->
<div
  class="lookup-widget-card-height lookup-widget mt-3 f-font"
  [ngClass]="{ 'no-results-alert': noResultAlert }"
  *ngIf="selectedProduct && !loadingSearches"
>
  <!-- SELECTED RESULT --- [SMALL] -->
  <div class="lookup-widget-sm-screen">
    <div class="d-flex justify-content-between">
      <h4 class="f-bold m-0 d-inline">Search Result</h4>
      <a
        class="cursor-pointer"
        data-cy="lookup_widget-clear_btn-sm"
        (click)="onClear()"
        >Clear</a
      >
    </div>
    <div>
      <hr class="my-1" />
    </div>
    <div class="d-flex align-items-center">
      <div class="me-4">
        <small class="">Model #</small>
        <p class="m-0">{{ selectedProduct.selectedSku.skuNumber }}</p>
      </div>
      <div class="me-4">
        <small class="">UPC</small>
        <p class="m-0">{{ selectedProduct.productSpecs.upcNumber }}</p>
      </div>
      <div class="me-4 long-and-truncated-parent">
        <small>Name</small>
        <p class="m-0 text-uppercase long-and-truncated">
          {{ selectedProduct.productSpecs.modelName }}
        </p>
      </div>
      <div>
        <button
          (click)="viewInLookupPage(selectedProduct.selectedSku.skuNumber)"
          class="f-btn f-xs f-red"
          data-cy="lookup_widget-view_btn-sm"
        >
          View
        </button>
      </div>
    </div>
    <div
      *ngIf="selectedProduct.selectedSku.images.mainSmAll.length; else noImages"
      class="d-flex justify-content-center"
    >
      <img
        src="{{
          'https://www.fmicassets.com/Damroot/' +
            selectedProduct.selectedSku.images.mainLgAll[0]
        }}"
        class="img-fluid"
      />
    </div>
  </div>
  <!-- end SELECTED RESULT --- [SMALL] -->
  <!-- SELECTED RESULT --- [LARGE] -->
  <div class="lookup-widget-lg-screen row">
    <div class="col-9 d-flex justify-content-center align-items-center">
      <div
        *ngIf="
          selectedProduct.selectedSku.images.mainSmAll.length;
          else noImages
        "
      >
        <img
          src="{{
            'https://www.fmicassets.com/Damroot/' +
              selectedProduct.selectedSku.images.mainLgAll[0]
          }}"
          class="img-fluid"
        />
      </div>
    </div>
    <div class="col-3">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="f-bold m-0 d-inline search-result-text">Search Result</h4>
        <a
          class="cursor-pointer search-result-text"
          data-cy="lookup_widget-clear_btn-lg"
          (click)="onClear()"
          >Clear</a
        >
      </div>
      <div>
        <hr class="my-1" />
        <div class="row">
          <div class="col-6 pe-1 m-0">
            <small class="">Model #</small>
            <p class="m-0 lh-1">{{ selectedProduct.selectedSku.skuNumber }}</p>
          </div>
          <div class="col-6 p-0 m-0">
            <small class="">UPC</small>
            <p class="m-0 lh-1">{{ selectedProduct.productSpecs.upcNumber }}</p>
          </div>
        </div>

        <div class="me-4 long-and-truncated-parent">
          <small>Name</small>
          <p class="m-0 text-uppercase long-and-truncated max-height-3lh">
            {{ selectedProduct.productSpecs.modelName }}
          </p>
        </div>
        <div class="mt-3">
          <button
            data-cy="lookup_widget-view_btn-lg"
            (click)="viewInLookupPage(selectedProduct.selectedSku.skuNumber)"
            class="f-btn f-xs f-red"
          >
            View
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- end SELECTED RESULT --- [LARGE] -->

  <ng-template #noImages>
    <div
      class="d-flex flex-column justify-content-center align-items-center mt-2 pt-5 pb-4"
    >
      <i class="fa-solid fa-images fa-2xl fa-dark-grey"></i>
      <small class="f-font mt-3">No image available</small>
    </div>
  </ng-template>
</div>
