import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IProductLineCheckbox } from 'src/app/fixtures/purchase-orders/product-line-catalog';

@Component({
  selector: 'app-product-line-checkbox',
  templateUrl: './product-line-checkbox.component.html',
  styles: [],
})
export class ProductLineCheckboxComponent implements OnInit {
  constructor() {}

  @Input() fenderOptions: IProductLineCheckbox[] = [];
  @Input() specialityOptions: IProductLineCheckbox[] = [];

  @Output() checkboxChange: EventEmitter<number[]> = new EventEmitter();

  // Checkbox Status
  public allOptionCheckbox: boolean = false;
  public fenderOptionCheckbox: boolean = false;
  public specialityOptionCheckbox: boolean = false;

  // Collapse States
  fenderIsCollapsed: boolean = true;
  specialityIsCollapsed: boolean = true;

  ngOnInit(): void {}

  // ==== Toggle Group Checkboxes ===
  // All
  onToggleAllOptions() {
    this.specialityOptions.forEach((option: IProductLineCheckbox) => {
      option.checked = this.allOptionCheckbox;
    });
    this.fenderOptions.forEach((option: IProductLineCheckbox) => {
      option.checked = this.allOptionCheckbox;
    });
    this.onChangeCheckbox();
  }
  // Fender
  onToggleFenderOptions() {
    this.fenderOptions.forEach((option: IProductLineCheckbox) => {
      option.checked = this.fenderOptionCheckbox;
    });
    this.onChangeCheckbox();
  }
  // Speciality
  onToggleSpecialityOptions() {
    this.specialityOptions.forEach((option: IProductLineCheckbox) => {
      option.checked = this.specialityOptionCheckbox;
    });
    this.onChangeCheckbox();
  }
  // ==== Check Every Group Checkboxes ===
  checkEvery() {
    this.fenderOptionCheckbox = this.fenderOptions.every(
      (option: IProductLineCheckbox) => option.checked
    );
    this.specialityOptionCheckbox = this.specialityOptions.every(
      (option: IProductLineCheckbox) => option.checked
    );

    this.allOptionCheckbox =
      this.fenderOptionCheckbox && this.specialityOptionCheckbox;
  }

  // Clear Filter
  clear() {
    console.log('clear');
    // Group
    this.allOptionCheckbox = false;
    this.fenderOptionCheckbox = false;
    this.specialityOptionCheckbox = false;
    // Collapse
    this.fenderIsCollapsed = true;
    this.specialityIsCollapsed = true;
    // Options
    this.fenderOptions.forEach((option: IProductLineCheckbox) => {
      option.checked = false;
    });
    this.specialityOptions.forEach((option: IProductLineCheckbox) => {
      option.checked = false;
    });
    this.onChangeCheckbox();
  }

  // on Change
  onChangeCheckbox() {
    const checkedProductLines = this.fenderOptions
      .concat(this.specialityOptions)
      .filter((option: IProductLineCheckbox) => option.checked)
      .map((option: IProductLineCheckbox) => {
        return option.productLine;
      });
    this.checkEvery();
    this.checkboxChange.emit(checkedProductLines);
  }
}
