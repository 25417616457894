<div class="d-flex align-items-center acc-rej-select-container">
  <label class="pt-1 f-font me-2">Status:</label>

  <!-- On Edit -->
  <ng-container *ngIf="!status">
    <select
      class="form-select acc-rej-select"
      [(ngModel)]="status"
      (ngModelChange)="statusChanged($event)"
    >
      <option value="" selected disabled>Select</option>
      <option
        *ngFor="let option of visibleStatusOptions"
        [value]="option.value"
      >
        {{ option.text }}
      </option>
    </select>
  </ng-container>

  <!-- Accepted  -->
  <ng-container *ngIf="status === 'Accepted'">
    <div class="d-flex align-items-center">
      <p class="my-0 ms-1 me-2 text-success">Accepted</p>
      <button class="f-btn btn-xs f-blue btn-std" (click)="setOnEdit()">
        <i class="fa-solid fa-pencil fa-white"></i>
      </button>
    </div>
  </ng-container>

  <!-- Rejected  -->
  <ng-container *ngIf="status === 'Rejected'">
    <div class="d-flex align-items-center">
      <p class="my-0 ms-1 me-2 text-danger">Rejected</p>
      <button class="f-btn btn-xs f-blue btn-std me-3" (click)="setOnEdit()">
        <i class="fa-solid fa-pencil fa-white"></i>
      </button>
      <!-- add note -->
      <ng-container *ngIf="existingNote">
        <div class="d-flex align-items-center">
          <button
            class="f-btn btn-xs f-green btn-std me-2"
            (click)="showNoteModal(noteModal)"
          >
            <p class="f-font m-0">Show note</p>
          </button>
          <p class="f-font my-0">
            {{ existingNote | truncate }}
          </p>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <!-- Requested Date Change  -->
  <ng-container *ngIf="status === 'Requested Date Change'">
    <div class="d-flex align-items-center">
      <p class="my-0 ms-1 me-2 text-primary">Requested Date Change</p>
      <button
        *ngIf="isFmicUser"
        class="f-btn btn-xs f-blue btn-std me-2"
        (click)="setOnEdit()"
      >
        <i class="fa-solid fa-pencil fa-white"></i>
      </button>
      <button
        class="f-btn btn-xs f-yellow"
        (click)="showDateChangeModal(dateChangeModal)"
      >
        Show Dates
      </button>
    </div>
  </ng-container>

  <!-- Requested Line Item Change  -->
  <ng-container *ngIf="status === 'Requested Line Item Change'">
    <div class="d-flex align-items-center">
      <p class="my-0 ms-1 me-2 text-primary">Requested Line Item Change</p>
      <button
        *ngIf="isFmicUser"
        class="f-btn btn-xs f-blue btn-std me-2"
        (click)="setOnEdit()"
      >
        <i class="fa-solid fa-pencil fa-white"></i>
      </button>
    </div>
  </ng-container>

  <!-- Updated  -->
  <ng-container *ngIf="status === 'Updated'">
    <div class="d-flex align-items-center">
      <p class="my-0 ms-1 me-2 text-primary">Updated</p>
      <button class="f-btn btn-xs f-blue btn-std" (click)="setOnEdit()">
        <i class="fa-solid fa-pencil fa-white"></i>
      </button>
    </div>
  </ng-container>
</div>

<ng-template #noteModal>
  <div class="modal-header border-bottom-0">
    <h3 class="modal-title pull-left f-font f-bold">Add a Note</h3>
    <div type="button" aria-label="Close" (click)="closeNoteModal()">
      <i class="fa-regular fa-circle-xmark fa-xl"></i>
    </div>
  </div>
  <div class="modal-body">
    <div>
      <p class="f-font"><span class="f-bold">PO#: </span>{{ poNumber }}</p>
    </div>
    <div>
      <div class="form-group">
        <textarea
          #noteTextArea
          class="form-control"
          [formControl]="noteControl"
          rows="6"
          [ngClass]="{
            'is-invalid': noteTextArea.value.length > 500
          }"
        ></textarea>
        <div class="d-flex justify-content-end mt-1">
          <small
            class="f-font m-0"
            [ngClass]="{
              'text-danger': noteTextArea.value.length > 500
            }"
            >Characters Remaining:&nbsp;{{
              500 - noteTextArea.value.length
            }}/500</small
          >
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer border-top-0">
    <div class="d-flex">
      <button
        class="f-btn f-green f-xs me-2"
        [disabled]="
          !noteControl.valid || noteControl.pristine || loadingSubmition
        "
        (click)="saveNote()"
      >
        <i class="fa-regular fa-floppy-disk"></i>
        <span class="me-2">Save</span>
        <ng-container *ngIf="loadingSubmition">
          <app-fender-tone-loading-div
            [inline]="true"
          ></app-fender-tone-loading-div>
        </ng-container>
      </button>
      <button class="f-btn f-black f-xs" (click)="closeNoteModal()">
        Cancel
      </button>
    </div>
  </div>
</ng-template>

<ng-template #dateChangeModal>
  <div class="modal-header border-bottom-0">
    <h3 *ngIf="!submittedAlert" class="modal-title pull-left f-font f-bold">
      Request Date Change
    </h3>
    <h3 *ngIf="submittedAlert" class="modal-title pull-left f-font f-bold">
      <i class="fa-regular fa-square-check fa-green me-2"></i>
      Submitted
    </h3>
    <div type="button" aria-label="Close" (click)="closeDateChangeModal()">
      <i class="fa-regular fa-circle-xmark fa-xl"></i>
    </div>
  </div>
  <!-- Modal Body -->
  <div *ngIf="!submittedAlert" class="modal-body">
    <div class="row">
      <div class="col-4">
        <div>
          <p class="f-font f-bold">Original Ship Date</p>
        </div>
        <div>
          <p class="f-font f-bold">New Ship Date</p>
        </div>
      </div>
      <div class="col-8">
        <div>
          <p class="f-font">{{ originalShipDate | date : userDateFormat }}</p>
        </div>
        <div>
          <p class="f-font d-inline me-3">
            <span
              *ngIf="!existingRequestedShipDate"
              [class.empty-section-text]="!requestedDateChange"
            >
              {{
                requestedDateChange
                  ? (requestedDateChange | date : userDateFormat)
                  : "Select Date"
              }}</span
            >
            <span *ngIf="existingRequestedShipDate">
              {{ existingRequestedShipDate | date : userDateFormat }}</span
            >
          </p>
          <!-- DATE INPUT -->
          <ng-container *ngIf="!existingRequestedShipDate">
            <input
              type="text"
              [bsConfig]="datePickerConfig"
              [formControl]="dateChangeControl"
              class="form-control invisible-date-input pb-2 d-inline"
              #dp="bsDatepicker"
              bsDatepicker
              [minDate]="minDate"
              (bsValueChange)="onDateValueChange($event)"
            />
            <button class="f-btn btn-xs f-blue btn-std" (click)="dp.toggle()">
              <i class="fa-regular fa-calendar fa-white"></i>
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Body - Submitted Alert -->
  <div *ngIf="submittedAlert" class="modal-body">
    <div>
      <p class="f-font">Your request has been submitted.</p>
    </div>
    <div>
      <p class="f-font">
        You will be notified when the Purchase Order has been updated.
      </p>
    </div>
  </div>
  <!-- Modal Footer -->
  <div class="modal-footer border-top-0">
    <div class="d-flex">
      <ng-container *ngIf="!existingRequestedShipDate">
        <button
          class="f-btn f-black f-xs me-2"
          (click)="closeDateChangeModal()"
        >
          Cancel
        </button>
        <button
          class="f-btn f-blue f-xs"
          [disabled]="
            !dateChangeControl.valid ||
            dateChangeControl.pristine ||
            loadingSubmition
          "
          (click)="saveDateChangeRequest()"
        >
          <i class="fa-regular fa-paper-plane"></i>
          <span class="me-2">Submit</span>
          <ng-container *ngIf="loadingSubmition">
            <app-fender-tone-loading-div
              [inline]="true"
            ></app-fender-tone-loading-div>
          </ng-container>
        </button>
      </ng-container>
      <ng-container *ngIf="existingRequestedShipDate">
        <button
          class="f-btn f-black f-xs me-2"
          (click)="closeDateChangeModal()"
        >
          Close
        </button>
      </ng-container>
    </div>
  </div>
</ng-template>

<!-- Submit Line Item Change  -->
<ng-template #confirmLineItemChangeModal>
  <div class="modal-header border-bottom-0">
    <h3 *ngIf="!submittedAlert" class="modal-title pull-left f-font f-bold">
      <span class="text-warning me-2"
        ><i class="fa-solid fa-triangle-exclamation"></i
      ></span>
      Attention
    </h3>
    <h3 *ngIf="submittedAlert" class="modal-title pull-left f-font f-bold">
      <i class="fa-regular fa-square-check fa-green me-2"></i>
      Submitted
    </h3>
    <div type="button" aria-label="Close">
      <i
        class="fa-regular fa-circle-xmark fa-xl"
        (click)="closeLineItemChangeModal()"
      ></i>
    </div>
  </div>
  <!-- Modal Body -->
  <div *ngIf="!submittedAlert" class="modal-body">
    <div>
      <p class="f-font">
        Are you sure you want to submit your request? Additional changes cannot
        be made until your request is reviewed.
      </p>
    </div>
  </div>
  <div *ngIf="submittedAlert" class="modal-body">
    <div>
      <p class="f-font">Your request has been submitted.</p>
    </div>
    <div>
      <p class="f-font">
        You will be notified when the Purchase Order has been updated.
      </p>
    </div>
  </div>
  <!-- Modal Footer -->
  <div class="modal-footer border-top-0">
    <div class="d-flex">
      <ng-container *ngIf="!submittedAlert">
        <button
          class="f-btn f-black f-xs me-2"
          (click)="closeLineItemChangeModal()"
        >
          Cancel
        </button>
        <button class="f-btn f-blue f-xs" (click)="saveLineItemChangeRequest()">
          <i class="fa-regular fa-paper-plane me-2"></i>
          <span class="me-2">Submit</span>
          <ng-container *ngIf="loadingSubmition">
            <app-fender-tone-loading-div
              [inline]="true"
            ></app-fender-tone-loading-div>
          </ng-container>
        </button>
      </ng-container>
      <ng-container *ngIf="submittedAlert">
        <button
          class="f-btn f-black f-xs me-2"
          (click)="closeLineItemChangeModal()"
        >
          Close
        </button>
      </ng-container>
    </div>
  </div>
</ng-template>
