<!-- Date Section -->
<!-- Title -->
<h4 class="mb-1 mt-3 font-size-18">{{ title }}</h4>
<!-- From -->
<div [formGroup]="form">
  <div class="d-flex align-items-center justify-content-between mb-1">
    <p class="m-0">From</p>
    <div class="d-flex align-items-center">
      <input
        class="form-control form-control-sm mx-1 po-date-range-input"
        type="text"
        #fromDate="bsDatepicker"
        bsDatepicker
        placement="top"
        formControlName="from"
        [bsConfig]="bsConfig"
        [maxDate]="maxDate"
      />
      <button
        type="button"
        class="btn f-btn f-blue btn-sm py-1 px-2 rounded"
        (click)="fromDate.toggle()"
      >
        <i class="fa-regular fa-calendar"></i>
      </button>
    </div>
  </div>
  <!-- To -->
  <div class="d-flex align-items-center justify-content-between mb-1">
    <p class="m-0">To</p>
    <div class="d-flex align-items-center">
      <input
        class="form-control form-control-sm mx-1 po-date-range-input"
        type="text"
        #toDate="bsDatepicker"
        bsDatepicker
        placement="top"
        formControlName="to"
        [bsConfig]="bsConfig"
        [minDate]="minDate"
      />
      <button
        type="button"
        class="btn f-btn f-blue btn-sm py-1 px-2 rounded"
        (click)="toDate.toggle()"
      >
        <i class="fa-regular fa-calendar"></i>
      </button>
    </div>
  </div>
</div>
