import { Injectable } from '@angular/core';
import Amplify, { Storage } from 'aws-amplify';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class StorageManagerService {
  constructor() {
    Amplify.configure(environment.storage.AWSS3);
  }

  async uploadFile(fileName: string | null, file: File) {
    if (!fileName || !file) {
      return null;
    }
    try {
      await Storage.put(fileName, file, {
        level: 'private',
        contentType: file.type,
      });
      return fileName;
    } catch (error) {
      console.log('Error uploading file: ', error);
      return null;
    }
  }
  async getFile(fileName: string|null, path: string, privateAccess: boolean|undefined) {
    if (!fileName) {
      return '';
    }
    try {
      let filenamePath = path ? `${path}/${fileName}`: fileName;
      
      const x = await Storage.get(filenamePath, {
        level: privateAccess ? 'private': 'public',
        expires: 60,
      });
      return x;
    } catch (error) {
      console.log('Error downloading file: ', error);
      return '';
    }
  }
  async removeFile(fileName: string): Promise<boolean> {
    if (!fileName) {
      return false;
    }
    try {
      await Storage.remove(fileName, { level: 'private' });
      return true;
    } catch (error) {
      console.log('Error downloading file: ', error);
      return false;
    }
  }
  async downloadFile(fileName: string) {
    if (!fileName) {
      return '';
    }
    try {
      return await Storage.get(`${fileName}`);
    } catch (error) {
      console.log('Error downloading file: ', error);
      return '';
    }
  }
}
