<div
  id="documentsCard"
  class="card"
  *ngIf="isOnDashboard"
  [ngClass]="{
    'h-auto': isCollapse,
    fullscreen: isFullscreen
  }"
>
  <!-- Card Header -->
  <div class="card-header small-header-dark">
    <!-- Title -->
    <div>
      <i class="fa-solid fa-bell me-2"></i>
      <span class="card-title f-font">What's New</span>
    </div>
    <!-- Widgets Constrols - if isWidget -->
    <!-- <div class="d-flex align-items-center" *ngIf="isWidget && showControls">
      <div
        id="widgetControlCollapse"
        type="button"
        class="widget-controls-btn"
        (click)="controlsCollapse()"
      >
        <i class="fa-solid fa-minus" *ngIf="!isCollapse"></i>
        <i class="fa-solid fa-plus" *ngIf="isCollapse"></i>
      </div>
      <div
        id="widgetControlFullscreen"
        type="button"
        class="widget-controls-btn fa-xs"
        (click)="onFullscreen()"
      >
        <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
      </div>
      <div
        id="widgetControlClose"
        type="button"
        class="widget-controls-btn"
        (click)="onClose()"
      >
        <i class="fa-solid fa-xmark"></i>
      </div>
    </div> -->
    <!-- Exit Fullscreen - if widget Fullscreen -->
    <!-- <div *ngIf="isWidget && isFullscreen">
      <div
        id="widgetControlExitFullscreen"
        type="button"
        class="widget-controls-btn fa-xs"
        (click)="onExitFullScreen()"
      >
        <i class="fa-solid fa-down-left-and-up-right-to-center"></i>
      </div>
    </div> -->
  </div>
  <div
    id="documentsCardBody"
    [ngClass]="{
      'pb-2': isWidget,
      widget: isWidget,
      'on-collapse': isCollapse,
      'h-100': isFullscreen
    }"
    class="overflow-auto double-height announcements-widget"
  >
    <!-- CARD BODY  -->
    <div
      class="card-body"
      *ngIf="widgetAnnouncements.length && !announcementLoader"
    >
      <!-- ANNOUNCEMENTS  -->
      <div *ngFor="let announcement of widgetAnnouncements">
        <div class="d-flex mt-2 mb-1 flex-column flex-sm-row">
          <div class="image-thumbnail d-none d-sm-block">
            <img
              class="cursor-pointer"
              [src]="announcement.imageName"
              onError="this.src= '../../../../assets/images/fender-black.svg' "
              alt="announcement-thumbnail"
              (click)="onViewAnnouncement(announcement.id)"
            />
          </div>
          <div class="f-font flex-grow-1 px-3">
            <label
              class="cursor-pointer"
              (click)="onViewAnnouncement(announcement.id)"
              >{{ announcement.title }}</label
            >
            <span
              *ngIf="announcement.isNew"
              class="new-annoncement-tag f-bold ms-2"
              >NEW</span
            >
            <p class="lh-sm description-overflow mb-0 description-min-height">
              {{ announcement.description }}
            </p>
          </div>
          <div
            class="d-flex align-items-center justify-content-end me-2 me-sm-0"
          >
            <button
              class="f-btn f-red f-xs mt-2 mt-sm-0"
              (click)="onViewAnnouncement(announcement.id)"
            >
              View
            </button>
          </div>
        </div>
        <hr class="my-0" />
      </div>
    </div>
    <div
      class="card-body h-75 d-flex justify-content-center align-items-center"
      *ngIf="announcementLoader"
    >
      <app-fender-tone-loading-div
        [widget]="isWidget"
        [black]="true"
      ></app-fender-tone-loading-div>
    </div>
    <div
      class="card-body h-75 d-flex justify-content-center align-items-center"
      *ngIf="!widgetAnnouncements.length && !announcementLoader"
    >
      <span class="f-font"> No results found </span>
    </div>
  </div>
</div>

<div bsModal #news="bs-modal" class="modal fade" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="f-font f-bold modal-title pull-left">{{ newsTitle }}</h3>
        <button
          type="button"
          class="btn-close close pull-right"
          (click)="onModalHide(newsTitle); news.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div
          [innerHTML]="newsContent | safeHtml"
          class="images-contained-modal"
        ></div>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #video="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-sizes-name1"
>
  <div class="modal-dialog modal-lg video-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="f-font f-bold modal-title pull-left">{{ videoTitle }}</h3>
        <button
          type="button"
          class="btn-close close pull-right"
          (click)="onModalHide(videoTitle); video.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
      </div>
      <div
        class="modal-body d-flex justify-content-center contained-video-modal"
      >
        <app-video-player
          [videoCategory]="'videoCategory'"
          [videoTitle]="videoTitle"
          [url]="videoUrl"
          [autoplay]="true"
        ></app-video-player>
      </div>
    </div>
  </div>
</div>

<!-- ============= Toast Alerts ============ -->
<!-- Error Toast -->
<app-toast-alert
  #announcementErrorToast
  [selector]="'announcementError'"
  [title]="'Error'"
  [description]="'Sorry, unable to find announcement'"
  [style]="'bg-warning'"
></app-toast-alert>
