export const materialCategories = {
  General: [
    'Series',
    'Orientation',
    'Color',
    'Cover Color',
    'Amplifier Type',
    'Instrument Type',
    'Material',
    'Number of Strings',
    'Country Of Origin',
    'Platform',
  ],
  Neck: [
    'Headstock',
    'Headstock Binding',
    'Neck Material',
    'Neck Finish',
    'Neck Shape',
    'Neck Construction',
    'Neck Thickness',
    'Neck Mounting',
    'Neck Butt Width',
    'Neck Binding',
    'Neck Thickness 12th Fret',
    'Fingerboard Radius',
    'Fingerboard Material',
    'Position Inlays',
    'Side Dots',
    'Number of Frets',
    'Truss Rod',
    'Truss Rod Nut',
    'Nut Width',
    'Nut Material',
  ],
  Body: [
    'Body Finish',
    'Body Shape',
    'Body Top',
    'Body Binding',
    'Body Back',
    'Body Sides',
    'Body Style',
    'Body Material',
    'cutaway | Cutaway',
    'Contoured',
    'Arm Rest',
    'Bracing',
    'Waist',
    'Rosette',
    'Rim Material',
    'Purfling',
    'Tone Ring',
    'Sound Hole',
    'Resonator Material',
  ],
  Electronics: [
    'Bridge Pickup',
    'Middle Pickup',
    'Neck Pickup',
    'Pickup Configuration',
    'Lead Wire',
    'Magnet Type',
    'Magnet Wire',
    'Routing',
    'Shielded',
    'Pole Pieces',
    'Bobbin Material',
    'Controls',
    'Control Panel',
    'Switching',
    'Auxiliary Switching',
    'Special Electronics',
    'Inputs',
    'Auxiliary Input',
    'Headphone Jack',
    'Output',
    'Line Out',
    'Channels',
    'Effects',
    'Effects Loop',
    'Looper',
    'Connectivity',
    'Connection Type',
    'Other Connections',
    'DC Resistance',
    'Inductance',
    'Voltage',
    'Wattage',
    'Input Impedance',
    'Output Impedance',
    'Input Noise',
    'Noise Reduction',
    'Power Consumption',
    'Power Requirements',
    'Potentiometers',
    'Output Power',
    'Power Handling',
  ],
  Speakers: ['Speakers', 'Tweeter', 'Speaker Wattage', 'Total Impedance'],
  Tubes: ['Power Tubes', 'Preamp Tubes', 'Rectifier'],
  Hardware: [
    'Bridge',
    'Bridge Cover',
    'Tailpiece',
    'Bridge Pins',
    'Bridge Mounting',
    'Tremolo Block',
    'Resonator Cone',
    'Pickguard',
    'Pickguard Ply',
    'Pickup Covers',
    'Control Knobs',
    'Switch Tip',
    'Hardware Finish',
    'Tuning Machines',
    'Tuner Mounting Config',
    'String Trees',
    'String Ends',
    'Strings',
    'Tremolo Arm',
    'Neck Plate',
    'Coordinator Rods',
    'Strap Buttons',
    'Amplifier Covering',
    'Cabinet Material',
    'Grille Cloth',
    'Handle',
    'Tilt Back Legs',
    'Casters',
    'Handles/Straps',
    'Mounting Type',
    'Amplifier Jewel',
    'Speaker Jack',
    'Front Panel',
    'Reverb Tank Type',
    'Rear Control Panel',
    'Cable Ends',
    'Cable Capacitance',
    'Head',
    'Hooks',
  ],
  Measurements: [
    'Dimensions',
    'Weight',
    'Product Depth',
    'Product Height',
    'Product Width',
    'Upper Bout',
    'Lower Bout',
    'Body Depth',
    'Body Length',
    'Headstock Thickness',
    'Peg Hole Diameter',
    'Fret Size',
    'Scale Length',
    'Cable Length',
    'Pick Thickness',
    'Slide Thickness',
    'Strap Length',
    'Strap Width',
    'Case Interior Body Depth',
    'Case Interior Body Length',
    'Case Interior Lower Bout Width',
    'Case Interior Overall Length',
    'Case Interior Upper Bout Width',
    'GigBag Interior Body Depth',
    'GigBag Interior Body Length',
    'GigBag Interior Lower Bout Width',
    'GigBag Interior Overall Length',
    'GigBag Interior Upper Bout Width',
    'Shipping SKU Length',
    'Shipping Length',
    'Shipping SKU Width',
    'Shipping Width',
    'Shipping SKU Height',
    'Shipping Height',
    'Shipping SKU Weight',
    'Shipping Weight',
    'Dimensional',
    'Volume Unit',
    'Weight Unit',
  ],
  Accessories: [
    'Included Accessories',
    'Footswitch',
    'Recommended Cover',
    'Recommended Case',
    'Case Padding',
    'GigBag Padding',
    'Case Pockets & Compartments',
    'GigBag Pockets & Compartments',
    'Saddle Height Wrench',
    'Truss Rod Wrench',
  ],
  Miscellaneous: [
    'Disclaimer',
    'Commodity Code',
    'Inner Pack Qty',
    'Case Pack Qty',
    'String Gauges',
    'Style',
    'Unique Features',
    'Padding',
    'Exterior',
    'Interior',
    'Pick Shape',
    'Power Cable',
    'Cable Angles',
    'Tone',
    'Size',
  ],
};
