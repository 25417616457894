<app-browser-support
  (continueAnyways)="continueAnyways()"
  *ngIf="!isValidBrowser"
></app-browser-support>

<div class="container mt-5 padding-bottom-auth" *ngIf="isValidBrowser">
  <div class="d-flex justify-content-center align-items-center">
    <div class="card width-auth align-center">
      <form id="loginform" name="loginform" [formGroup]="loginForm">
        <div class="card-header py-3 f-font">
          <h3 class="f-font">{{ "Login" }}</h3>
        </div>
        <div class="card-body">
          <label for="emailInput" class="f-font">{{ "Email" }}</label>
          <div class="input-group has-validation mb-3">
            <input
              id="emailInput"
              name="email"
              type="email"
              autofocus
              class="form-control"
              formControlName="email"
            />
            <span class="input-group-text">
              <i class="fa-solid fa-user"></i>
            </span>
          </div>

          <label for="passwordInput" class="f-font">{{ "Password" }}</label>
          <div class="input-group has-validation">
            <input
              id="passwordInput"
              name="password"
              type="password"
              class="form-control"
              formControlName="password"
            />
            <span class="input-group-text">
              <i class="fa-solid fa-lock"></i>
            </span>
          </div>
          <div class="mb-3">
            <a id="forgot-password-a" href="forgot-password" class="f-font">{{
              "Forgot password?"
            }}</a>
          </div>

          <!-- STAY SIGNED IN INPUT -->
          <!-- <div class="form-check mb-3">
            <input
              name="stayLogged"
              class="form-check-input"
              type="checkbox"
              id="stayLoggedCheckbox"
              formControlName="stayLogged"
            />
            <label class="form-check-label" for="stayLoggedCheckbox">
              <small class="f-font">{{ "Stay signed in" }}</small>
            </label>
          </div> -->

          <div class="f-font text-danger text-center" *ngIf="signInError">
            <span id="invalidUserMessage">{{ singInErrorMessage }}</span>
          </div>
        </div>
        <div class="card-footer d-flex justify-content-between py-3">
          <div class="d-flex flex-column">
            <small
              ><a
                href="/documents/TermsofUse-SupplierPortal.pdf"
                target="_blank"
                class="f-font text-decoration-none"
                >Terms of Use</a
              ></small
            >
            <small
              ><a
                href="/documents/PrivacyPolicy-SupplierPortal.pdf"
                target="_blank"
                class="f-font text-decoration-none"
                >Privacy Policy</a
              ></small
            >
          </div>
          <button
            id="submitLoginButton"
            type="submit"
            class="f-btn f-xs f-blue"
            [disabled]="!loginForm.valid || !signInButtonActive"
            (click)="login()"
            (keyup.enter)="login()"
          >
            <span>
              {{ "Sign In" }}
            </span>
            <span *ngIf="loading">
              <app-fender-tone-loading-div
                [inline]="true"
              ></app-fender-tone-loading-div>
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
