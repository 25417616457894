import { Injectable } from '@angular/core';
import { Subscription, take, throwError } from 'rxjs';
import { HttpHelperService } from '../helper/http-helper.service';
import { IdentityService } from './identity.service';
import { IUser } from '../../models/user';
import { environment } from 'src/environments/environment';
import { FenderRoles } from '../../models/role';
import { SelectSupplierHelperService } from '../helper/select-supplier-helper.service';
declare let gtag: Function;

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsService {
  currentUserSubscription: Subscription | undefined;

  constructor(
    private identityService: IdentityService,
    private selectSupplierHelperService: SelectSupplierHelperService
  ) {}

  private fenderRoles = Object.values(FenderRoles);

  trackEvent(eventName: string, eventAction: string, eventLabel: string) {
    if (!eventName || !eventAction) {
      return throwError(() => new Error('Event info must be provided'));
    }
    if (this.currentUserSubscription) {
      this.currentUserSubscription.unsubscribe();
    }

    this.currentUserSubscription = this.identityService
      .getCurrentMeUser()
      .subscribe((user: IUser | any) => {
        gtag('event', eventName, {
          event_label: eventLabel,
          event_action: eventAction,
          supplier_number: this.getSuplierInfo(user, 'Number'),
          supplier_name: this.getSuplierInfo(user, 'Name'),
          userID: user.userId,
        });
      });
  }

  getSuplierInfo(user: IUser, info: string): string | undefined {
    if (this.fenderRoles.includes(user.role as FenderRoles)) {
      return 'Fender User';
    }
    switch (info) {
      case 'Number':
        return user.supplierNumber;
      case 'Name':
        console.log(
          this.selectSupplierHelperService.selectedSupplierName.value
        );
        return this.selectSupplierHelperService.selectedSupplierName.value;
    }
  }

  setGoogleAnalytics() {
    let googleId = environment.google.analytics;
    let customGtagScriptEle: HTMLScriptElement =
      document.createElement('script');
    customGtagScriptEle.async = true;
    customGtagScriptEle.src = `https://www.googletagmanager.com/gtag/js?id=${googleId}`;
    document.head.prepend(customGtagScriptEle);
    gtag('config', googleId);
  }
}
