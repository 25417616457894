import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
})
export class NotificationDialogComponent implements OnInit {
  @Input() title: string | undefined;
  @Input() description: string | undefined;
  @Output() confirmFunction: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onConfirmation(confirmation: boolean) {
    this.confirmFunction.emit(confirmation);
  }
}
