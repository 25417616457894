import { IdentityService } from './../common/identity.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  catchError,
  from,
  map,
  Observable,
  of,
  switchMap,
  take,
  throwError,
  timeout,
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HttpHelperService {
  constructor(
    private httpClient: HttpClient,
    private identityService: IdentityService
  ) {}

  get(url: string, params?: { [param: string]: any }): Observable<any> {
    if (!url) {
      return throwError(() => new Error('Url must be provided'));
    }

    return from(this.identityService.getIdToken()).pipe(
      switchMap((token) => {
        return this.httpClient
          .get(
            `${environment.api.endPoint}${
              url.indexOf('/') === 0 ? url : '/' + url
            }`,
            {
              params: params,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              responseType: 'json',
            }
          )
          .pipe(take(1), timeout(environment.api.timeout));
      }),
      catchError((err) => err)
    );
  }

  post(url: string, params?: any) {
    if (!url) {
      return throwError(() => new Error('Url must be provided'));
    }

    return from(this.identityService.getIdToken()).pipe(
      switchMap((token) => {
        return this.httpClient
          .post(
            `${environment.api.endPoint}${
              url.indexOf('/') === 0 ? url : '/' + url
            }`,
            params,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              responseType: 'json',
            }
          )
          .pipe(take(1), timeout(environment.api.timeout));
      }),
      catchError((err) => err)
    );
  }

  put(url: string, params?: any) {
    if (!url) {
      return throwError(() => new Error('Url must be provided'));
    }

    return from(this.identityService.getIdToken()).pipe(
      switchMap((token) => {
        return this.httpClient
          .put(
            `${environment.api.endPoint}${
              url.indexOf('/') === 0 ? url : '/' + url
            }`,
            params,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              responseType: 'json',
            }
          )
          .pipe(take(1), timeout(environment.api.timeout));
      }),
      catchError((err) => err)
    );
  }

  delete(url: string, params?: { [param: string]: any }): Observable<any> {
    if (!url) {
      return throwError(() => new Error('Url must be provided'));
    }

    return from(this.identityService.getIdToken()).pipe(
      switchMap((token) => {
        return this.httpClient
          .delete(
            `${environment.api.endPoint}${
              url.indexOf('/') === 0 ? url : '/' + url
            }`,
            {
              params: params,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              responseType: 'json',
            }
          )
          .pipe(take(1), timeout(environment.api.timeout));
      }),
      catchError((err) => err)
    );
  }
}
