export const productLineCatalog: IProductLineOptions[] = [
  {
    productLine: 92,
    description: 'Specialty SPA',
    grouping: 'Specialty',
  },
  {
    productLine: 33,
    description: 'Gretsch Acoustics',
    grouping: 'Specialty',
  },
  {
    productLine: 22,
    description: 'EVH',
    grouping: 'Specialty',
  },
  {
    productLine: 18,
    description: 'Japan Fender Electric',
    grouping: 'Fender',
  },
  {
    productLine: 16,
    description: 'Imported Fender Electric',
    grouping: 'Fender',
  },
  {
    productLine: 40,
    description: 'Guitar Amps',
    grouping: 'Fender',
  },
  {
    productLine: 79,
    description: 'Live Sound Hardware',
    grouping: 'Specialty',
  },
  {
    productLine: 31,
    description: 'USA Fender Acoustics',
    grouping: 'Fender',
  },
  {
    productLine: 87,
    description: 'Pedals & Electronics',
    grouping: 'Fender',
  },
  {
    productLine: 75,
    description: 'Pro Audio',
    grouping: 'Fender',
  },
  {
    productLine: 84,
    description: 'Consumer Electronics',
    grouping: 'Fender',
  },
  {
    productLine: 28,
    description: 'Jackson',
    grouping: 'Specialty',
  },
  {
    productLine: 30,
    description: 'Imported F/S Acoustics',
    grouping: 'Fender',
  },
  {
    productLine: 24,
    description: 'Gretsch Electric Guitar & Basses',
    grouping: 'Specialty',
  },
  {
    productLine: 77,
    description: 'Recording Hardware',
    grouping: 'Specialty',
  },
  {
    productLine: 91,
    description: 'Clothing & Gifts',
    grouping: 'Fender',
  },
  {
    productLine: 32,
    description: 'Mexico Fender Acoustics',
    grouping: 'Fender',
  },
  {
    productLine: 78,
    description: 'Live Sound Hardware',
    grouping: 'Specialty',
  },
  {
    productLine: 26,
    description: 'Charvel',
    grouping: 'Specialty',
  },
  {
    productLine: 85,
    description: 'Guitar Accessories',
    grouping: 'Fender',
  },
  {
    productLine: 20,
    description: 'Squier Electric',
    grouping: 'Fender',
  },
  {
    productLine: 86,
    description: 'Parts and Pickups',
    grouping: 'Fender',
  },
  {
    productLine: 10,
    description: 'US Fender Electric',
    grouping: 'Fender',
  },
  {
    productLine: 15,
    description: 'USA Custom Shop',
    grouping: 'Fender',
  },
  {
    productLine: 42,
    description: 'Bass Amps',
    grouping: 'Fender',
  },
  {
    productLine: 14,
    description: 'Mexico Fender Electric',
    grouping: 'Fender',
  },
];

export interface IProductLineOptions {
  productLine: number;
  description: string;
  grouping: string;
}

export interface IProductLineCheckbox extends IProductLineOptions {
  checked: boolean;
}

export enum ProductLineGrouping {
  FENDER = 'Fender',
  SPECIALITY = 'Specialty',
}
