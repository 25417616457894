import { Pipe, PipeTransform } from '@angular/core';
import { DomManagerService } from '../services/common/dom-manager.service';

@Pipe({
  name: 'htmlToText',
})
export class HtmlToTextPipe implements PipeTransform {
  constructor(private _domManagerService: DomManagerService) {}
  transform(value: string): string {
    return this._domManagerService.htmlToText(value);
  }
}
