import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MainHeaderComponent } from './main-header/main-header.component';
import { FenderToneLoadingDivComponent } from './fender-tone-loading-div/fender-tone-loading-div.component';
import { NotificationDialogComponent } from './notification-dialog/notification-dialog.component';
import { WidgetsManagerComponent } from './widgets-manager/widgets-manager.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { WidgetComponent } from './widget/widget.component';
import { ReactiveRoleSelectorComponent } from './forms/reactive-role-selector/reactive-role-selector.component';
import { RoleSelectorComponent } from './forms/role-selector/role-selector.component';
import { RolesFilterPipe } from '../pipes/roles-filter.pipe';
import { ReactiveFileInputComponent } from './forms/reactive-file-input/reactive-file-input.component';
import { ReactiveImageSelectorComponent } from './forms/reactive-image-selector/reactive-image-selector.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropModalComponent } from './forms/image-crop-modal/image-crop-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ReactiveInviteListComponent } from './forms/reactive-invite-list/reactive-invite-list.component';
import { InviteListSelectorModalComponent } from './forms/invite-list-selector-modal/invite-list-selector-modal.component';
import { SiteMessagesComponent } from './dashboard/site-messages/site-messages.component';
import { SiteMessageFilterPipe } from '../pipes/site-message-filter.pipe';
import { VideoPlayerComponent } from './video/video-player/video-player.component';
import { RouterModule } from '@angular/router';
import { VideoThumbnailComponent } from './video/video-thumbnail/video-thumbnail.component';
import { ContactUsComponent } from './contact-us/contact-us-main/contact-us.component';
import { ShipmentQuestionComponent } from './contact-us/shipment-question/shipment-question.component';
import { InvoicePaymentsContactUsComponent } from './contact-us/invoice-payments-contact-us/invoice-payments-contact-us.component';
import { NgxMaskModule } from 'ngx-mask';
import { DashboardModule } from '../dashboard/dashboard.module';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { SelectSupplierComponent } from './select-supplier/select-supplier.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ShowMoreComponent } from './show-more/show-more.component';
import { PixelStepperComponent } from './forms/pixel-stepper/pixel-stepper.component';
import { EventsBackgroundSelectorComponent } from './forms/events-background-selector/events-background-selector.component';
import { ColorSketchModule } from 'ngx-color/sketch';
import { ReactiveColorSelectorComponent } from './forms/reactive-color-selector/reactive-color-selector.component';
import { MainMenuSubMenuComponent } from './main-menu/main-menu-sub-menu/main-menu-sub-menu.component';
import { BitMovinVideoPlayerComponent } from './video/bitmovin-video-player/bitmovin-video-player.component';
import { ShowPlayerComponent } from './video/bitmovin-video-player/show-player.component';
import { ShowVideoComponent } from './video/bitmovin-video-player/show-video.component';
import { StaticVideoThumbnailComponent } from './video/bitmovin-video-player/static-video-thumbnail.component';
import { CircleProgressBarComponent } from './video/bitmovin-video-player/circle-progress-bar.component';
import { MobileMainManuComponent } from './mobile-main-manu/mobile-main-manu.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ThumbnailCarouselComponent } from './thumbnail-carousel/thumbnail-carousel.component';
import { ImageSlideshowComponent } from './image-slideshow/image-slideshow.component';
import { LookupSearchComponent } from './lookup-search//lookup-search.component';
import { ProductSpecsComponent } from './products-spec/product-specs/product-specs.component';
import { ToastAlertComponent } from './toast-alert/toast-alert.component';
import { ApplicationPipesModule } from '../pipes/application-pipes.module';
import { MultiselectDropdownComponent } from './multiselect-dropdown/multiselect-dropdown.component';
import { AcceptRejectSelectComponent } from './selects/accept-reject-select/accept-reject-select.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NewUiSelectComponent } from './forms/new-ui-select/new-ui-select.component';
import { PoAlertBannerComponent } from './purchase-orders/po-alert-banner/po-alert-banner.component';
import { PoAlertItemComponent } from './purchase-orders/po-alert-item/po-alert-item.component';
import { PoDropdownDateFilterComponent } from './purchase-orders/po-dropdown-date-filter/po-dropdown-date-filter.component';
import { PoDateRangeSectionComponent } from './purchase-orders/po-date-range-section/po-date-range-section.component';
import { PoStatusSelectTableComponent } from './selects/po-status-select-table/po-status-select-table.component';
import { NoteModalComponent } from './modals/purchase-orders/note-modal/note-modal.component';
import { DateChangeModalComponent } from './modals/purchase-orders/date-change-modal/date-change-modal.component';
import { OptionCustomPageComponent } from './main-menu/option-custom-page/option-custom-page.component';
import { OptionMainMenuComponent } from './main-menu/option-main-menu/option-main-menu.component';
import { TableActiveToggleComponent } from './table/table-active-toggle/table-active-toggle.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { MobileCustomPageOptionComponent } from './mobile-main-manu/mobile-custom-page-option/mobile-custom-page-option.component';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    MainHeaderComponent,
    FenderToneLoadingDivComponent,
    NotificationDialogComponent,
    WidgetsManagerComponent,
    MainMenuComponent,
    WidgetComponent,
    ReactiveRoleSelectorComponent,
    RoleSelectorComponent,
    RolesFilterPipe,
    ReactiveFileInputComponent,
    ReactiveImageSelectorComponent,
    ImageCropModalComponent,
    ReactiveInviteListComponent,
    InviteListSelectorModalComponent,
    SiteMessagesComponent,
    SiteMessageFilterPipe,
    VideoPlayerComponent,
    VideoThumbnailComponent,
    ContactUsComponent,
    ShipmentQuestionComponent,
    InvoicePaymentsContactUsComponent,
    SafeHtmlPipe,
    SelectSupplierComponent,
    ShowMoreComponent,
    PixelStepperComponent,
    EventsBackgroundSelectorComponent,
    ReactiveColorSelectorComponent,
    MainMenuSubMenuComponent,
    BitMovinVideoPlayerComponent,
    ShowPlayerComponent,
    ShowVideoComponent,
    StaticVideoThumbnailComponent,
    CircleProgressBarComponent,
    MobileMainManuComponent,
    ThumbnailCarouselComponent,
    ImageSlideshowComponent,
    LookupSearchComponent,
    ProductSpecsComponent,
    ToastAlertComponent,
    MultiselectDropdownComponent,
    AcceptRejectSelectComponent,
    NewUiSelectComponent,
    PoAlertBannerComponent,
    PoAlertItemComponent,
    PoDropdownDateFilterComponent,
    PoDateRangeSectionComponent,
    PoStatusSelectTableComponent,
    NoteModalComponent,
    DateChangeModalComponent,
    OptionCustomPageComponent,
    OptionMainMenuComponent,
    TableActiveToggleComponent,
    MobileCustomPageOptionComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ImageCropperModule,
    ApplicationPipesModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    NgxMaskModule.forRoot(),
    CollapseModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PopoverModule.forRoot(),
    ColorSketchModule,
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    MainHeaderComponent,
    FenderToneLoadingDivComponent,
    NotificationDialogComponent,
    MainMenuComponent,
    WidgetsManagerComponent,
    ReactiveRoleSelectorComponent,
    RoleSelectorComponent,
    RolesFilterPipe,
    ReactiveFileInputComponent,
    ReactiveImageSelectorComponent,
    ImageCropModalComponent,
    ReactiveInviteListComponent,
    InviteListSelectorModalComponent,
    SiteMessagesComponent,
    SiteMessageFilterPipe,
    VideoPlayerComponent,
    VideoThumbnailComponent,
    SafeHtmlPipe,
    SelectSupplierComponent,
    ShowMoreComponent,
    PixelStepperComponent,
    EventsBackgroundSelectorComponent,
    BitMovinVideoPlayerComponent,
    ShowPlayerComponent,
    ShowVideoComponent,
    StaticVideoThumbnailComponent,
    CircleProgressBarComponent,
    ImageSlideshowComponent,
    LookupSearchComponent,
    ProductSpecsComponent,
    ToastAlertComponent,
    MultiselectDropdownComponent,
    AcceptRejectSelectComponent,
    NewUiSelectComponent,
    PoAlertBannerComponent,
    PoAlertItemComponent,
    PoDropdownDateFilterComponent,
    PoDateRangeSectionComponent,
    PoStatusSelectTableComponent,
    NoteModalComponent,
    DateChangeModalComponent,
    TableActiveToggleComponent,
  ],
})
export class UiModule {}
