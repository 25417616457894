<nav class="navbar navbar-expand-lg header" [ngClass]="headerBackgroundColor">
  <div class="container-fluid header-parent">
    <!-- LOGO -->
    <div class="left-item">
      <div class="d-flex">
        <a class="text-decoration-none" [routerLink]="'dashboard'">
          <div class="d-flex align-items-center logo-container-main">
            <img
              class="logo-image"
              src="{{ imagePathLogo }}"
              alt="Fender Logo White"
            />
            <span class="text-white f-font f-bold fst-italic ms-2 logo-text"
              >Supplier</span
            >
          </div>
        </a>
      </div>
    </div>

    <!-- CONTACT FORM -->
    <app-contact-us></app-contact-us>

    <div class="right-item d-flex justify-content-end">
      <!-- <div class="d-flex align-items-center px-2">
        <div role="button" class="position-relative me-sm-3 me-xs-1">
          <span class="badge-position translate-middle badge rounded-pill bg-light text-dark">
              {{2}}
          </span>
          <i class="f-icon f-md f-finance-alerts f-white"></i>
        </div >
      </div> -->

      <!-- <form id="headersearch" name="headersearch" [formGroup]="headerSearch" (keyup.enter)="headerSearch && searchQuery()">

      <div class="dropdown me-sm-3 me-xs-1">
        <div role="button" class="navbar-toggler" type="button" data-bs-dismiss="collapse" data-bs-target="#navbarSearch" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleSearch()">
          <span class="navbar-toggler-icon search-span">
            <i class="f-icon f-md f-search f-white"></i>
          </span>
        </div>
        <div class="dropdown-menu p-3 dropdown-search" aria-labelledby="dropdownMenu" [ngClass]="mobileSearch ? 'show' : '' ">
          <div class="d-flex align-items-center">
            <div class="input-group me-3">
              <input class="form-control form-control-sm"
                placeholder="Search"
                formControlName="searchQuery"
              >
              <span class="input-group-text">
                  <i class="f-icon f-md f-search mb-2"></i>
              </span>
            </div>
            <div role="button" (click)="mobileSearch = false">
              <i class="f-icon f-x f-md mb-2"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="collapse navbar-collapse me-sm-3 me-xs-1" id="navbarSearch">
        <div class="input-group input-size">
          <input class="form-control form-control-sm"
            placeholder="Search"
            formControlName="searchQuery">
          <span class="input-group-text">
              <i class="f-icon f-md f-search mb-2"></i>
          </span>
        </div>
      </div>

    </form> -->
      <div data-cy="header-dropdown" class="btn-group" dropdown>
        <a dropdownToggle type="button" data-cy="header-dropdown--btn">
          <img
            src="assets/images/fender-guitar-head-icon.svg"
            class="guitar-head-image img-fuid"
          />
        </a>
        <ul
          *dropdownMenu
          class="dropdown-menu dropdown-menu-right"
          role="menu"
          data-cy="header-dropdown--items"
        >
          <li role="menuitem">
            <a
              class="dropdown-item py-3"
              [routerLink]="'account-maintenance'"
              data-cy="header-dropdown--item-acc"
            >
              <i class="f-icon f-md f-gear-wrench me-2"></i>
              Account Maintenance
            </a>
          </li>
          <li class="divider dropdown-divider"></li>
          <li role="menuitem">
            <a
              class="dropdown-item py-3"
              (click)="logout()"
              data-cy="header-dropdown--item-logout"
              ><i class="f-icon f-md f-exit"></i>
              Logout
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
