<div *ngIf="!onEdit" [formGroup]="formParent">
  <label
    for="'inputFile' + fieldName"
    class="form-label f-font"
    [ngClass]="{ required: required }"
    >{{ labelText }}</label
  >
  <div class="input-group has-validation mb-1">
    <input
      type="file"
      class="form-control f-font"
      [attr.id]="'inputFile' + fieldName"
      [formControlName]="controlName"
      (change)="onFileSelect($event)"
      [ngClass]="{
        'is-invalid':
          formParent.get(controlName)?.invalid &&
          formParent.get(controlName)?.touched
      }"
      data-cy="reactive-file_input"
    />
    <button
      class="btn btn-outline-secondary"
      type="button"
      id="button-addon2"
      (click)="onRemove()"
      *ngIf="canRemove && formParent.get(controlName)?.value"
      data-cy="reactive-file_on-remove"
    >
      <i class="fa-solid fa-trash-can"></i>
    </button>
    <div
      class="invalid-feedback f-font"
      *ngIf="!formParent.get(controlName)?.value"
    >
      Document is required.
    </div>
  </div>
  <div class="d-flex flex-column mb-2">
    <small class="f-font my-0" [ngClass]="{ 'text-danger': maxSizeError }">
      <i class="fa-solid fa-circle-exclamation" *ngIf="maxSizeError"></i>
      Maximum file upload size is {{ maxMBs }}MB
    </small>
    <small class="f-font my-0" [ngClass]="{ 'text-danger': formatError }">
      <i class="fa-solid fa-circle-exclamation" *ngIf="formatError"></i>
      Document type&nbsp;( {{ extensionsAllowed.join(", ") }} )&nbsp;only
    </small>
  </div>
</div>

<div *ngIf="onEdit" [formGroup]="formParent">
  <label for="fileChoosen" class="form-label f-font">Uploaded file</label>
  <div class="input-group mb-3">
    <input
      id="fileChoosen"
      type="text"
      [formControlName]="controlName"
      class="form-control f-font"
      placeholder="{{ selectedFileName }}"
      readonly
      data-cy="reactive-file_selected-file-input"
    />
    <button
      class="btn btn-outline-secondary f-font"
      type="button"
      id="button-addon1"
      (click)="onRemove()"
    >
      Change file
    </button>
  </div>
</div>
