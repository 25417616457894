import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ISupplier } from 'src/app/models/supplier';
import { SelectSupplierHelperService } from 'src/app/services/helper/select-supplier-helper.service';
import { SupplierService } from 'src/app/services/suppliers/supplier.service';

@Component({
  selector: 'app-select-supplier',
  templateUrl: './select-supplier.component.html',
})
export class SelectSupplierComponent implements OnInit {
  @Input() userId: boolean = true;
  @Input() visible: boolean = false;
  @Output() setSupplier: EventEmitter<string> = new EventEmitter();
  potentialSupplier: ISupplier | null = null;
  selectedSupplier: ISupplier | null = null;

  supplierNumberText: string = '';
  loadingSupplier: boolean = false;
  notFoundMessage: boolean = false;

  subscription: Subscription = new Subscription();

  constructor(
    private supplierService: SupplierService,
    private selectSupplierHelperService: SelectSupplierHelperService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async ngOnInit() {
    this.subscription.add(
      this.selectSupplierHelperService.getValue().subscribe((value) => {
        if (value?.length && value != this.potentialSupplier?.supplierNumber) {
          this.autoSetSupplier(value);
        }
      })
    );
  }

  autoSetSupplier(supplierNumber: string) {
    this.subscription.add(
      this.supplierService.getSupplier(supplierNumber).subscribe({
        next: (supplier: ISupplier) => {
          this.selectedSupplier = supplier;
        },
        error: (err) => {
          console.log(err);
          this.notFoundMessage = false;
          this.loadingSupplier = false;
          if (err === 'Users API Error. Details: undefined')
            this.router.navigate(['/dashboard']);
        },
      })
    );
  }

  getSupplier(supplierNumber: string) {
    this.loadingSupplier = true;
    this.notFoundMessage = false;
    this.subscription.add(
      this.supplierService.getSupplier(supplierNumber).subscribe({
        next: (supplier: ISupplier) => {
          this.loadingSupplier = false;
          this.potentialSupplier = supplier;
        },
        error: (error) => {
          this.notFoundMessage = true;
          this.loadingSupplier = false;
          this.potentialSupplier = null;
          this.notFoundMessageDissappear();
        },
      })
    );
  }

  notFoundMessageDissappear() {
    setTimeout(() => {
      this.notFoundMessage = false;
    }, 3000);
  }

  setSupplieNumber() {
    if (this.potentialSupplier) {
      this.selectedSupplier = this.potentialSupplier;
      this.selectSupplierHelperService.setValue(
        this.selectedSupplier.supplierNumber
      );
    }
  }

  resetSupplierNumber() {
    if (this.selectedSupplier?.supplierNumber) {
      this.selectedSupplier = null;
      this.selectSupplierHelperService.setValue('');
    }
    this.supplierNumberText = '';
    this.potentialSupplier = null;
  }
}
