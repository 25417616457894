import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PurchaseOrdersPageComponent } from './purchase-orders-page/purchase-orders-page.component';
import { PurchaseOrderDetailsComponent } from './purchase-order-details/purchase-order-details.component';
import { PurchaseOrdersAccess } from 'src/app/authentication/guards/purchase-orders-access.guard';

const routes: Routes = [
  {
    path: 'by-po',
    canActivate: [PurchaseOrdersAccess],
    component: PurchaseOrdersPageComponent,
  },
  {
    path: 'by-model',
    canActivate: [PurchaseOrdersAccess],
    component: PurchaseOrdersPageComponent,
  },
  {
    path: 'summary',
    canActivate: [PurchaseOrdersAccess],
    component: PurchaseOrdersPageComponent,
  },
  {
    path: ':poNumber',
    canActivate: [PurchaseOrdersAccess],
    component: PurchaseOrderDetailsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PurchaseOrdersRoutingModule {}
