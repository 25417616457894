import { Injectable, Inject, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class DomManagerService {
  constructor(private sanitizer: DomSanitizer) {}

  htmlToText(htmlValue: string): string {
    const tempHTML = document.createElement('div');
    const safeHtml = this.sanitizer.sanitize(SecurityContext.HTML, htmlValue);
    tempHTML.innerHTML = safeHtml ? safeHtml : '';
    return tempHTML.textContent || tempHTML.innerText || '';
  }
}
