import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-mobile-custom-page-option',
  templateUrl: './mobile-custom-page-option.component.html',
  styles: [],
})
export class MobileCustomPageOptionComponent implements OnInit {
  @Input() menuItem: any;
  @Output() navigatePage: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onMenuItemClick(menuItem: any) {
    this.navigatePage.emit(menuItem);
  }
}
