<div [class.inline-loading]="inline">
  <img
    src="../../../assets/images/fender-tone-loader.gif"
    alt="loading..."
    *ngIf="!black && !whiteF"
  />
  <img
    src="../../../assets/images/fender-tone-loader-black.gif"
    alt="loading..."
    *ngIf="black && !whiteF"
  />
  <img
    src="../../../assets/images/fender-tone-black-f-white.gif"
    alt="loading..."
    *ngIf="whiteF"
  />
</div>
