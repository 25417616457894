import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-invite-list-selector-modal',
  templateUrl: './invite-list-selector-modal.component.html',
})
export class InviteListSelectorModalComponent implements OnInit {
  @Input() isRequired: boolean = false;
  @Input() invitedSuppliers: string = '';
  @Input() isEditMode: boolean = false;
  @Output() saveSuppliersInvited = new EventEmitter();
  @ViewChild('inviteListModal', { static: true })
  inviteListModal!: TemplateRef<ElementRef>;

  modalRef!: BsModalRef;

  public localInvitedDealers: string[] = [];
  public isSavedList: boolean = false;

  constructor(private modalService: BsModalService) {}

  ngOnInit() {
    this.isSavedList = this.invitedSuppliers?.length > 0;
  }

  showDialog() {
    this.modalRef = this.modalService.show(this.inviteListModal, {
      backdrop: 'static',
      keyboard: false,
    });
  }

  save() {
    if (!this.isEditMode && this.invitedSuppliers?.length > 0) {
      this.localInvitedDealers = this.invitedSuppliers.split(/[\s\t\n,;]+/);
    } else if (this.isEditMode && this.invitedSuppliers?.length > 0) {
      this.localInvitedDealers = this.invitedSuppliers.toString().includes(',')
        ? this.invitedSuppliers.toString().split(',')
        : this.invitedSuppliers.toString().split(/[\s\t\n,;]+/);
    }
    if (this.localInvitedDealers && this.localInvitedDealers.length > 0) {
      this.saveSuppliersInvited.emit(this.localInvitedDealers);
      this.modalRef.hide();
      this.isSavedList = true;
    }
  }

  delete() {
    this.modalRef.hide();
    this.saveSuppliersInvited.emit([]);
    this.isSavedList = false;
  }

  clear() {
    this.saveSuppliersInvited.emit([]);
    this.invitedSuppliers = '';
  }
}
