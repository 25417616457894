<div class="image-crop-uploader">
  <label for="title" class="form-label f-font d-block"
    >Image<span *ngIf="required" class="required-asterisk">&nbsp;*</span></label
  >
  <div *ngIf="allowImageUrl">
    <select
      class="form-select mb-3"
      [(ngModel)]="imageSourceType"
      (change)="onSelectedSource($event)"
    >
      <option value="" disabled readonly>Select Image Source</option>
      <option value="file">From File</option>
      <option value="url">From URL</option>
    </select>
  </div>

  <div class="img-select-image-container" *ngIf="uploading">
    <p>Uploading image...</p>
  </div>

  <!-- Vertical -->
  <div
    class="img-select-image-container"
    *ngIf="imageToShow && !uploading && !horizontalLayout"
  >
    <div class="d-flex justify-content-center my-2">
      <img [src]="imageToShow" />
    </div>
  </div>
  <!-- / Vertical -->

  <div>
    <div class="form-group" *ngIf="isUrl">
      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="https://" />
        <button
          type="button"
          class="btn btn-outline-secondary"
          id="basic-addon2"
        >
          <i class="fa fa-link"></i> {{ "Add Crop" }}
        </button>
      </div>
    </div>
    <div
      class="form-group"
      [hidden]="allowImageUrl && !isFile"
      [ngClass]="{ 'd-flex align-items-center': horizontalLayout }"
    >
      <div class="col-6">
        <button
          class="f-btn f-xs f-blue mb-2"
          (click)="imageInput.click()"
          data-cy="img_selctor-select_btn"
        >
          Select Image
        </button>
        <input
          [id]="'fileInput'"
          #imageInput
          type="file"
          class="d-none"
          [disabled]="false"
          [required]="required"
          (change)="onImageChanged($event)"
        />
        <div class="d-flex flex-column">
          <small
            class="f-font"
            [ngClass]="{ 'invalid-font-color': invalidFormatError }"
            ><i
              class="fa-solid fa-triangle-exclamation"
              *ngIf="invalidFormatError"
            ></i>
            Image Format (.jpg, .png or .gif)</small
          >
          <small
            class="f-font"
            [ngClass]="{ 'invalid-font-color': maxSizeError }"
            ><i
              class="fa-solid fa-triangle-exclamation"
              *ngIf="maxSizeError"
            ></i
            >{{ "Maximum file upload size " + maxMBs + "MB" }}</small
          >
          <small class="f-font" *ngIf="resolutionText">{{
            resolutionText
          }}</small>
        </div>
      </div>
      <!-- Horizaontal -->
      <div
        class="img-select-image-container"
        *ngIf="imageToShow && !uploading && horizontalLayout"
      >
        <div class="d-flex justify-content-center my-2">
          <img [src]="imageToShow" />
        </div>
      </div>
      <!-- / Horizaontal -->
    </div>
  </div>
</div>

<app-image-crop-modal
  #imageCropModal
  [imageInput]="imageInput"
  *ngIf="withCropper"
  [imageUrl]="imageUrlSource"
  [forceCrop]="forceCrop"
  [aspectRatio]="aspectRatio"
  (change)="onCropChanged($event)"
>
</app-image-crop-modal>
