import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { IUser } from 'src/app/models/user';

@Injectable({ providedIn: 'root' })
export class IdentityService {
  currentUser$ = new BehaviorSubject(null);
  currentMeUser$ = new BehaviorSubject<IUser | null>(null);
  currentUserInfo$ = new BehaviorSubject(null);

  constructor() {}

  public async getIdToken() {
    const userValue = this.currentUser$.getValue();
    try {
      if (userValue) {
        return userValue['signInUserSession']['idToken']['jwtToken'];
      } else {
        return null;
      }
    } catch (error: any) {
      return null;
    }
  }

  public getCurrentMeUser() {
    return this.currentMeUser$.asObservable();
  }
  public getCurrentUserInfo() {
    return this.currentUserInfo$.asObservable();
  }
}
