export interface IAnnouncement {
  id?: number;
  title: string;
  description?: string;
  content: string;
  enable: boolean;
  roleIds: string[];
  supplierNumbers?: string[];
  isNew: boolean;
  url?: string;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
  typeId?: number;
  type: string;
  endsAt: string;
  startsAt?: string;
  imageName?: string;
}

export enum AnnouncementType {
  NEWS = 'News',
  EXTERNAL_LINKS = 'ExternalLinks',
  INTERNAL_LINKS = 'InternalLinks',
  VIDEOS = 'Videos',
  SITE_MESSAGES = 'SiteMessages',
}
