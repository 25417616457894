<div
  id="documentsCard"
  class="card"
  *ngIf="isOnDashboard"
  [ngClass]="{
    'h-auto': isCollapse,
    fullscreen: isFullscreen
  }"
>
  <!-- Card Header -->
  <div class="card-header small-header-dark">
    <!-- Title -->
    <div>
      <i class="fa-solid fa-file-lines me-2"></i>
      <span class="card-title f-font">
        {{ isWidget ? "Documents" : "Documents List" }}</span
      >
    </div>
    <!-- Widgets Constrols - if isWidget -->
    <!-- <div class="d-flex align-items-center" *ngIf="isWidget && showControls">
      <div
        id="widgetControlCollapse"
        type="button"
        class="widget-controls-btn"
        (click)="controlsCollapse()"
      >
        <i class="fa-solid fa-minus" *ngIf="!isCollapse"></i>
        <i class="fa-solid fa-plus" *ngIf="isCollapse"></i>
      </div>
      <div
        id="widgetControlFullscreen"
        type="button"
        class="widget-controls-btn fa-xs"
        (click)="onFullscreen()"
      >
        <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
      </div>
      <div
        id="widgetControlClose"
        type="button"
        class="widget-controls-btn"
        (click)="onClose()"
      >
        <i class="fa-solid fa-xmark"></i>
      </div>
    </div> -->
    <!-- Search Button - if documentPage -->
    <div *ngIf="!isWidget">
      <button
        class="f-btn f-xs search-btn"
        (click)="toggleSearchInput()"
        [ngClass]="{ 'search-selected': searchSelected }"
      >
        <i
          class="f-icon f-xs f-search"
          [ngClass]="{ 'f-white': searchSelected }"
        ></i>
        <span>Search</span>
      </button>
    </div>
    <!-- Exit Fullscreen - if widget Fullscreen -->
    <!-- <div *ngIf="isWidget && isFullscreen">
      <div
        id="widgetControlExitFullscreen"
        type="button"
        class="widget-controls-btn fa-xs"
        (click)="onExitFullScreen()"
      >
        <i class="fa-solid fa-down-left-and-up-right-to-center"></i>
      </div>
    </div> -->
  </div>
  <div
    class="doc-widget"
    id="documentsCardBody"
    [ngClass]="{
      'pb-5': isWidget,
      widget: isWidget,
      'on-collapse': isCollapse
    }"
  >
    <!-- *ngIf="!isCollapse" -->
    <div class="card-body">
      <div
        class="m-0"
        [collapse]="!searchSelected"
        [isAnimated]="true"
        *ngIf="!isWidget"
      >
        <div class="pb-3">
          <input
            type="search"
            class="form-control"
            id="searchUserTable"
            placeholder="Search"
            [(ngModel)]="filterString"
            (ngModelChange)="filterDocuments()"
          />
        </div>
      </div>

      <!-- Table  -->
      <table
        class="f-table table-responsive-xl f-font document-page-table"
        [ngClass]="{ 'table-responsive-xl': !isWidget }"
        *ngIf="documentsList.length && !loading"
      >
        <thead>
          <tr [ngClass]="{ 'd-none': isWidget }">
            <th>
              <span
                class="sort-table-heading"
                role="button"
                (click)="changeSort('title')"
                [ngClass]="{ 'f-bold': sort.column == 'title' }"
                data-cy="document-page_sort-btn_title"
              >
                Title
                <i
                  *ngIf="sort.column == 'title'"
                  class="fa-solid fa-xs"
                  [ngClass]="{
                    'fa-sort-up': sort.reverse,
                    'fa-sort-down': !sort.reverse
                  }"
                ></i>
              </span>
            </th>
            <th>
              <span
                class="sort-table-heading"
                role="button"
                (click)="changeSort('description')"
                [ngClass]="{ 'f-bold': sort.column == 'description' }"
                data-cy="document-page_sort-btn_description"
              >
                Description
                <i
                  *ngIf="sort.column == 'description'"
                  class="fa-solid fa-xs"
                  [ngClass]="{
                    'fa-sort-up': sort.reverse,
                    'fa-sort-down': !sort.reverse
                  }"
                ></i>
              </span>
            </th>
            <th>
              <span
                class="sort-table-heading"
                role="button"
                (click)="changeSort('createdAt')"
                [ngClass]="{ 'f-bold': sort.column == 'createdAt' }"
                data-cy="document-page_sort-createdAt"
              >
                Create Date
                <i
                  *ngIf="sort.column == 'createdAt'"
                  class="fa-solid fa-xs"
                  [ngClass]="{
                    'fa-sort-up': sort.reverse,
                    'fa-sort-down': !sort.reverse
                  }"
                ></i>
              </span>
            </th>
            <th></th>
          </tr>
        </thead>
        <!-- Table Body -->
        <tbody>
          <tr
            *ngFor="
              let document of documentsList | slice : 0 : limit;
              let i = index
            "
          >
            <td data-label="Title" class="text-start">
              <span class="text-start">{{ document.title }}</span>
            </td>
            <td data-label="Description" [ngClass]="{ 'd-none': isWidget }">
              <span class="text-start">
                {{ document.description }}
              </span>
            </td>
            <td data-label="Create At" [ngClass]="{ 'd-none': isWidget }">
              <span class="text-start">{{
                document.createdAt | date : userFormats.dateFormat
              }}</span>
            </td>
            <td
              data-label="Actions"
              class="text-xl-end"
              [ngClass]="{ 'text-end': isWidget }"
            >
              <a
                *ngIf="document.type === 'file'"
                id="downloadDocumentBtn"
                class="f-btn f-xs f-blue table-btn-width"
                (click)="download(document.filename, document.title)"
                data-cy="document-page_download-btn"
              >
                <i class="fa-solid fa-file-arrow-down me-0 me-md-3"></i>
                <span class="d-none d-md-inline">Download</span>
              </a>
              <a
                *ngIf="document.type === 'url'"
                id="downloadDocumentBtn"
                class="f-btn f-xs f-red table-btn-width"
                (click)="viewUrl(document.url, document.title)"
                data-cy="document-page_view-btn"
              >
                <span class="d-inline">View</span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="card-body h-75 d-flex justify-content-center align-items-center"
      *ngIf="loading"
    >
      <app-fender-tone-loading-div
        [widget]="isWidget"
        [black]="true"
      ></app-fender-tone-loading-div>
    </div>
    <div
      class="card-body h-75 d-flex justify-content-center align-items-center mb-3"
      *ngIf="!documentsList.length && !loading"
    >
      <span class="f-font"> No results found </span>
    </div>
    <!-- See More Button -->
    <a
      id="#seeMoreButton"
      [routerLink]="'/documents'"
      data-cy="document-widget_see-more-btn"
    >
      <div class="see-more-container" *ngIf="isWidget && !isCollapse">
        <span class="f-font">
          <i class="fa-solid fa-link"></i>
          See More
        </span>
      </div>
    </a>
  </div>
</div>
