<li class="h-100">
  <a
    name="menuItem.name"
    class="py-3"
    [ngClass]="{
      'has-events':
        menuItem.subItems &&
        menuItem.subItems.length &&
        menuItem.name === 'Events'
    }"
    (click)="onClickNavigatePage(menuItem)"
    [attr.data-cy]="'main-menu-btn_' + menuItem.route"
  >
    <i class=" {{ menuItem.icon }} "></i>
    <span class="f-font">{{ menuItem.name }}</span>
    <i
      class="fa-solid fa-sort-down subitems-down-icon"
      *ngIf="menuItem.subItems && menuItem.subItems.length > 1"
    ></i>
  </a>
  <!-- Sub Items -->
  <div
    class="sub-menu-items"
    *ngIf="menuItem.subItems && menuItem.subItems.length > 1"
  >
    <ul>
      <li *ngFor="let subItem of menuItem.subItems">
        <ng-container *ngIf="subItem.visible">
          <a
            type="button"
            (click)="onClickSelectSubItem(menuItem, subItem)"
            [attr.data-cy]="'main-menu_subitem-btn_' + subItem.route"
          >
            <i *ngIf="subItem.icon" class=" {{ subItem.icon }} me-2 pt-1"></i>
            <span class="f-font">{{ subItem.title }}</span>
          </a>
        </ng-container>
      </li>
    </ul>
  </div>
</li>
