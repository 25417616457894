<!-- IMAE CONTINER -->
<ng-container *ngIf="!noImages">
  <div class="image-slideshow">
    <!-- Only Lookup -->

    <ng-container *ngIf="lookupArrangement">
      <div class="row mx-1">
        <div class="col-12 col-md-6">
          <div class="f-font mb-4">
            <h3 class="f-uppercase f-bold mb-3">
              {{ mainMaterialInfo.productDisplayName }}
            </h3>
            <p class="m-0">SAP NAME: {{ mainMaterialInfo.sapName }}</p>
            <p class="m-0">SKU: {{ mainMaterialInfo.skuNumber }}</p>
            <p class="m-0">UPC: {{ mainMaterialInfo.upcNumber }}</p>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <app-thumbnail-carousel
            [thumbnails]="images.mainThumbnailAll"
            [imageSelected]="imageObject.imagePosition"
            (selectImage)="selectImage($event)"
          ></app-thumbnail-carousel>
        </div>
      </div>
      <div
        class="image-container my-3 my-lg-0"
        [ngClass]="{ 'lookup-arrangement-slideshow': lookupArrangement }"
      >
        <a>
          <img
            src="{{ imageUrl + imageObject.mainXLgAll }}"
            alt="image"
            class="main-image cursor-pointer"
            [ngClass]="{
              'lookup-arrangement-slideshow': lookupArrangement
            }"
            [hidden]="imageLoading"
            (click)="showZoomImage(zoomImagesModal)"
            (load)="imageOnLoad()"
          />
        </a>
        <ng-container *ngIf="imageLoading">
          <div class="d-flex flex-column">
            <app-fender-tone-loading-div
              [widget]="true"
              [black]="true"
            ></app-fender-tone-loading-div>
            <span class="f-font mt-3">Loading image</span>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="!lookupArrangement">
      <app-thumbnail-carousel
        [thumbnails]="images.mainThumbnailAll"
        [imageSelected]="imageObject.imagePosition"
        (selectImage)="selectImage($event)"
      ></app-thumbnail-carousel>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="noImages">
  <div
    class="d-flex flex-column justify-content-center align-items-center py-5"
  >
    <i class="fa-solid fa-images fa-2xl fa-dark-grey"></i>
    <small class="f-font mt-3">No images available</small>
  </div>
</ng-container>

<!-- zoom modal -->
<ng-template #zoomImagesModal>
  <div class="modal-body">
    <!-- navigation -->
    <div class="lightbox-nav">
      <div class="d-flex justify-content-between mb-3">
        <div class="invisible"></div>
        <!-- controls -->
        <div class="btn-group" role="group" aria-label="Basic example">
          <button
            type="button"
            class="btn btn-xs btn-outline-secondary"
            (click)="previousZoomImage()"
          >
            ‹ Previous
          </button>
          <button
            type="button"
            class="btn btn-xs btn-outline-secondary"
            (click)="openImageInNewTab(imageObject.mainZoomAll)"
          >
            Open image in new tab
          </button>
          <button
            type="button"
            class="btn btn-xs btn-outline-secondary"
            (click)="nextZoomImage()"
          >
            Next ›
          </button>
        </div>
        <!-- close button -->
        <div class="modal-close-btn" (click)="modalRef?.hide()">
          <i class="fa-solid fa-xmark fa-lg"></i>
        </div>
      </div>
    </div>
    <!-- iamge container -->
    <div class="lightbox-image-container">
      <img src="{{ imageUrl + imageObject.mainZoomAll }}" class="img-fluid" />
    </div>
  </div>
</ng-template>
