import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameInUrl',
})
export class NameInUrlPipe implements PipeTransform {
  transform(url: string, last: boolean = true, position?: number) {
    const urlArray = url.split('/');
    let name: string | undefined = '';
    if (last) {
      name = urlArray.pop();
    } else {
    }
    return name || '';
  }
}
