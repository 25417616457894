export interface IOSEEvents {
  id?: number;
  title?: string;
  startDate?: string;
  startTime?: string;
  endDate?: string;
  endTime?: string;
  createdDate?: string;
  createdBy?: string;
  modifiedBy?: string;
  createdModifyDate?: string;
  createdModifyBy?: string;
  navigationIcon?: string;
  roleIds?: number[];
  rolesName?: string[];
  activityReport?: boolean;
  enable?: boolean;
  supplierNumbers?: string[];
  pageIds?: string[];

  rolesNamesFMIC?: string[];
  rolesNamesSupplier?: string[];

  startsAt: string;
  endsAt: string;

  isCollapsed?: boolean;

  createdAt: Date;
  updatedAt: Date;
  updatedBy: string;
  createdById: string;
  updatedById: string;

  videos?: number;

  pages: IOSEPage[];
}

export interface IEventImage {
  id?: string;
  url?: string;
  name?: string;
  imageUrl?: string;
  imageName?: string;
  deleted?: boolean;
}

export interface IOSEPage {
  id: number;
  title: string;
  pageType: string;
  createdAt: Date;
  updatedAt: Date;
  updatedBy: Date;
  createdById: Date;
  sections: IOSESection[];
  isCollapsed?: boolean;
  eventTitle?: string;
}

export interface IOSESection {
  id: number;
  description: string;
  sectionType: string;
  backgroundType: string;
  background: string;
  backgroundPath?: string;
  paddingSections: number;
  filename: string;
  filenamePath: string;
  videoTitle: string;
  videoThumbnail: string;
  videoThumbnailPath: string;
  subtitles: IOSESubtitle[];
  isCollapsed?: boolean;
  progressPercentage?: number;
  startTime?: number;
}

export interface IOSESubtitle {
  id?: string;
  url?: string;
  label?: string;
  langCode?: string;
  createdAt?: Date;
  updatedAt?: Date;
  isCollapsed?: boolean;
}

export enum EventType {
  OSE = 'ose',
  CUSTOM_PAGES = 'custom-pages',
}
