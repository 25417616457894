import { Injectable } from '@angular/core';
import { Cache } from 'aws-amplify';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  constructor() {}

  set(key: string, value: any, expiration?: number) {
    Cache.setItem(key, value, { expires: this.setExpiration(expiration) });
  }

  get(key: string): any {
    return Cache.getItem(key);
  }

  delete(key: string): any {
    Cache.removeItem(key);
  }

  clear(): any {
    Cache.clear();
  }

  private setExpiration(minutes: number = 60) {
    const currentTime = new Date();
    return currentTime.setMinutes(currentTime.getMinutes() + minutes);
  }
}
