import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IMainMenu } from 'src/app/models/main-menu';
import { GoogleAnalyticsService } from 'src/app/services/common/google-analytics.service';

@Component({
  selector: 'app-option-custom-page',
  templateUrl: './option-custom-page.component.html',
  styles: [],
})
export class OptionCustomPageComponent {
  @Input() menuItem!: IMainMenu;
  @Output() navigatePage: EventEmitter<any> = new EventEmitter();

  constructor(private googleAnalyticsService: GoogleAnalyticsService) {}

  onClickNavigatePage(menuItem: IMainMenu) {
    this.trackGA4(menuItem);
    this.navigatePage.emit(menuItem);
  }

  trackGA4(menuItem: any) {
    this.googleAnalyticsService.trackEvent(
      'custom_pages',
      'View Page',
      `${menuItem.name}`
    );
  }
}
