<div class="container mt-5 padding-bottom-auth">
  <div class="d-flex justify-content-center align-items-center">
    <div class="card width-auth align-center">
      <form
        id="forgotpasswordform"
        name="forgotpasswordform"
        [formGroup]="forgotPasswordForm"
      >
        <div class="card-header py-3 f-font">
          <h3 class="f-font">{{ "Forgot Password?" }}</h3>
        </div>
        <div class="card-body">
          <div>
            <p class="lh-sm f-font">
              {{
                "Don’t worry, it happens all the time!! Let us know the email account you use to sign-in and we will send you an email with instructions."
              }}
            </p>
            <hr />
          </div>
          <label for="emailInput" class="f-font">{{
            "Enter your email address"
          }}</label>
          <div class="input-group has-validation mb-3">
            <input
              id="emailInput"
              name="email"
              type="email"
              autofocus
              class="form-control"
              formControlName="email"
            />
            <span class="input-group-text">
              <i class="fa-solid fa-envelope"></i>
            </span>
            <div class="invalid-feedback">
              {{ "Please provide a valid email." }}
            </div>
          </div>
          <div>
            <a href="login" class="f-font">Return to Login Page</a>
          </div>
          <div
            class="alert alert-success lh-sm my-3"
            role="alert"
            *ngIf="emailSent"
          >
            <span class="f-font" id="sucessMessage">
              Thank you, if your email account is registered with us you will
              shortly receive an email with the verification code.
            </span>
          </div>
          <div
            class="alert alert-danger lh-sm my-3"
            role="alert"
            *ngIf="isError"
          >
            <span class="f-font" id="errorMessage">
              Error sending confirmation email please contact your portal
              administrator.
            </span>
          </div>
        </div>

        <div class="card-footer d-flex justify-content-end py-3">
          <button
            id="submitEmailButton"
            class="f-btn f-xs f-blue"
            [disabled]="!forgotPasswordForm.valid"
            (click)="sendEmail()"
            (keyup.enter)="sendEmail()"
          >
            <span> <i class="f-icon f-revert f-white"></i>Send </span>
            <span *ngIf="loading">
              <app-fender-tone-loading-div
                [inline]="true"
              ></app-fender-tone-loading-div>
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
