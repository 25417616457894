import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, timeout, BehaviorSubject } from 'rxjs';
import { HttpUrlEncoding } from 'src/app/utils/http-url-encoding';
import { ILookupMaterials, ILookupRecentSearches } from '../../models/lookup';
import { HttpHelperService } from '../helper/http-helper.service';
import { environment } from 'src/environments/environment';
import { materialCategories } from './lookup-categories';

@Injectable({
  providedIn: 'root',
})
export class LookupService {
  private material = new BehaviorSubject<ILookupMaterials | undefined>(
    undefined
  );
  public sharedMaterial = this.material.asObservable();
  constructor(private httpHelperService: HttpHelperService) {}

  setSharedMaterial(data: ILookupMaterials | undefined) {
    this.material.next(data);
  }

  getMaterial(
    material: string,
    supplierNumber?: any
  ): Observable<ILookupMaterials> {
    return this.httpHelperService
      .get(
        `/lookup${
          supplierNumber ? '/suppliers/' + supplierNumber : ''
        }/materials/${material}`
      )
      .pipe(
        catchError((err) => {
          throw new Error('Lookup API. Details: ' + err.error.message);
        })
      );
  }

  getRecentSearches(): Observable<ILookupRecentSearches[]> {
    return this.httpHelperService.get('/lookup/users/me/recent-searches').pipe(
      catchError((err) => {
        throw new Error('Lookup API. Details: ' + err.error.message);
      })
    );
  }

  downloadMaterial(material: string): Observable<any> {
    let queryParams: HttpParams = new HttpParams({
      encoder: new HttpUrlEncoding(),
    });

    queryParams = queryParams.append('material', material);
    return this.httpHelperService
      .get(`/jobs/downloads/material-specs`, queryParams)
      .pipe(
        timeout(environment.api.timeout),
        catchError((err) => {
          throw new Error('Lookup API. Details: ' + err.error.message);
        })
      );
  }

  getCategories() {
    return materialCategories;
  }
}
