export enum DateRangeFilterTitles {
  PO_CREATE_DATE = 'PO Create Date',
  PRODUCTION_MONTH = 'Production Month',
  ORIGINAL_SHIP_DATE = 'Original Ship Date',
  CURRENT_SHIP_DATE = 'Current Ship Date',
}

export interface IPoDateRangeFormGroupFilter {
  to: Date | string | undefined;
  from: Date | string | undefined;
}

export const dateRangeFormGroupNames = [
  'poCreateDate',
  'productionMonth',
  'originalShipDate',
  'currentShipDate',
];
