import { GoogleAnalyticsService } from './../../../services/common/google-analytics.service';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Subscription, take } from 'rxjs';
import { IUserFormats } from 'src/app/models/user';
import { AnnouncementsService } from 'src/app/services/announcements/announcements.service';
import { StorageManagerService } from 'src/app/services/managers/storage-manager.service';
import { IAnnouncement } from '../../../models/announcement';
import { Location } from '@angular/common';
import {
  BsModalService,
  BsModalRef,
  ModalDirective,
} from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { ToastAlertComponent } from 'src/app/ui/toast-alert/toast-alert.component';

@Component({
  selector: 'app-my-announcements',
  templateUrl: './my-announcements.component.html',
})
export class MyAnnouncementsComponent implements OnInit {
  // Toast Alert
  @ViewChild('announcementErrorToast') announcementError!: ToastAlertComponent;
  @Input() isWidget: boolean = true;

  @Input() announcementLoader: boolean = true;
  @Input() widgetAnnouncements: IAnnouncement[] = [];

  @Output() callGetAnnouncements: EventEmitter<any> = new EventEmitter();

  // @Input() userFormats!: IUserFormats;
  // loading: boolean = false;
  showError: boolean = false;
  // searchDocuments: FormGroup;
  searchSelected: boolean = false;
  isCollapse: boolean = false;
  showControls: boolean = true;
  showSeeMoreBtn: boolean = true;
  isFullscreen: boolean = false;
  isOnDashboard: boolean = true;
  limit: number = 25;
  subscription: Subscription = new Subscription();
  filterString: string = '';
  sort!: { column: string; reverse: boolean };
  noDataListError: boolean = false;
  modalRef?: BsModalRef;
  newsContent: string = '';
  newsTitle: string = '';
  videoTitle: string = '';
  videoUrl: string = '';

  @ViewChild('video', { static: false }) videoModal?: ModalDirective;
  @ViewChild('news', { static: false }) newsModal?: ModalDirective;

  constructor(
    private announcementsService: AnnouncementsService,
    private storageManagerService: StorageManagerService,
    private router: Router,
    private modalService: BsModalService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit() {
    this.getAnnouncements();
  }

  onViewAnnouncement(announcementID: any) {
    this.announcementsService.getActiveAnnouncement(announcementID).subscribe({
      next: (data) => {
        if (data) {
          this.displayAnnouncement(data);
        }
      },
      error: (err) => {
        console.error(err);
        this.announcementError?.show();
      },
    });
  }

  displayAnnouncement(announcement: any) {
    this.googleAnalyticsService.trackEvent(
      'whats_new',
      'View',
      announcement.title
    );

    // News
    if (announcement.type === 'News') {
      this.newsTitle = announcement.title;
      this.newsModal?.show();
      this.newsContent = announcement.content;
    }
    // Video
    if (announcement.type === 'Videos') {
      this.videoTitle = announcement.title;
      this.videoUrl = announcement.url;
      this.videoModal?.show();
      this.onOutsideModalClick(announcement.title);
    }

    // ExternalLinks
    if (announcement.type === 'ExternalLinks') {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', announcement.url);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    // InternalLinks
    if (announcement.type === 'InternalLinks') {
      const location =
        this.router['location']._platformLocation.location.origin;
      const route = announcement.url.replace(location, '');
      this.router.navigateByUrl(route);
    }

    this.checkIsNew(announcement);
  }

  checkIsNew(announcement: IAnnouncement) {
    if (!announcement) {
      return null;
    }
    if (announcement.isNew) {
      this.subscription.add(
        this.announcementsService.seenAnnouncement(announcement.id).subscribe({
          next: () => {},
          complete: () => {
            this.getAnnouncements();
          },
          error: (err) => {
            console.error(err);
          },
        })
      );
    }
  }

  controlsCollapse() {
    this.isCollapse = !this.isCollapse;
  }

  onFullscreen() {
    this.isCollapse = false;
    this.showSeeMoreBtn = false;
    this.showControls = false;
    this.isFullscreen = true;
  }
  onExitFullScreen() {
    this.showSeeMoreBtn = true;
    this.showControls = true;
    this.isFullscreen = false;
  }

  onClose() {
    this.isOnDashboard = false;
  }

  onModalHide(newsTitle: string) {
    this.googleAnalyticsService.trackEvent(
      'whats_new',
      'Acknowledge',
      newsTitle
    );
    this.videoTitle = '';
    this.videoUrl = '';
  }

  onOutsideModalClick(newsTitle: string) {
    this.videoModal?.onHide.pipe(take(1)).subscribe(() => {
      this.googleAnalyticsService.trackEvent(
        'whats_new',
        'Acknowledge',
        newsTitle
      );
      this.videoTitle = '';
      this.videoUrl = '';
    });
  }
  getAnnouncements() {
    this.callGetAnnouncements.emit();
  }
}
