<ng-container *ngIf="hasValues">
  <div class="card mb-3" [class.lookup-card--gray]="background">
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <img class="me-2" alt="icon specs" src="{{ imagePath }}" /><span
          class="f-bold"
          >{{ title }}</span
        >
      </li>
      <li class="list-group-item">
        <div class="row gy-2">
          <ng-container *ngFor="let spec of specsObject">
            <ng-container *ngIf="spec.value">
              <ng-container
                [ngTemplateOutlet]="
                  spec.label != 'Disclaimer' ? normalLabel : disclaimerLabel
                "
                [ngTemplateOutletContext]="{ spec: spec }"
              >
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </li>
    </ul>
  </div>
</ng-container>

<!-- Normal Label -->
<ng-template #normalLabel let-spec="spec">
  <div class="col-4">
    <label>{{ spec.label }}&nbsp;</label>
  </div>
  <div class="col-8">
    <span>{{ spec.value }}</span>
  </div>
</ng-template>

<!-- Disclaimer Label -->
<ng-template #disclaimerLabel let-spec="spec">
  <div class="col-4">
    <label>{{ spec.label }}&nbsp;</label>
  </div>
  <div class="col-8">
    <div [innerHTML]="spec.value"></div>
  </div>
</ng-template>
