import { Injectable } from '@angular/core';
import { Environment } from 'src/app/fixtures/environments/environment-titles';
import { CurrencyCode } from 'src/app/models/purchase-orders';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  public cloudfrontPattern =
    /(https:\/\/)(([\da-z\.-]+)\.)?((cloudfront)).([\da-z\.-]+)/;
  public urlPattern = /(https:\/\/).+/;
  public internalLink = /.{3,}/;

  constructor() {}

  /** Powerful sorting function, returns a sorted array based on the value of the property specified in the propertyPath parameter.
   * Supports sorting for string, numeric, boolean and null values.
   * @param arrayToSort array to be sorted
   * @param propertyPath property which will be used for comparison, this could be a nested property which in
   * that case is specified with dot notation: 'foo.bar.property'
   * @param reverse sort the array in reverse order
   * */
  nestedSort(arrayToSort: any[], propertyPath: string, reverse = false) {
    if (!arrayToSort) {
      return arrayToSort;
    }
    arrayToSort.sort((objA, objB) => {
      let a = this.getObjectPropertyFromString(objA, propertyPath);
      let b = this.getObjectPropertyFromString(objB, propertyPath);
      if (a === b) {
        return 0;
      }
      // Sort null values
      if (a === null) {
        return -1;
      } else if (b === null) {
        return 1;
      }
      // Sort boolean values
      if (typeof a === 'boolean') {
        return a ? 1 : -1;
      }
      // Sort string values
      if (typeof a === 'string') {
        return a.localeCompare(b);
      }
      // Sort numeric and other values
      return a > b ? 1 : a < b ? -1 : 0;
    });
    if (reverse) {
      return arrayToSort.reverse();
    }
    return arrayToSort;
  }

  emptyToEnd(array: any[], propertyPath: string) {
    const present = array.filter((item) => {
      const value = this.getObjectPropertyFromString(item, propertyPath);
      return typeof value !== 'undefined' && value !== null && value !== 0;
    });
    const empty = array.filter((item) => {
      const value = this.getObjectPropertyFromString(item, propertyPath);
      return !(typeof value !== 'undefined' && value !== null && value !== 0);
    });
    return present.concat(empty);
  }

  /** Returns the value requested property specified in the stringPath parameter, ex.
   * objectPropertyFromString({ foo: { bar: 'asdf' } }, 'foo.bar') --> 'asdf' */
  private getObjectPropertyFromString(object: any, stringPath: string) {
    const properties: any = stringPath.split('.');
    const propertyDepth: any = properties.length;
    const getPropertyFrom: any = {
      1: () => object[stringPath],
      2: () => object[properties[0]][properties[1]],
      3: () => object[properties[0]][properties[1]][properties[2]],
    };
    return getPropertyFrom[propertyDepth]();
  }

  guidGenerator() {
    return (
      this.S4() +
      this.S4() +
      '-' +
      this.S4() +
      '-' +
      this.S4() +
      '-' +
      this.S4() +
      '-' +
      this.S4() +
      this.S4() +
      this.S4()
    );
  }

  S4() {
    // tslint:disable-next-line: no-bitwise
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  getCleanFileName(fileName: string, type: string|undefined) {
    if (!fileName || !type) {
      return null;
    }
    return (
      fileName.replace(/[^a-zA-Z\d_-]/g, '-') +
      this.guidGenerator() +
      `.${type}`
    );
  }
  createDownloadLink(url: any) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', url);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  isBrowserValid = () => {
    const userAgent = navigator.userAgent;

    const chrome = /.*(Chrome\/).*(Safari\/).*/g;
    const chromeMobile = /.*(CriOS\/).*(Safari\/).*/g;
    const firefox = /.*(Firefox\/).*/g;
    const firefoxMobile = /.*(FxiOS\/).*/g;
    const safari = /.*(Version\/).*(Safari\/).*/g;
    const safariIPadMobile = /.*(Mozilla\/).*(iPad).*(Mobile\/).*/g;
    const opera = /.*(Chrome\/).*(Safari\/).*(OPR\/).*/g;
    const operaMobile = /.*(Mozilla\/).*(iPhone).*(Mobile\/).*/g;
    const edge = /.*(Chrome\/).*(Safari\/).*(Edge\/).*/g;
    const ie = /.*(Trident\/7).*(rv:).*/g;

    if (opera.exec(userAgent) || operaMobile.exec(userAgent)) {
      return true;
    }
    if (edge.exec(userAgent)) {
      return true;
    }
    if (chrome.exec(userAgent) || chromeMobile.exec(userAgent)) {
      return true;
    }
    if (safari.exec(userAgent) || safariIPadMobile.exec(userAgent)) {
      return true;
    }
    if (firefox.exec(userAgent) || firefoxMobile.exec(userAgent)) {
      return true;
    }
    if (ie.exec(userAgent)) {
      return true;
    }
    return false;
  };

  browserDetection() {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: tem[1] || '' };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR|Edge\/(\d+)/);
      if (tem != null) {
        return { name: 'Opera', version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1],
    };
  }

  addLeadingZeros(number: string, length: number): string {
    return number.padStart(length, '0');
  }

  // PURCHASE ORDERS - HELPER
  formatPoDateString(dateString: string | undefined) {
    if (dateString && dateString.length === 8) {
      dateString = [
        dateString.slice(0, 4),
        dateString.slice(4, 6),
        dateString.slice(6, 8),
      ].join('-');
      return dateString;
    }
    return undefined;
  }

  // PURCHASE ORDERS - CURRENCY REGEX HELPER
  setCurrencyRegex(currency: string) {
    switch (currency) {
      case CurrencyCode.USD:
        return '^[$]?[0-9.,]*$';
      case CurrencyCode.EUR:
        return '^[€]?[0-9.,]*$';
      case CurrencyCode.JPY:
        return '^[¥]?[0-9,]*$';
      case CurrencyCode.RMB:
        return '^(CN¥)?[0-9.,]*$';
      case CurrencyCode.CNY:
        return '^(CN¥)?[0-9.,]*$';
      default:
        return '^[0-9]*$';
    }
  }

  downloadFromUrl(fileUrl: string) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', fileUrl);
    link.setAttribute('download', fileUrl);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  getEnviromentColor(environment: string) {
    switch (environment) {
      case Environment.local:
        return 'blue';
      case Environment.DEV:
        return 'blue';
      case Environment.QA:
        return 'brown';
      case Environment.STAGING:
        return 'green';
      case Environment.PROD:
        return 'red';
      default:
        return 'red';
    }
  }
}
