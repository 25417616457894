import { Pipe, PipeTransform } from '@angular/core';
import { IAnnouncement } from '../models/announcement';

@Pipe({
  name: 'siteMessageFilter',
})
export class SiteMessageFilterPipe implements PipeTransform {
  transform(announcements: IAnnouncement[], type: 'SiteMessages' | 'Widget') {
    if (!announcements) {
      return null;
    }
    if (type === 'SiteMessages') {
      return announcements.filter(
        (announcement: IAnnouncement) => announcement.type === 'SiteMessages'
      );
    }
    if (type === 'Widget') {
      return announcements.filter(
        (announcement: IAnnouncement) => announcement.type !== 'SiteMessages'
      );
    }
  }
}
