<article [attr.data-dragid]="widget.id" class="widget col-xs-12 col-lg-6" [ngClass]="{ 'widget-fullscreen' : widget.fullscreen }" draggable="true">
  <header>
      <h2>
          <label class="widget-info">
              <span class="widget-icon">
                  <i class="fa fa-{{widget.icon}}"></i>
              </span>
              <span>&nbsp;</span>
              <a href="widget.link" [textContent]="widget.title" *ngIf="widget.link"></a>
              <label class="widget-title" [textContent]="widget.title" *ngIf="!widget.link"></label>
          </label>
          <span class="widget-controls">
              <a href="javascript:void(0);" (click)="toggleCollapse()" [attr.title]="widget.collapseLegend" [hidden]="widget.fullscreen">
                  <i class="fa" [ngClass]="{ 'fa-minus' : widget.collapseLegend == 'Collapse', 'fa-plus' : widget.collapseLegend == 'Expand' }"></i>
              </a>
              <a href="javascript:void(0);" (click)="toggleFullscreen()" [attr.title]="widget.fullscreenLegend">
                  <i class="fa" [ngClass]="{ 'fa-expand' : widget.fullscreenLegend == 'Fullscreen', 'fa-compress' : widget.fullscreenLegend != 'Fullscreen' }"></i>
              </a>
              <a href="javascript:void(0);" (click)="hideWidget()" title="Close" [hidden]="widget.fullscreen"><i class="fa fa-times"></i></a>
          </span>
      </h2>
  </header>
  <div [hidden]="widget.collapsed && !widget.fullscreen">
      <ng-content></ng-content>
  </div>
  <footer class="see-more-footer" *ngIf="widget.canSeeMore">
      <a data-cy="widget-see-more-link" href="widget.link"><i class="fa fa-link" aria-hidden="true"></i><span [textContent]="'See More' "></span></a>
  </footer>
</article>

