import { Component, Input, OnInit } from '@angular/core';
import * as bootstrap from 'bootstrap';
import { HelperService } from '../../services/helper/helper.service';

@Component({
  selector: 'app-toast-alert',
  templateUrl: './toast-alert.component.html',
  styles: [],
})
export class ToastAlertComponent implements OnInit {
  @Input() selector: string = 'toastooo';
  @Input() title = 'Alert';
  @Input() description = '';
  @Input() style: 'bg-success' | 'bg-warning' | 'bg-danger' | 'bg-primary' =
    'bg-success';
  toast: any;
  toastElement: any;

  constructor(private helperService: HelperService) {}

  ngOnInit(): void {
    const uiid = this.helperService.guidGenerator();
    this.setSelector(uiid);
  }

  setSelector(uiid: string) {
    this.toastElement = document.getElementById('toastElement');
    this.toastElement?.setAttribute('id', `${this.selector}-${uiid}`);
    this.initializeToast();
  }

  initializeToast() {
    this.toast = new bootstrap.Toast(this.toastElement);
  }

  show() {
    this.toast.show();
  }
}
