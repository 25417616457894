<div *ngFor="let siteMessage of siteMessages">
  <div *ngIf="siteMessage.isNew">
    <div class="w-100 d-flex justify-content-center">
      <div
        class="site-message alert alert-warning alert-outline d-flex justify-content-between py-2"
        role="alert"
      >
        <div class="d-flex">
          <div>
            <i class="fa-solid fa-triangle-exclamation me-2"></i>
          </div>
          <div>
            <!-- <span class="f-font f-bold">{{ siteMessage.title }}</span>
          <hr class="my-1" /> -->
            <div
              class="f-font lh-0"
              [innerHTML]="siteMessage.content | safeHtml"
            ></div>
          </div>
        </div>
        <div class="px-2" role="button" (click)="onClose(siteMessage)">
          <i class="fa-solid fa-xmark"></i>
        </div>
      </div>
    </div>
  </div>
</div>
