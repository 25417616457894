import { Component, Input, OnInit } from '@angular/core';
import { IMaterialSpec } from '../../../models/lookup';

@Component({
  selector: 'app-product-specs',
  templateUrl: './product-specs.component.html',
  styles: [],
})
export class ProductSpecsComponent implements OnInit {
  @Input() title!: string;
  @Input() icon!: string;
  @Input() categorySpecs!: string[];
  @Input() productSpecs?: any;
  @Input() background?: boolean = false;

  specsObject: IMaterialSpec[] | undefined = undefined;
  imagePath: string = '';
  hasValues: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.imagePath = 'assets/images/lookup-icon/' + this.icon;
    this.setSpecsObject();
  }

  setSpecsObject() {
    this.specsObject = this.categorySpecs.map((spec) => {
      return { label: spec, camelCase: this.camelize(spec) };
    });
    this.matchValue();
  }

  matchValue() {
    Object.entries(this.productSpecs).forEach(([key, val]) => {
      this.specsObject?.forEach((spec: IMaterialSpec) => {
        if (spec.camelCase === key) {
          spec.value = val as string;
          this.hasValues = true;
        }
      });
    });
  }

  camelize(str: string) {
    return str
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  }
}
