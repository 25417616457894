import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { INewUISelect } from 'src/app/models/ui-components';

@Component({
  selector: 'app-new-ui-select',
  templateUrl: './new-ui-select.component.html',
})
export class NewUiSelectComponent implements OnInit {
  @Input() public options: INewUISelect[] = [];
  @Input() public preselectedOption?: string;
  @Input() public isSmall?: boolean = false;

  @Output() onSelection = new EventEmitter();

  public selectedOption: any | undefined = undefined;
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['options'] &&
      changes['options'].previousValue != changes['options'].currentValue
    ) {
      this.onPreselected();
    }
  }

  ngOnInit(): void {}

  onSelect(item: any): void {
    if (item === this.selectedOption) return;
    this.options.forEach((opt) => {
      opt.selected = false;
      if (opt.text === item.text) {
        opt.selected = true;
      }
    });
    this.selectedOption = item;
    this.onSelection.emit(this.selectedOption);
  }

  onPreselected() {
    this.options.forEach((opt) => {
      if (opt.selected) {
        this.selectedOption = opt;
      }
    });
    this.onSelection.emit(this.selectedOption);
  }

  clear(): void {
    this.selectedOption = undefined;
    this.options.forEach((opt) => {
      opt.selected = false;
    });
  }
}
