export const plantLocations = [
  {
    plant: 1000,
    location: 'San Bernardino',
  },
  {
    plant: 1010,
    location: 'Ensenada/San Diego',
  },
  {
    plant: 1020,
    location: 'Acoustasonics Ensenada',
  },
  {
    plant: 1040,
    location: 'SPA Ensenada',
  },
  {
    plant: 1050,
    location: 'Corona',
  },
  {
    plant: 1060,
    location: 'GDC',
  },
  {
    plant: 1065,
    location: 'Hong Kong',
  },
  {
    plant: 1080,
    location: 'Hollywood',
  },
  {
    plant: 1090,
    location: 'Scottsdale',
  },
  {
    plant: 1100,
    location: 'Ensenada/Mexico',
  },
  {
    plant: 2500,
    location: 'Japan',
  },
  {
    plant: 2700,
    location: 'Australia',
  },
];
