import { Component, OnInit } from '@angular/core';
import { IUser, IUserFormats } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/users/authentication.service';
import { UserService } from 'src/app/services/users/user.service';
import { IdentityService } from '../../../services/common/identity.service';
@Component({
  selector: 'app-documents-page',
  templateUrl: './documents-page.component.html',
})
export class DocumentsPageComponent implements OnInit {
  mUser!: IUser;
  userRole: string = '';
  userFormats!: IUserFormats;
  constructor(
    private identityService: IdentityService,
    private userService: UserService
  ) {}

  ngOnInit() {
    // Get Me
    this.identityService.getCurrentMeUser().subscribe((user) => {
      if (user) {
        this.mUser = user;
        this.userRole = user.role;
        this.userFormats = {
          timezone: this.mUser.timezone || '',
          unitOfMeasure: this.mUser.unitOfMeasure || '',
          dateFormat: this.mUser.dateFormat || 'MM-dd-yyyy',
          timeFormat: this.mUser.timeFormat || '',
        };
      }
    });
  }
}
