import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ROLES, RoleType } from 'src/app/models/role';
import { EmailOptIn, EmailOptInData } from 'src/app/models/user';

@Component({
  selector: 'app-multiselect-dropdown',
  templateUrl: './multiselect-dropdown.component.html',
})
export class MultiselectDropdownComponent implements OnInit {
  @Input() items: any[] | undefined = undefined;
  @Input() labelText?: any[];
  @Input() role?: string;
  @Input() roleType?: string;
  @Input() supplerNumberSelected?: string;
  @Input() isSmall: boolean = false;
  @Input() isProfile: boolean = false;
  @Input() isPO: boolean = false;
  @Input() maxPreview: number = 2;
  @Input() loadingOptions: boolean = false;
  @Input() emailOptInControl!: AbstractControl | null;
  @Input() preselectedValues: string[] | undefined = undefined;
  @Input() emailOptInData: EmailOptInData | undefined = undefined;
  @Output() save = new EventEmitter();
  checkboxesObject:
    | [{ name: string; value: string; status: boolean; hidden: boolean }]
    | any[] = [];
  editing: boolean = false;
  selectedValues: any[] = [];
  selectedValuesList: string = '';
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.preselectedValues) {
      this.setPreselectedValues();
    }
  }

  ngOnInit(): void {}

  setPreselectedValues() {
    this.checkboxesObject = [];
    this.selectedValues = [];
    if (this.items) {
      this.items.forEach((item: string, index: number) => {
        let checkboxObj = {
          name: this.setLabelName(
            item,
            this.labelText ? this.labelText[index] : null
          ),
          value: item,
          status: this.setOptionStatus(item),
          hidden: this.setOptionHidden(item),
        };
        if (checkboxObj.status && !checkboxObj.hidden) {
          this.selectedValues.push(item);
        }
        this.checkboxesObject.push(checkboxObj);
        this.setSelectedList();
      });
    }
  }

  setOptionStatus(item: string): boolean {
    if (this.preselectedValues && this.preselectedValues.includes(item)) {
      if (item === EmailOptIn.PURCHASE_ORDERS) {
        return (this.emailOptInData?.onPoCreated.supplierNumbers.includes(
          this.supplerNumberSelected!
        ) &&
          this.isProfile) ||
          !this.isProfile
          ? true
          : false;
      }
      return true;
    }
    return false;
  }

  setOptionHidden(item: string): boolean {
    return (
      item === EmailOptIn.PURCHASE_ORDERS &&
      ((this.roleType === RoleType.FMIC && !this.supplerNumberSelected) ||
        this.role === ROLES.SUPPIER_FACTORY)
    );
  }

  onChangeCheckbox(description: any, event: any) {
    var index = this.selectedValues.indexOf(description);
    if (event.target.checked) {
      if (index === -1) {
        this.selectedValues.push(description);
      }
    } else {
      if (index !== -1) {
        this.selectedValues.splice(index, 1);
      }
    }
    if (this.isPO) {
      this.save.emit(this.selectedValues);
    }
    this.emailOptInControl?.setValue(this.selectedValues);
    this.setSelectedList();
  }

  setLabelName(value: string, text?: string) {
    return !this.isPO ? value : `${value} - ${text}`;
  }

  // String List
  setSelectedList() {
    this.selectedValuesList = this.selectedValues
      .toString()
      .split(',')
      .slice(0, this.maxPreview)
      .join(', ');

    if (this.selectedValues.length > this.maxPreview) {
      this.selectedValuesList = `${this.selectedValuesList}, and ${
        this.selectedValues.length - this.maxPreview
      } more`;
    }
  }

  // Controls for Profile Section
  setEditing() {
    this.setPreselectedValues();
    this.editing = true;
  }
  cancelEditing() {
    this.editing = false;
    this.setPreselectedValues();
  }
  saveEdition() {
    // EMIT SAVE FUNCTION
    this.save.emit(this.selectedValues);
    this.editing = false;
    // Test
    this.preselectedValues = this.selectedValues;
  }
}
