import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { PurchaseOrdersService } from '../../../../services/purchase-orders/purchase-orders.service';
import {
  IProductLineCheckbox,
  IProductLineOptions,
  ProductLineGrouping,
  productLineCatalog,
} from 'src/app/fixtures/purchase-orders/product-line-catalog';
import { ProductLineCheckboxComponent } from '../product-line-checkbox/product-line-checkbox.component';

@Component({
  selector: 'app-product-line-filter',
  templateUrl: './product-line-filter.component.html',
  styles: [],
})
export class ProductLineFilterComponent implements OnInit {
  @Output() checkboxChange: EventEmitter<string> = new EventEmitter();

  @ViewChild('productLineCheckbox', { static: false })
  productLineCheckbox?: ProductLineCheckboxComponent;

  loadingOptions: boolean = false;

  optionsTotal: number = 0;
  filterPlaceholder: string = 'Select';

  // Catalog
  productLineCatalog: IProductLineOptions[] = [...productLineCatalog];
  // Options Array
  fenderOptions: IProductLineCheckbox[] = [];
  specialityOptions: IProductLineCheckbox[] = [];

  constructor(private purchaseOrdersService: PurchaseOrdersService) {}

  ngOnInit(): void {
    this.getProductLines();
  }

  getProductLines() {
    this.loadingOptions = true;
    this.purchaseOrdersService.getProductLines().subscribe((resp) => {
      this.getResultsCatalog(resp);
      this.loadingOptions = false;
    });
  }

  getResultsCatalog(productLineIds: number[]) {
    productLineIds.forEach((pLineId: number) => {
      this.productLineCatalog.find((product) => {
        if (
          product.productLine === pLineId &&
          product.grouping === ProductLineGrouping.FENDER
        ) {
          (product as IProductLineCheckbox).checked = false;
          this.fenderOptions.push(product as IProductLineCheckbox);
        }
        if (
          product.productLine === pLineId &&
          product.grouping === ProductLineGrouping.SPECIALITY
        ) {
          (product as IProductLineCheckbox).checked = false;
          this.specialityOptions.push(product as IProductLineCheckbox);
        }
      });
    });
    this.optionsTotal = productLineIds.length;
  }

  onChangeCheckbox(event: number[]) {
    this.setPlaceholder(event);
    this.checkboxChange.emit(event.toString());
  }

  setPlaceholder(selected: number[]) {
    if (selected.length === this.optionsTotal) {
      this.filterPlaceholder = 'All Selected';
    }
    if (selected.length === 0) {
      this.filterPlaceholder = 'Select';
    }

    if (selected.length < this.optionsTotal && selected.length > 0) {
      this.filterPlaceholder = selected.toString().split(',').join(', ');
    }
  }
}
