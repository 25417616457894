import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmlLinebreak',
})
export class HtmlLinebreakPipe implements PipeTransform {
  transform(value: string | undefined, ...args: unknown[]): any {
    let text = value?.replace(/\n/g, '<br/>');
    return text;
  }
}
