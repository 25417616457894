import { Component, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { GoogleAnalyticsService } from './services/common/google-analytics.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'suppliers-portal-web';
  route: string = '';
  logged: boolean = false;
  token!: string | null;
  constructor(
    public router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.router.events.subscribe((events) => {
      if (events instanceof NavigationEnd) {
        this.route = router.url;
        this.logged = this.isLogged();
      }
    });
    this.googleAnalyticsService.setGoogleAnalytics();
  }

  isLogged() {
    if (
      this.route.includes('login') ||
      this.route.includes('/forgot-password') ||
      this.route.includes('/reset-password') ||
      this.route.includes('/activate')
    ) {
      document.body.classList.remove('dashboard');
      document.body.classList.add('login');
      return false;
    } else {
      document.body.classList.remove('login');
      document.body.classList.add('dashboard');
      return true;
    }
  }
}
