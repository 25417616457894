import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, Observable, throwError, timeout } from 'rxjs';
import { ISupplier } from 'src/app/models/supplier';
import { HttpHelperService } from '../helper/http-helper.service';

@Injectable({
  providedIn: 'root',
})
export class SupplierService {
  constructor(
    private httpClient: HttpClient,
    private httpHelperService: HttpHelperService
  ) {}

  getSupplier(number: string) {
    if (!number) {
      return throwError(() => new Error('Token Must Be Provided'));
    }
    return this.httpHelperService.get(`/suppliers/${number}`).pipe(
      timeout(environment.api.timeout),
      catchError((err) => {
        throw new Error('Users API. Details: ' + err.error.message);
      })
    );
  }
}
