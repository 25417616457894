import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fender-tone-loading-div',
  templateUrl: './fender-tone-loading-div.component.html',
})
export class FenderToneLoadingDivComponent implements OnInit {
  @Input() small = false;
  @Input() widget = false;
  @Input() inline = false;
  @Input() black = false;
  @Input() whiteF = false;
  constructor() {}

  ngOnInit(): void {}
}
