import { Injectable } from '@angular/core';
import { catchError, Observable, of, throwError, timeout } from 'rxjs';
import { HttpHelperService } from './../helper/http-helper.service';
import { IAnnouncement } from 'src/app/models/announcement';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementsService {
  constructor(private httpHelperService: HttpHelperService) {}

  getAnnouncementsList() {
    return this.httpHelperService.get('/announcements').pipe(
      catchError((err) => {
        throw new Error('Announcement API. Details: ' + err.error.message);
      })
    );
  }

  getAnnouncement(id: string): Observable<IAnnouncement> {
    if (!id) {
      return throwError(() => new Error('Id Must Be Provided'));
    }

    return this.httpHelperService.get(`/announcements/${id}`).pipe(
      catchError((err) => {
        throw new Error('Announcement API. Details: ' + err.error.message);
      })
    );
  }

  getActiveAnnouncement(id: string): Observable<IAnnouncement> {
    if (!id) {
      return throwError(() => new Error('Id Must Be Provided'));
    }

    return this.httpHelperService.get(`/announcements/${id}/active`).pipe(
      catchError((err) => {
        throw new Error('Announcement API. Details: ' + err.error.message);
      })
    );
  }

  getActiveAnnouncements(): Observable<IAnnouncement[]> {
    return this.httpHelperService.get('/announcements/active').pipe(
      catchError((err) => {
        throw new Error('Announcement API. Details: ' + err.error.message);
      })
    );
  }

  createAnnouncement(announcement: any, filename: string | undefined) {
    if (!announcement) {
      return throwError(() => new Error('Parameters Must Be Provided'));
    }
    return this.httpHelperService
      .post('/announcements', this.prepareAnnoncement(announcement, filename))
      .pipe(
        catchError((err) => {
          throw new Error('Announcement API. Details: ' + err.error.message);
        })
      );
  }

  updateAnnouncement(
    id: any,
    announcement: IAnnouncement,
    filename: string | undefined
  ) {
    if (!announcement || !id) {
      return throwError(() => new Error('Parameters Must Be Provided'));
    }
    return this.httpHelperService
      .put(
        `/announcements/${id}`,
        this.prepareAnnoncement(announcement, filename)
      )
      .pipe(
        catchError((err) => {
          throw new Error('Announcement API. Details: ' + err.error.message);
        })
      );
  }

  toggleActiveAnnouncement(id: any, active: boolean) {
    if (!id) {
      return throwError(() => new Error('Parameters Must Be Provided'));
    }
    return this.httpHelperService
      .put(`/announcements/${id}`, { enable: active })
      .pipe(
        catchError((err) => {
          throw new Error('Announcement API. Details: ' + err.error.message);
        })
      );
  }

  duplicateAnnouncement(id: string) {
    if (!id) {
      return throwError(() => new Error('Parameters Must Be Provided'));
    }
    return this.httpHelperService
      .post(`/announcements/${id}/duplicate`, {})
      .pipe(
        catchError((err) => {
          throw new Error('Announcement API. Details: ' + err.error.message);
        })
      );
  }

  seenAnnouncement(id: any) {
    if (!id) {
      return throwError(() => new Error('Parameters Must Be Provided'));
    }
    return this.httpHelperService.post(`/announcements/${id}/seen`, {}).pipe(
      catchError((err) => {
        throw new Error('Announcement API. Details: ' + err.error.message);
      })
    );
  }

  deleteAnnouncement(id: number) {
    if (!id) {
      return throwError(() => new Error('Id Must Be Provided'));
    }
    return this.httpHelperService.delete(`/announcements/${id}`).pipe(
      catchError((err) => {
        throw new Error('Announcement API. Details: ' + err.error.message);
      })
    );
  }

  // Private Functions
  private prepareAnnoncement(
    announcement: any,
    filename: string | undefined
  ): IAnnouncement | null {
    if (!announcement) {
      return null;
    }
    const announcementObject: IAnnouncement = {
      type: announcement.type,
      title: announcement.title,
      description: announcement.description
        ? announcement.description
        : undefined,
      enable: announcement.enable ? announcement.enable : false,
      startsAt: announcement.startDate ? announcement.startDate : undefined,
      endsAt: announcement.endDate,
      imageName: filename ? filename : undefined,
      url: announcement.url ? announcement.url : undefined,
      content: announcement.content ? announcement.content : undefined,
      roleIds: announcement.roles,
      supplierNumbers: announcement.inviteList ? announcement.inviteList : [],
      isNew: announcement.isNew,
    };
    return announcementObject;
  }
}
