import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UiModule } from '../ui/ui.module';
import { CompletePasswordComponent } from './complete-password/complete-password.component';
import { BrowserSupportComponent } from '../ui/browser-support/browser-support.component';

@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    CompletePasswordComponent,
    BrowserSupportComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, UiModule],
  exports: [LoginComponent],
})
export class AuthenticationModule {}
