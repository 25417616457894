<div class="btn-group w-100" dropdown>
  <!-- Select Input -->
  <div class="input-group">
    <input
      class="form-control bg-white input-with-chevron cursor-default text-dark"
      value=" {{ selectedOption ? selectedOption.text : 'Select' }}"
      [ngClass]="{
        'form-control-sm': isSmall,
        'new-ui-small': isSmall
      }"
      readonly
      disabled
      dropdownToggle
    />
  </div>
  <!-- Dropdown Options -->
  <div
    *dropdownMenu
    class="dropdown-menu new-ui-select-dropdown overflow-auto w-100 p-1"
  >
    <div class="list-group">
      <a
        *ngFor="let item of options; let odd = odd"
        class="list-group-item list-group-item-action border-0 rounded f-font text-decoration-none text-dark cursor-pointer"
        [ngClass]="{
          'new-odd-select ': odd,
          'new-ui-select-selected': item.selected,
          'new-ui-small': isSmall
        }"
        (click)="onSelect(item)"
        >{{ item.text || item.value }}</a
      >
    </div>
  </div>
</div>
