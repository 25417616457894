<ng-container *ngIf="!loading; else onLoading">
  <ng-container *ngIf="!preventActive; else onPreventActive">
    <a
      [popover]="popOverString"
      triggers="mouseenter:mouseleave"
      class="f-btn btn-xs btn-std"
      type="button"
      data-cy="table-toggle-active_btn"
      (click)="toggleActive(status)"
      [ngClass]="{
        'f-green': status,
        'inactive-btn': !status
      }"
    >
      <i class="fa-solid fa-check fa-white fa-lg"></i>
    </a>
  </ng-container>
</ng-container>

<ng-template #onPreventActive>
  <a
    [popover]="popOverString"
    data-cy="table-toggle-active_btn"
    triggers="mouseenter:mouseleave"
    class="f-btn btn-xs btn-std inactive-btn"
  >
    <i class="fa-solid fa-check fa-white fa-lg"></i>
  </a>
</ng-template>

<ng-template #onLoading>
  <span class="inline-loading-container-sm">
    <app-fender-tone-loading-div [black]="true"></app-fender-tone-loading-div>
  </span>
</ng-template>
