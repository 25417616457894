<form [formGroup]="parentForm">
  <label class="f-font required">Background Type</label>
  <select
    id="backgroundType"
    #backgroundType
    class="form-select w-100"
    (change)="onBackgroundTypeChange(backgroundType.value)"
    formControlName="backgroundType"
    data-cy="background_selector-type-select"
  >
    <option selected disabled>Select background type</option>
    <option value="RGB">Color</option>
    <option value="ImageFile">Image</option>
  </select>
  <div *ngIf="backgoundSelected === 'RGB'" class="mt-2 color-sketch-container">
    <app-reactive-color-selector
      [colorFormControl]="$any(parentForm).controls['background']"
      [color]="color"
      data-cy="background_selector-color_selector"
    ></app-reactive-color-selector>
  </div>
  <div *ngIf="backgoundSelected === 'ImageFile'" class="mt-2">
    <app-reactive-image-selector
      [required]="true"
      [imageFormControl]="$any(parentForm).controls['background']"
      [imageUrl]="$any(parentForm).controls['background'].value"
      [allowImageUrl]="false"
      [resolutionText]="'Minimum Width 1700px. Height May Vary'"
      [aspectRatio]="3"
      [maxMBs]="5"
      [imageNamePrefix]="'image'"
      (uploadedFileName)="getUploadedFile($event)"
    >
    </app-reactive-image-selector>
  </div>
</form>
