import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { INewUISelect } from 'src/app/models/ui-components';
import { PoHelperService } from '../../../services/purchase-orders/po-helper.service';
import {
  PoStatus,
  PoStatusEndpointValue,
} from 'src/app/models/purchase-orders';
import { PurchaseOrdersService } from 'src/app/services/purchase-orders/purchase-orders.service';
import { ToastAlertComponent } from '../../toast-alert/toast-alert.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from '../../../services/common/google-analytics.service';

@Component({
  selector: 'app-po-status-select-table',
  templateUrl: './po-status-select-table.component.html',
  styles: [],
})
export class PoStatusSelectTableComponent implements OnInit {
  @Input() public options: INewUISelect[] = [
    ...this.poHelperService.setTableStatusOptions(),
  ];
  @Input() public preselectedOption?: string;
  @Input() public poInfo?: any;

  @Input() public isSmall?: boolean = true;

  @Output() onSelection = new EventEmitter();

  @ViewChild('errorToast')
  errorToast!: ToastAlertComponent;

  public selectedOption: INewUISelect | undefined = undefined;
  public clickedOption: INewUISelect | undefined = undefined;
  public loadingStatusChange: boolean = false;

  // Params
  noteString: string = '';
  requestedDateChange: string = '';

  // MODALS
  modalRef!: BsModalRef;
  // Note Modal UI
  @ViewChild('noteModal', { static: true })
  noteModal!: TemplateRef<any>;
  // Change Date Modal UI
  @ViewChild('changeDateModal', { static: true })
  changeDateModal!: TemplateRef<any>;

  constructor(
    private purchaseOrdersService: PurchaseOrdersService,
    private poHelperService: PoHelperService,
    private modalService: BsModalService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['preselectedOption'] &&
      changes['preselectedOption'].previousValue !=
        changes['preselectedOption'].currentValue
    ) {
      this.onPreselected();
    }
  }

  ngOnInit(): void {}

  onPreselected() {
    this.options.forEach((opt) => {
      opt.selected = false;
      if (opt.text === this.preselectedOption) {
        opt.selected = true;
        this.selectedOption = opt;
      }
    });
  }

  onChangeStatus(item: INewUISelect): void {
    if (item === this.selectedOption) return;
    this.clickedOption = item;
    this.setGA4StatusEvent(item.text!);

    if (item.value === PoStatusEndpointValue.ACCEPT) {
      this.saveStatus(PoStatusEndpointValue.ACCEPT);
    }
    if (item.value === PoStatusEndpointValue.REJECT) {
      this.showModal(this.noteModal);
    }
    if (item.value === PoStatusEndpointValue.UPDATE) {
      this.saveStatus(PoStatusEndpointValue.UPDATE);
    }
    if (item.value === PoStatusEndpointValue.REQ_DATE_CHANGE) {
      this.showModal(this.changeDateModal);
    }
    if (item.value === PoStatusEndpointValue.REQ_LINE_ITEM_CHANGE) {
      this.onStartAsEditingMode();
    }
  }

  saveStatus(status: string): void {
    this.loadingStatusChange = true;
    const params = this.setParam(status);
    if (this.poInfo.supplierNumber && this.poInfo.poNumber) {
      this.purchaseOrdersService
        .savePoStatus(
          this.poInfo.supplierNumber,
          this.poInfo.poNumber,
          status,
          params
        )
        .subscribe({
          error: () => {
            this.errorToast?.show();
            this.loadingStatusChange = false;
          },
          complete: () => {
            this.setSelected();
            this.loadingStatusChange = false;
            this.noteString = '';
          },
        });
    }
  }

  setSelected() {
    this.options.forEach((opt) => {
      opt.selected = false;
      if (opt.value === this.clickedOption?.value) {
        opt.selected = true;
      }
    });
    this.selectedOption = this.clickedOption;
    this.clickedOption = undefined;
  }

  setParam(status: string) {
    let paramObj: any = {};
    switch (status) {
      case PoStatusEndpointValue.REJECT:
        paramObj.note = this.noteString;
        break;
      case PoStatusEndpointValue.REQ_DATE_CHANGE:
        paramObj.requestedShipDate = this.requestedDateChange;
        break;
    }
    return paramObj;
  }

  showModal(modal: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(modal);
    this.modalService.onHide.pipe(take(1)).subscribe((reason) => {
      if (reason === 'backdrop-click') {
        this.closeModal();
      }
    });
  }

  selectableStatus(status: string) {
    return this.poHelperService.selectableStatusOnPoTable(status);
  }

  closeModal(): void {
    this.modalRef.hide();
  }

  onSaveReject(note: string): void {
    this.noteString = note;
    this.saveStatus(PoStatusEndpointValue.REJECT);
  }

  onSaveDateChange(requestedDate: string): void {
    this.requestedDateChange = requestedDate;
    this.saveStatus(PoStatusEndpointValue.REQ_DATE_CHANGE);
  }

  onStartAsEditingMode() {
    this.router.navigate([
      `/purchase-orders/${this.poInfo.poNumber}`,
      {
        supplierNumber: this.poInfo.supplierNumber,
        editingMode: PoStatus.REQ_LINE_ITEM_CHANGE,
      },
    ]);
  }

  formatTextValueForSelect(text: string | undefined): string {
    if (!text) return '';
    switch (text) {
      case PoStatus.REQ_DATE_CHANGE:
        return 'Req. Date Change';
      case PoStatus.REQ_LINE_ITEM_CHANGE:
        return 'Req. Line Item Change';
    }
    return text;
  }

  setGA4StatusEvent(status: string): void {
    this.googleAnalyticsService.trackEvent(
      'purchase_orders',
      'Status Selection - Summary',
      status
    );
  }
}
