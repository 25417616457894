export interface IMainMenu {
  name: string;
  icon: string;
  route: string;
  visible: boolean;
  subItems?: IMainSubMenu[] | any;

  isCustom?: boolean;
  navigationIcon?: string;
}

export interface IMainSubMenu {
  title: string;
  icon: string;
  route: string;
  visible: boolean;
}

export enum MainMenuOptionName {
  EVENTS = 'Events',
  DOCUMENT = 'Documents',
  FAQ = 'FAQ / Help',
  PURCHASE_ORDERS = 'Purchase Orders',
  LOOKUP = 'Lookup',
}

export enum PoOptionSubMenuOptions {
  BY_PO = 'By PO #',
  BY_MODEL = 'By Model #',
  SUMMARY = 'Summary',
}
