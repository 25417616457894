import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { HelperService } from '../../services/helper/helper.service';

@Component({
  selector: 'app-browser-support',
  templateUrl: './browser-support.component.html',
})
export class BrowserSupportComponent implements OnInit {
  @Output() continueAnyways: EventEmitter<any> = new EventEmitter();

  browserVersion: { name: string; version: string } = {
    name: '',
    version: '',
  };
  constructor(private helperService: HelperService) {}

  ngOnInit(): void {
    this.browserVersion = this.helperService.browserDetection();
  }

  continue() {
    this.continueAnyways.emit();
  }
}
