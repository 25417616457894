<div class="menu-bar">
  <div
    id="hide-menu"
    class="collapse-button cursor-pointer"
    (click)="mainMenuClick()"
  >
    <span>
      <a title="Collapse Menu">
        <i *ngIf="!menuIsOpen" class="fa-solid fa-bars"></i>
        <i *ngIf="menuIsOpen" class="fa-solid fa-xmark"></i>
      </a>
    </span>
  </div>
  <nav>
    <ul class="main-menu-list">
      <!-- Default Main Menu Option -->
      <ng-container *ngFor="let menuItem of menuArray">
        <ng-container *ngIf="menuItem.visible && !menuItem.isCustom">
          <app-option-main-menu
            [menuItem]="menuItem"
            (navigatePage)="navigatePage($event)"
            (selectSubItem)="selectSubItem($event)"
          >
          </app-option-main-menu>
        </ng-container>
      </ng-container>
      <!-- Custom Page Option -->
      <ng-container *ngIf="customPagesArray.length">
        <ng-container *ngFor="let customPage of customPagesArray">
          <ng-container *ngIf="customPage.visible">
            <app-option-custom-page
              [menuItem]="customPage"
              (navigatePage)="navigatePage($event)"
            ></app-option-custom-page>
          </ng-container>
        </ng-container>
      </ng-container>
    </ul>
  </nav>
</div>
<app-mobile-main-manu
  [menuArray]="menuArray"
  [customPagesArray]="customPagesArray"
  [supplierNumber]="supplierNumber"
  [menuIsOpen]="menuIsOpen"
  (emitCloseMenu)="closeMenu()"
></app-mobile-main-manu>
<app-select-supplier [visible]="canSelectSupplier"></app-select-supplier>
