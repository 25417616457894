import { from, Observable, throwError } from 'rxjs';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../users/authentication.service';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptorService implements HttpInterceptor {
  authUserError: any;
  constructor(
    private route: Router,
    private authenticationService: AuthenticationService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(
      catchError(async (err: HttpErrorResponse) => {
        if (err.status == 0 || err.status == 401) {
          const authUser = await this.checkForToken();
          if (authUser === 'The user is not authenticated') {
            await this.authenticationService.signOut();
            this.route.navigateByUrl('/login');
          }
          return throwError(() => err);
        } else {
          throw throwError(() => err);
        }
      })
    );
  }

  private async checkForToken() {
    const userError = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    }).catch((err) => {
      return err;
    });
    return userError;
  }
}
