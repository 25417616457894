<div class="container-fluid py-5">
  <div class="card po">
    <div class="card-header small-header-dark">
      <span class="card-title f-font m-0">{{ "Purchase Orders Details" }}</span>
    </div>
    <!-- Card Body -->
    <div class="card-body" *ngIf="hasPoData && !loadingData; else poLoading">
      <!-- Top Section with Status, Banner, and Export Buttons -->
      <div class="row mb-1">
        <!-- Accept/Reject Select Component -->
        <div class="col-md-4 mb-2 mb-md-0 d-flex align-items-center">
          <app-accept-reject-select
            [existingStatus]="existingStatus"
            [existingNote]="existingNote"
            [supplierNumber]="currentSupplierNumber"
            [poNumber]="poNumber"
            [isFmicUser]="isFmicUser"
            [userDateFormat]="userDateFormat"
            [originalShipDate]="purchaseOrderList[0].originalShipDate"
            [existingRequestedShipDate]="requestedShipDate"
            [updateRequired]="updateByFMICRequired"
            (refreshStatus)="getStatus()"
            (setLineEditingModeAction)="setLineEditingModeAction($event)"
          ></app-accept-reject-select>
        </div>

        <!-- Yellow Banner -->
        <div
          class="col-md-4 mb-2 mb-md-0 d-flex justify-content-center align-items-center"
        >
          <div *ngIf="showBanner && bannerMessage" class="yellow-banner">
            <img
              class="me-1"
              src="/assets/images/warning-icon.svg"
              alt="Warning"
            />
            {{ bannerMessage }}
          </div>
        </div>

        <!-- Export Buttons -->
        <div class="col-md-4 d-flex justify-content-md-end align-items-center">
          <button
            class="f-btn f-red f-xs me-2 has-gif"
            (click)="downloadDetailsPdf()"
          >
            <i
              *ngIf="!loadingPoPdf.loader"
              class="fa-solid fa-cloud-arrow-down m-0"
            ></i>
            <span *ngIf="loadingPoPdf.loader">
              <app-fender-tone-loading-div
                [inline]="true"
                [whiteF]="true"
              ></app-fender-tone-loading-div>
            </span>
          </button>
          <button class="f-btn f-green f-xs" (click)="downloadDetailsReport()">
            <i
              *ngIf="!loadingReport.loader"
              class="fa-regular fa-file-excel"
            ></i>
            <span *ngIf="loadingReport.loader">
              <app-fender-tone-loading-div
                [inline]="true"
              ></app-fender-tone-loading-div>
            </span>
            Export Order Details
          </button>
        </div>
      </div>
      <hr />
      <div class="row">
        <!-- PO & Supplier Information -->

        <div class="f-font col-12 col-md-4 mb-5">
          <div class="py-2 px-3 mb-2 bg-gray-silver">
            <h2 class="f-font m-0">PO Information</h2>
          </div>
          <div class="ms-3">
            <p class="mb-1 mt-0">
              <span class="f-bold">Supplier:&nbsp;</span
              >{{ currentSupplierNumber }}
            </p>
            <p class="mb-1 mt-0">
              <span class="f-bold">PO#:&nbsp;</span>{{ poNumber }}
            </p>
            <p class="mb-1 mt-0">
              <span class="f-bold">Open Quantity:&nbsp;</span
              >{{ poOpenQtyTotal | number }}
            </p>
            <p class="mb-1 mt-0">
              <span class="f-bold">Open Value:&nbsp;</span>
              {{ transformCurrencyValue(poOpenValueTotal) }}
            </p>
          </div>
        </div>
        <!-- Address Information -->
        <div class="col-12 col-md-4 mb-5">
          <div class="py-2 px-3 mb-2 bg-gray-silver">
            <h2 class="f-font m-0">Destination Address</h2>
          </div>
          <div class="ms-3 f-font">
            <p class="text-pre-line">{{ poAddress }}</p>
          </div>
        </div>
        <!-- Purchaser Information -->
        <div class="col-12 col-md-4 mb-5">
          <div class="py-2 px-3 mb-2 bg-gray-silver">
            <h2 class="f-font m-0">Purchaser</h2>
          </div>
          <div class="ms-3 f-font">
            <p *ngIf="purchaserInfo.name" class="mb-1 mt-0">
              <span class="f-bold">Name:&nbsp;</span>{{ purchaserInfo.name }}
            </p>
            <p *ngIf="purchaserInfo.email" class="mb-1 mt-0">
              <span class="f-bold">Email:&nbsp;</span>{{ purchaserInfo.email }}
            </p>
          </div>
        </div>
      </div>
      <!-- Open Items Section -->
      <div>
        <div class="py-2 px-3 bg-gray-silver">
          <div class="d-flex justify-content-between">
            <h3 class="f-font m-0">Open Items</h3>
            <a
              *ngIf="existingStatus == 'Requested Line Item Change'"
              class="f-btn btn-xs f-yellow text-decoration-none btn-std"
              type="button"
              (click)="showReadOnlyNoteModal(readOnlyNoteModal, 0, true)"
            >
              Show All Requests
            </a>
          </div>
        </div>
        <!-- ON Table Edit Mode -->
        <div *ngIf="isLineItemEditingMode" class="py-2 px-3 bg-warning">
          <h3 class="f-font m-0">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <i class="fa-solid fa-pencil me-2"></i>Table in Edit Mode
              </div>
              <div>
                <button
                  class="f-btn f-black f-xs me-2 has-gif"
                  (click)="
                    setLineEditingModeAction({ action: 'onEdit', value: false })
                  "
                >
                  Cancel
                </button>
                <button
                  class="f-btn f-blue f-xs me-2 has-gif"
                  (click)="saveLineItemChangeRequest()"
                  [disabled]="
                    !lineItemFormArray.valid || lineItemFormArray.pristine
                  "
                >
                  <i class="fa-regular fa-floppy-disk me-2"></i>Save
                </button>
              </div>
            </div>
          </h3>
        </div>

        <div class="container-lg mt-3">
          <!-- TABLE -->
          <div>
            <table class="f-table table-responsive-xl f-font">
              <thead>
                <tr>
                  <th>
                    <span
                      id="table-heading-ponumber-srt"
                      class="sort-table-heading"
                      role="button"
                      (click)="changeSort('poItem')"
                      [ngClass]="{ 'f-bold': sort.by == 'poItem' }"
                    >
                      Line Item
                      <i
                        *ngIf="sort.by == 'poItem'"
                        class="fa-solid fa-xs"
                        [ngClass]="{
                          'fa-sort-up': sort.type === 'asc',
                          'fa-sort-down': sort.type === 'desc'
                        }"
                      ></i>
                    </span>
                  </th>
                  <th>
                    <span class="no-sort-table-heading"> PO Create Date </span>
                  </th>
                  <th>
                    <span class="no-sort-table-heading"> Model Name </span>
                  </th>
                  <th>
                    <span class="no-sort-table-heading"> Model # </span>
                  </th>
                  <th>
                    <span class="no-sort-table-heading"> Delivery Plant </span>
                  </th>
                  <th>
                    <span class="no-sort-table-heading"> Open Qty </span>
                  </th>
                  <th>
                    <span class="no-sort-table-heading"> Open Value </span>
                  </th>
                  <th>
                    <span class="no-sort-table-heading"> Unit Price </span>
                  </th>
                  <th>
                    <span class="no-sort-table-heading">
                      Production Month
                    </span>
                  </th>
                  <th>
                    <span class="no-sort-table-heading">
                      Original Ship Date
                    </span>
                  </th>
                  <th>
                    <span class="no-sort-table-heading">
                      Current Ship Date
                    </span>
                  </th>
                  <th>
                    <span class="no-sort-table-heading"> Qty Shipped </span>
                  </th>
                  <ng-container
                    *ngIf="
                      isLineItemEditingMode ||
                      existingStatus == 'Requested Line Item Change'
                    "
                  >
                    <th>
                      <span class="no-sort-table-heading"> Comments </span>
                    </th>
                  </ng-container>
                </tr>
              </thead>
              <!-- Table Body -->
              <tbody *ngIf="!loadingTable">
                <tr
                  *ngFor="let purchaseOrder of purchaseOrderList; let i = index"
                >
                  <td data-label="Line Item" class="position-relative">
                    <div
                      *ngIf="getEditigLineInfoAt(i, 'Line')"
                      class="po-table-edited-line-icon align-middle"
                    >
                      <div class="d-flex align-items-center">
                        <i class="f-badge f-sm f-warning me-1"></i
                        ><i
                          class="fa-solid fa-chevron-right fa-xl text-warning"
                        ></i>
                      </div>
                    </div>
                    {{ purchaseOrder.poItem | number }}
                  </td>
                  <td data-label="PO Create Date">
                    {{ purchaseOrder.createDate | date : userDateFormat }}
                  </td>
                  <td data-label="Model Name">
                    {{ purchaseOrder.modelName }}
                  </td>
                  <td data-label="Model Number">
                    {{ purchaseOrder.modelNumber }}
                  </td>
                  <td data-label="Delivery Plant">
                    {{ purchaseOrder.plant }}
                  </td>
                  <td
                    *ngIf="
                      !isLineItemEditingMode &&
                      !getEditigLineInfoAt(i, 'Field', 'openQuantity')
                    "
                    data-label="Open Qty"
                  >
                    {{ purchaseOrder.openQuantity | number }}
                  </td>
                  <!-- openQuantity EDITING input -->
                  <td
                    *ngIf="isLineItemEditingMode"
                    data-label="Open Qty"
                    class="position-relative"
                  >
                    <input
                      type="text"
                      class="form-control form-control-sm f-table-input-sm"
                      [formControl]="getFormControlAt(i, 'openQuantity')"
                      [ngClass]="{
                        'is-invalid': getFormControlAt(i, 'openQuantity')
                          .invalid
                      }"
                    />
                    <div
                      class="invalid-tooltip text-nowrap table-onedit-input-tooltip-sm"
                    >
                      <span
                        class="d-block"
                        *ngIf="
                          getFormControlAt(i, 'openQuantity').hasError(
                            'required'
                          )
                        "
                        >Cant be empty</span
                      >
                      <span
                        class="d-block"
                        *ngIf="
                          getFormControlAt(i, 'openQuantity').hasError(
                            'pattern'
                          )
                        "
                        >Only numbers</span
                      >
                      <span
                        class="d-block"
                        *ngIf="
                          getFormControlAt(i, 'openQuantity').hasError(
                            'maxlength'
                          )
                        "
                        >10 character limit</span
                      >
                    </div>
                  </td>
                  <!-- openQuantity EDITED state -->
                  <td
                    *ngIf="getEditigLineInfoAt(i, 'Field', 'openQuantity')"
                    class="bg-warning"
                    data-label="Open Qty"
                  >
                    <div
                      [popover]="
                        'Original: ' +
                        getEditigLineInfoAt(i, 'Value', 'openQuantity').original
                      "
                      triggers="mouseenter:mouseleave"
                    >
                      {{ getEditigLineInfoAt(i, "Value", "openQuantity").new }}
                    </div>
                  </td>
                  <td data-label="Open Value">
                    {{ transformCurrencyValue(purchaseOrder.openValue) }}
                  </td>
                  <!-- New -->
                  <td
                    *ngIf="
                      !isLineItemEditingMode &&
                      !getEditigLineInfoAt(i, 'Field', 'unitPrice')
                    "
                    data-label="Unit Price"
                  >
                    {{ transformCurrencyValue(purchaseOrder.netPrice) }}
                  </td>
                  <!-- netPrice EDITING input -->
                  <td
                    *ngIf="isLineItemEditingMode"
                    data-label="Unit Price"
                    class="position-relative"
                  >
                    <input
                      type="text"
                      class="form-control form-control-sm f-table-input-sm"
                      [formControl]="getFormControlAt(i, 'netPrice')"
                      [ngClass]="{
                        'is-invalid': getFormControlAt(i, 'netPrice').invalid
                      }"
                    />
                    <div
                      class="invalid-tooltip text-nowrap table-onedit-input-tooltip-sm"
                    >
                      <span
                        class="d-block"
                        *ngIf="
                          getFormControlAt(i, 'netPrice').hasError('required')
                        "
                        >Cant be empty</span
                      >
                      <span
                        class="d-block"
                        *ngIf="
                          getFormControlAt(i, 'netPrice').hasError('pattern')
                        "
                        >Only numbers <br />
                        <span *ngIf="poCurrency == 'USD'"> and $ symbol</span>
                        <span *ngIf="poCurrency == 'EUR'"> and € symbol</span>
                        <span *ngIf="poCurrency == 'JPY'"> and ¥ symbol</span>
                        <span
                          *ngIf="poCurrency == 'RMB' || poCurrency == 'CN¥'"
                        >
                          and CN¥ symbol</span
                        >
                      </span>
                      <span
                        class="d-block"
                        *ngIf="
                          getFormControlAt(i, 'netPrice').hasError('maxlength')
                        "
                        >13 character limit</span
                      >
                    </div>
                  </td>
                  <!-- netPrice EDITED state -->
                  <td
                    *ngIf="getEditigLineInfoAt(i, 'Field', 'unitPrice')"
                    class="bg-warning"
                    data-label="Open Qty"
                  >
                    <div
                      [popover]="
                        'Original: ' +
                        transformCurrencyValue(
                          getEditigLineInfoAt(i, 'Value', 'unitPrice').original
                        )
                      "
                      triggers="mouseenter:mouseleave"
                    >
                      {{
                        transformCurrencyValue(
                          getEditigLineInfoAt(i, "Value", "unitPrice").new
                        )
                      }}
                    </div>
                  </td>

                  <td data-label="Production Month">
                    {{ purchaseOrder.productionMonth | date : "MMMM y" }}
                  </td>
                  <td data-label="Original Ship Date">
                    {{ purchaseOrder.originalShipDate | date : userDateFormat }}
                  </td>
                  <td data-label="Current Ship Date">
                    {{ purchaseOrder.currentShipDate | date : userDateFormat }}
                  </td>
                  <td data-label="Qty Shipped">
                    {{ purchaseOrder.shippedQuantity | number }}
                  </td>
                  <!-- Comments -->
                  <ng-container
                    *ngIf="
                      isLineItemEditingMode &&
                      !getFormControlAt(i, 'comment').value
                    "
                  >
                    <td data-label="Comments">
                      <a
                        class="f-btn btn-xs f-green btn-std"
                        type="button"
                        (click)="showLineItemNoteModal(noteModal, i)"
                      >
                        <i class="fa-solid fa-plus fa-white"></i>
                      </a>
                      &nbsp;Add
                    </td>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      isLineItemEditingMode &&
                      getFormControlAt(i, 'comment').value
                    "
                  >
                    <td data-label="Comments">
                      <a
                        class="f-btn btn-xs f-yellow btn-std"
                        type="button"
                        (click)="showLineItemNoteModal(noteModal, i)"
                      >
                        Show Comment
                      </a>
                    </td>
                  </ng-container>
                  <ng-container
                    *ngIf="existingStatus == 'Requested Line Item Change'"
                  >
                    <td data-label="Comments">
                      <a
                        *ngIf="
                          getEditigLineInfoAt(i, 'Field', 'comment') &&
                          getEditigLineInfoAt(i, 'Value', 'comment')
                        "
                        class="f-btn btn-xs f-yellow btn-std"
                        type="button"
                        (click)="showReadOnlyNoteModal(readOnlyNoteModal, i)"
                      >
                        Show Comment
                      </a>
                    </td>
                  </ng-container>
                </tr>
              </tbody>

              <tbody *ngIf="loadingTable">
                <tr class="text-center">
                  <td colspan="12" class="pt-3">
                    <app-fender-tone-loading-div
                      [widget]="true"
                      [black]="true"
                    ></app-fender-tone-loading-div>
                    <h4 class="f-font mt-3">Loading Items</h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- / TABLE -->
        </div>
      </div>
    </div>
    <!-- Loading Card -->
    <ng-template #poLoading>
      <ng-container *ngIf="loadingData; else noResults">
        <div
          class="mt-5 d-flex flex-column justify-content-center align-items-center"
        >
          <app-fender-tone-loading-div
            [black]="true"
          ></app-fender-tone-loading-div>
          <h4 class="f-font mt-3">Loading</h4>
        </div>
      </ng-container>
    </ng-template>
    <!-- No Results Card -->
    <ng-template #noResults>
      <div
        class="my-5 d-flex flex-column justify-content-center align-items-center"
      >
        <h3 class="f-font">No results found.</h3>
      </div>
    </ng-template>
  </div>
</div>

<!-- Line Item Modal COMMENT -->
<ng-template #noteModal>
  <div class="modal-header border-bottom-0">
    <h3 class="modal-title pull-left f-font f-bold">Add a Note</h3>
    <div
      type="button"
      aria-label="Close"
      (click)="closeNoteModal(lineItemCommentIndex)"
    >
      <i class="fa-regular fa-circle-xmark fa-xl"></i>
    </div>
  </div>
  <div class="modal-body">
    <div>
      <p class="f-font"><span class="f-bold">PO#: </span>{{ poNumber }}</p>
    </div>
    <div>
      <div class="form-group">
        <textarea
          #noteTextArea
          class="form-control"
          rows="6"
          [formControl]="getFormControlAt(lineItemCommentIndex, 'draft')"
          [ngClass]="{
            'is-invalid': noteTextArea.value.length > 500
          }"
        ></textarea>
        <div class="d-flex justify-content-end mt-1">
          <small
            class="f-font m-0"
            [ngClass]="{
              'text-danger': noteTextArea.value.length > 500
            }"
            >Characters Remaining:&nbsp;{{
              500 - noteTextArea.value.length
            }}/500</small
          >
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer border-top-0">
    <div class="d-flex">
      <button
        class="f-btn f-green f-xs me-2"
        [disabled]="
          !getFormControlAt(lineItemCommentIndex, 'draft').valid ||
          getFormControlAt(lineItemCommentIndex, 'draft').pristine
        "
        (click)="saveLineItemNoteModal(lineItemCommentIndex)"
      >
        <i class="fa-regular fa-floppy-disk"></i>
        <span class="me-2">Save</span>
      </button>
      <button
        class="f-btn f-black f-xs"
        (click)="closeNoteModal(lineItemCommentIndex)"
      >
        Cancel
      </button>
    </div>
  </div>
</ng-template>

<ng-template #readOnlyNoteModal>
  <div class="modal-header border-bottom-0">
    <h3 class="modal-title pull-left f-font f-bold">
      Line Item Change Requested
    </h3>
    <div
      type="button"
      aria-label="Close"
      (click)="closeReadOnlyNoteModal(lineItemCommentIndex)"
    >
      <i class="fa-regular fa-circle-xmark fa-xl"></i>
    </div>
  </div>
  <div class="modal-body">
    <!-- Show All Requests -->
    <table
      class="table table-striped table-borderless f-font"
      *ngIf="showAllLineItemRequests"
    >
      <thead class="f-bold font-size-13 all-req-modal-col-width">
        <tr>
          <th scope="col" class="td-w-6">Item #</th>
          <th scope="col" class="td-w-10">Model #</th>
          <th scope="col" class="td-w-20">Model Name</th>
          <th scope="col" class="ps-5">Open Qty</th>
          <th scope="col" class="">Unit Price</th>
          <th scope="col" class="td-w-35">Notes</th>
        </tr>
      </thead>
      <tbody class="align-middle">
        <ng-container
          *ngFor="let lineItem of lineItemCompValues; let i = index"
        >
          <tr *ngIf="lineItem">
            <!-- PO Item -->
            <th scope="row">{{ purchaseOrderList[i].poItem | number }}</th>
            <!-- Model # -->
            <td scope="row">{{ purchaseOrderList[i].modelNumber }}</td>
            <!-- Model Name -->
            <td scope="row">{{ purchaseOrderList[i].modelName }}</td>
            <!-- Open Quantity -->
            <td class="ps-5">
              <ng-container
                *ngIf="getEditigLineInfoAt(i, 'Field', 'openQuantity')"
              >
                <span>O: {{ purchaseOrderList[i].openQuantity }}</span
                ><br />
                <span>R: {{ lineItem.openQuantity.new }}</span>
              </ng-container>
            </td>
            <!-- Unit Price -->
            <td>
              <ng-container *ngIf="getEditigLineInfoAt(i, 'Field', 'unitPrice')"
                ><span class="text-nowrap"
                  >O:
                  {{
                    transformCurrencyValue(purchaseOrderList[i].netPrice)
                  }}</span
                ><br />
                <span span class="text-nowrap"
                  >R: {{ transformCurrencyValue(lineItem.unitPrice.new) }}</span
                ></ng-container
              >
            </td>
            <!-- Comments -->
            <td>
              <ng-container *ngIf="getEditigLineInfoAt(i, 'Field', 'comment')"
                ><p class="my-1 lh-sm">
                  {{ lineItem.comment }}
                </p></ng-container
              >
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <!-- Show Comment Requests -->
    <table
      class="table table-striped table-borderless all-req-modal-col-width f-font"
      *ngIf="!showAllLineItemRequests"
    >
      <thead class="f-bold font-size-13">
        <tr>
          <th scope="col" class="td-w-6">Item #</th>
          <th scope="col" class="td-w-10">Model #</th>
          <th scope="col" class="td-w-20">Model Name</th>
          <th scope="col" class="ps-5">Open Qty</th>
          <th scope="col" class="">Unit Price</th>
          <th scope="col" class="td-w-35">Notes</th>
        </tr>
      </thead>
      <tbody class="align-middle">
        <tr>
          <!-- Po Item -->
          <th scope="row">
            {{ purchaseOrderList[lineItemCommentIndex].poItem | number }}
          </th>
          <!-- Model # -->
          <td scope="row">
            {{ purchaseOrderList[lineItemCommentIndex].modelNumber }}
          </td>
          <!-- Model Name -->
          <td scope="row">
            {{ purchaseOrderList[lineItemCommentIndex].modelName }}
          </td>
          <!-- Open Quantity -->
          <td class="ps-5">
            <ng-container
              *ngIf="
                getEditigLineInfoAt(
                  lineItemCommentIndex,
                  'Field',
                  'openQuantity'
                )
              "
            >
              <span
                >O:
                {{ purchaseOrderList[lineItemCommentIndex].openQuantity }}</span
              ><br />
              <span
                >R:
                {{
                  getEditigLineInfoAt(
                    lineItemCommentIndex,
                    "Value",
                    "openQuantity"
                  ).new
                }}</span
              >
            </ng-container>
          </td>
          <!-- Unit Price -->
          <td>
            <ng-container
              *ngIf="
                getEditigLineInfoAt(lineItemCommentIndex, 'Field', 'unitPrice')
              "
              ><span class="text-nowrap"
                >O:
                {{
                  transformCurrencyValue(
                    purchaseOrderList[lineItemCommentIndex].netPrice
                  )
                }} </span
              ><br />
              <span span class="text-nowrap"
                >R:
                {{
                  transformCurrencyValue(
                    getEditigLineInfoAt(
                      lineItemCommentIndex,
                      "Value",
                      "unitPrice"
                    ).new
                  )
                }}</span
              ></ng-container
            >
          </td>
          <!-- Comments -->
          <td>
            <ng-container
              *ngIf="
                getEditigLineInfoAt(lineItemCommentIndex, 'Field', 'comment')
              "
              ><p class="my-1 lh-sm">
                {{
                  getEditigLineInfoAt(lineItemCommentIndex, "Value", "comment")
                }}
              </p></ng-container
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer justify-content-between border-top-0">
    <div class="ps-2 f-font lh-sm">
      <span>O: Original</span><br />
      <span>R: Requested</span>
    </div>
    <button
      class="f-btn f-black f-xs"
      (click)="closeReadOnlyNoteModal(lineItemCommentIndex)"
    >
      Close
    </button>
  </div>
</ng-template>

<!-- TOAST -->
<app-toast-alert
  #warningToast
  [selector]="'warningToast'"
  [title]="'Alert'"
  [description]="toastErrorText"
  [style]="'bg-warning'"
></app-toast-alert>
