<div class="d-flex">
  <span class="f-font f-bold ms-2">Invite List:</span>
  <span class="f-font mx-2">
    <small data-cy="invite_list-total_label"
      >{{
        invitedSuppliers ? invitedSuppliers.length : 0
      }}&nbsp;Total&nbsp;</small
    ></span
  >
  <!-- <show-more *ngIf="showInvitedDealers" [showArray]="invitedDealers" [showQty]="showQty"></show-more>&nbsp; -->
  <button
    class="f-btn btn-xs f-blue btn-std"
    (click)="showSelectorModal()"
    [class.has-error]="isRequired && touched && emptySelection"
    [disabled]="false"
    data-cy="invite_list-edit_btn"
  >
    <i class="fa-solid fa-pen fa-white"></i>
  </button>
</div>
<app-invite-list-selector-modal
  [isRequired]="isRequired"
  [invitedSuppliers]="invitedSuppliers"
  [isEditMode]="isEditMode"
  (saveSuppliersInvited)="onSaveDealersInvited($event)"
></app-invite-list-selector-modal>
