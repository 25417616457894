<ng-template #imageCropModal>
  <div class="modal-header">
    <h4 class="modal-title f-font">{{ "Crop Image" }}</h4>
    <button
      type="button"
      class="btn"
      data-dismiss="modal"
      aria-label="Close"
      *ngIf="!forceCrop"
      (click)="modalRef.hide()"
      data-cy="img_cropper-close_btn"
    >
      <i class="fa-solid fa-xmark"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-xs-12 col-xs-left">
        <div class="crop-area">
          <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [imageURL]="imageUrl"
            [maintainAspectRatio]="true"
            [aspectRatio]="aspectRatio"
            format="jpeg"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded($event)"
            (loadImageFailed)="loadImageFailed()"
            class="img-cropper img-cropper-width"
          ></image-cropper>
        </div>
      </div>
      <div class="col-xs-12 cropped-preview">
        <img [src]="croppedImage" />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="f-btn f-xs me-2"
      data-dismiss="modal"
      aria-label="Cancel"
      (click)="setCancelModal()"
      data-cy="img_cropper-cancel_btn"
    >
      {{ "Cancel" }}
    </button>
    <button
      type="button"
      class="f-btn f-xs me-2"
      data-dismiss="modal"
      aria-label="Close"
      *ngIf="!forceCrop"
      (click)="setOriginal()"
      data-cy="img_cropper-save_original_btn"
    >
      {{ "Save Without Changes" }}
    </button>
    <button
      type="button"
      class="f-btn btn-xs f-blue"
      data-dismiss="modal"
      (click)="setCroppedImg()"
      data-cy="img_cropper-save_cropped_btn"
    >
      {{ "Save Changes" }}
    </button>
  </div>
</ng-template>
