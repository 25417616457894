import { Injectable } from '@angular/core';
import { IdentityService } from '../common/identity.service';
import { FenderRoles, RoleType, SupplierRoles } from 'src/app/models/role';

@Injectable({
  providedIn: 'root',
})
export class RolesHelperService {
  currentUserRole: string = '';

  constructor() {}

  getRoleType(role: any) {
    const fenderRoles = Object.values(FenderRoles);
    const supplierRoles = Object.values(SupplierRoles);
    if (fenderRoles.includes(role)) {
      return RoleType.FMIC;
    } else if (supplierRoles.includes(role)) {
      return RoleType.SUPPLIER;
    }
  }
}
