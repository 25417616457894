import { Pipe, PipeTransform } from '@angular/core';
// import { PermissionsService } from '../services/users/permissions.service';

@Pipe({
    name: 'rolesFilter'
})
export class RolesFilterPipe implements PipeTransform {

    constructor(
        // private permissionsService: PermissionsService
    ) {}

    transform(roles: any = [], roleClass: 'Supplier'|'Fender') {

        // const selectedRoles = roleClass === 'Dealer' ?
        //     this.permissionsService.getDealerRoles() :
        //     this.permissionsService.getFenderRoles();

        return roles.filter(
            (role:any) => (role.category === roleClass)
        );
    }
}

