import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile-main-manu',
  templateUrl: './mobile-main-manu.component.html',
})
export class MobileMainManuComponent implements OnInit {
  @Output() emitCloseMenu = new EventEmitter<string>();
  @Input() menuArray!: any;
  @Input() customPagesArray!: any;
  @Input() supplierNumber: any;
  @Input() menuIsOpen: boolean = false;

  eventsIsCollapsed: boolean = true;

  constructor(private route: Router) {}

  ngOnInit(): void {
    this.setInitialCollapse();
  }

  onMenuItemClick(page: any) {
    switch (page.route) {
      case 'custom-pages':
        this.navigateToCustomPage(page);
        break;
      default:
        if (!page.subItems || !page.subItems.length) {
          this.route.navigate([page.route]);
          this.closeMenu();
        } else {
          this.toggleIsCollapsed(page);
        }
        break;
    }
  }

  navigateToCustomPage(page: any) {
    this.route.navigate([`/custom-pages/${page.customPageId}`], {
      queryParams: { pageIds: page.pagesId?.toString() },
    });
    this.closeMenu();
  }

  onSubItemMenuClick(menuItem: any, subItem: any) {
    switch (menuItem.route) {
      case 'events':
        this.route.navigate([`/events/${subItem.id}`], {
          queryParams: { pageIds: subItem?.pageIds.join() },
        });
        break;
      case 'purchase-orders':
        this.route.navigate([`/purchase-orders/${subItem.route}`]);
        break;
      default:
    }

    this.closeMenu();
  }

  setInitialCollapse() {
    this.menuArray.forEach((menuItem: any) => {
      menuItem.collapsed = false;
    });
  }

  toggleIsCollapsed(page: any) {
    page.collapsed = !page.collapsed;
  }

  closeMenu() {
    this.setInitialCollapse();
    this.emitCloseMenu.emit();
  }
}
