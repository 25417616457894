import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { HttpHelperService } from '../helper/http-helper.service';
import { IContactUsForm } from '../../models/contact-us';

@Injectable({
  providedIn: 'root',
})
export class ContactUsService {
  constructor(private httpHelperService: HttpHelperService) {}

  getContactUsTypes() {
    return this.httpHelperService.get(`/contact-us/issue-types`).pipe(
      catchError((err) => {
        throw new Error('Contact Us API. Details: ' + err.error.message);
      })
    );
  }

  submitContactForm(form: any) {
    console.log(form);
    if (!form) {
      return throwError(() => new Error('Parameters Must Be Provided'));
    }
    return this.httpHelperService
      .post(`/contact-us/${form.issueType}`, this.prepareForm(form))
      .pipe(
        catchError((err) => {
          throw new Error('Contact Us API. Details: ' + err.error.message);
        })
      );
  }

  private prepareForm(form: any, filename?: string | undefined) {
    if (!form) {
      return null;
    }
    const contactUsForm: IContactUsForm = {
      firstName: form.firstName,
      lastName: form.lastName || undefined,
      email: form.email,
      supplierNumber: form.supplierNumber,
      phoneNumber: form.phoneNumber || undefined,
      comments: form.comments || undefined,
    };
    if (form.issueType === 'purchase-orders') {
      contactUsForm.purchaseOrderNumber = form.purchaseOrderNumber;
    }
    if (form.issueType === 'invoice-payments') {
      contactUsForm.purchaseOrderNumber =
        form.invoicePaymentsForm.purchaseOrderNumber;
      contactUsForm.invoiceNumber = form.invoicePaymentsForm.invoiceNumber;
      contactUsForm.billOfLading = form.invoicePaymentsForm.billOfLading;
      contactUsForm.packingList = form.invoicePaymentsForm.packingList;
    }
    if (form.issueType === 'shipment-question') {
      contactUsForm.shipmentType = form.shipmentQuestionForm.shipmentType;
      contactUsForm.shipmentItem = form.shipmentQuestionForm.shipmentItem;
      contactUsForm.containLiBatteries =
        form.shipmentQuestionForm.containLiBatteries;
      contactUsForm.isDangerItem = form.shipmentQuestionForm.isDangerItem;
      contactUsForm.fromLocation = form.shipmentQuestionForm.fromLocation;
      contactUsForm.destinationLocation =
        form.shipmentQuestionForm.destinationLocation;
      contactUsForm.numberOfBoxes = form.shipmentQuestionForm.numberOfBoxes;
      contactUsForm.weight = form.shipmentQuestionForm.weight;
      contactUsForm.dimension = form.shipmentQuestionForm.dimension;
      contactUsForm.brands = form.shipmentQuestionForm.brands.toString();
    }
    console.log(contactUsForm);
    return contactUsForm;
  }
}
