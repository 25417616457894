<div class="container mt-5 padding-bottom max-width-browser-support">
  <div class="d-flex justify-content-center align-items-center">
    <div class="card f-font">
      <h3 class="card-header text-center">Important Notice</h3>
      <div class="card-body">
        <div class="alert alert-warning" role="alert">
          <i class="fa-solid fa-triangle-exclamation me-2"></i>
          This site will soon no longer support your browser version.
        </div>
        <p class="card-text">
          The browser you are currently using offers limited functionality on
          the Supplier Portal. <br />
          Your version: {{ browserVersion.name }} v{{ browserVersion.version }}
        </p>
        <p class="card-text">
          Please consider updating your browser to a newer version. You can find
          some browser install and update links below: <br />
          <a href="https://www.google.com/chrome/">Chrome</a>,
          <a href="https://support.apple.com/en_AU/downloads/safari">Safari</a>,
          <a href="https://www.opera.com/download">Opera</a>,
          <a href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a>,
          <a
            href="https://support.microsoft.com/en-us/microsoft-edge/download-the-new-microsoft-edge-based-on-chromium-0f4a3dd7-55df-60f5-739f-00010dba52cf"
            >Edge</a
          >
        </p>
        <p>
          Thank you for your understanding, <br />
          Supplier Portal Team
        </p>
      </div>
      <div class="card-footer d-flex justify-content-end">
        <button (click)="continue()" class="f-btn f-blue f-xs">
          Continue Anyway
        </button>
      </div>
    </div>
  </div>
</div>
