import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { CatalogService } from '../../../services/users/catalog.service';
import { AuthenticationService } from '../../../services/users/authentication.service';
import { Subscription } from 'rxjs';
import { RolesFilterPipe } from '../../../pipes/roles-filter.pipe';
import { IRole } from 'src/app/models/role';

@Component({
  selector: 'app-role-selector',
  templateUrl: './role-selector.component.html',
})
export class RoleSelectorComponent implements OnInit {
  @Input() splitRoles = false;
  @Input() rolesParent: any;
  @Input() preSelectRolesIds: any = [];
  @Input() isRequired = false;
  @Output() handleCheckRoles = new EventEmitter();

  public roles: any;
  public allRolesCheckbox: boolean = false;
  public supplierRolesCheckbox: boolean = false;
  public fenderRolesCheckbox: boolean = false;
  public rolesExpanded = {
    Dealer: true,
    Fender: true,
  };
  private sub: Subscription | undefined;

  rolesFilterPipe = new RolesFilterPipe();

  constructor(private catalogService: CatalogService) {}

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['rolesParent'] && changes['rolesParent'].currentValue) {
      if (!this.rolesParent.length && this.roles) {
        this.allRolesCheckbox = false;
        this.checkAllRolesToggle();
      }
    }

    if (
      changes['preSelectRolesIds'] &&
      changes['preSelectRolesIds'].currentValue
    ) {
      if (this.preSelectRolesIds.length && this.roles) {
      }
    }
  }

  ngOnInit() {
    this.loadRoles();
  }

  private async loadRoles() {
    this.sub = this.catalogService.getRolesCatalog().subscribe((roles) => {
      this.roles = roles;
      this.checkAllRolesToggle();
      if (this.preSelectRolesIds.length && this.roles) {
        this.preSelectRoles();
      }
    });
  }

  checkAllRolesToggle() {
    this.roles.forEach((role: IRole) => {
      role.checked = this.allRolesCheckbox;
    });
    if (this.splitRoles) {
      this.fenderRolesCheckbox = this.allRolesCheckbox;
      this.supplierRolesCheckbox = this.allRolesCheckbox;
    }
    this.roleChanged();
  }

  checkDealerFenderRolesToggle(roleClass: 'Supplier' | 'Fender') {
    this.rolesFilterPipe
      .transform(this.roles, roleClass)
      .forEach((role: IRole) => {
        role.checked =
          roleClass === 'Supplier'
            ? this.supplierRolesCheckbox
            : this.fenderRolesCheckbox;
      });
    this.checkSelectedRoles();
  }

  updateSupplierFenderCheckboxes() {
    this.supplierRolesCheckbox = this.rolesFilterPipe
      .transform(this.roles, 'Supplier')
      .every((role: IRole) => role.checked);
    this.fenderRolesCheckbox = this.rolesFilterPipe
      .transform(this.roles, 'Fender')
      .every((role: IRole) => role.checked);
  }

  checkSelectedRoles() {
    let allChecked = true;
    this.roles.forEach((role: IRole) => {
      if (!role.checked) {
        allChecked = false;
      }
    });
    if (allChecked) {
      this.allRolesCheckbox = true;
    } else {
      this.allRolesCheckbox = false;
    }
    if (this.splitRoles) {
      this.updateSupplierFenderCheckboxes();
    }

    this.roleChanged();
  }

  private roleChanged() {
    this.handleCheckRoles.emit(this.getSelectedRoles());
  }

  private getSelectedRoles() {
    const rolesArray: IRole[] = [];
    this.roles.forEach((role: IRole) => {
      if (role.checked) {
        rolesArray.push(role);
      }
    });
    return rolesArray;
  }

  private preSelectRoles() {
    this.preSelectRolesIds.forEach((item: any) => {
      this.roles.forEach((role: IRole) => {
        if (role.id == item.id) {
          role.checked = true;
        }
      });
    });
    this.checkSelectedRoles();
  }
}
function ngAfterViewInit() {
  throw new Error('Function not implemented.');
}
