<div class="pixel-stepper-container mt-4">
  <div class="pixel-stepper">
    <span class="f-font me-4">{{ "Padding between sections" }}:</span>
    <div class="d-flex flex-column">
      <div class="input-group">
        <button
          (click)="sub()"
          class="btn sub-button"
          data-cy="pixel_stepper-substract-btn"
        >
          -
        </button>
        <input
          #quantity
          type="number"
          name="qty"
          class="form-control pixel-stepper__input"
          data-cy="pixel_stepper-pixel-input"
          [ngModel]="currentValue"
          (ngModelChange)="validatePixels($event, quantity)"
        />
        <button
          (click)="add()"
          class="btn add-button"
          data-cy="pixel_stepper-add-btn"
        >
          +
        </button>
      </div>
      <div class="d-flex justify-content-center">
        <span class="pixel-stepper__bottom-label f-font">{{ "Pixels" }}</span>
      </div>
    </div>
  </div>
</div>
