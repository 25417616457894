<div
  class="p-2"
  *ngIf="fenderOptions.length || specialityOptions.length; else noResults"
>
  <!-- Select All -->
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      value=""
      [(ngModel)]="allOptionCheckbox"
      (change)="onToggleAllOptions()"
    />
    <p class="form-check-label f-bold f-font">Select All</p>
  </div>
  <!-- ================== Fender Toggle Collapse ================== -->
  <div
    class="d-flex align-items-center new-odd-select rounded p-2"
    *ngIf="fenderOptions.length"
  >
    <i
      class="fa-solid me-2"
      [ngClass]="fenderIsCollapsed ? 'fa-circle-plus' : 'fa-circle-minus'"
      (click)="fenderIsCollapsed = !fenderIsCollapsed"
    ></i>
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        [(ngModel)]="fenderOptionCheckbox"
        (change)="onToggleFenderOptions()"
      />
      <p class="form-check-label f-font mb-0">Fender</p>
    </div>
  </div>
  <!-- Fender Group Collapse -->
  <div
    id="collapseBasic"
    class="ms-3"
    [collapse]="fenderIsCollapsed"
    [isAnimated]="true"
  >
    <!-- Fender Options -->
    <ng-container *ngFor="let option of fenderOptions">
      <div class="form-check mt-2">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          [(ngModel)]="option.checked"
          (change)="onChangeCheckbox()"
        />
        <p class="form-check-label f-font m-0">{{ option.description }}</p>
      </div>
    </ng-container>
  </div>
  <!-- ================== Speciality Toggle Collapse ================== -->
  <div
    class="d-flex align-items-center new-odd-select rounded p-2 mt-2"
    *ngIf="specialityOptions.length"
  >
    <i
      class="fa-solid me-2"
      [ngClass]="specialityIsCollapsed ? 'fa-circle-plus' : 'fa-circle-minus'"
      (click)="specialityIsCollapsed = !specialityIsCollapsed"
    ></i>
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        [(ngModel)]="specialityOptionCheckbox"
        (change)="onToggleSpecialityOptions()"
      />
      <p class="form-check-label f-font mb-0">Speciality</p>
    </div>
  </div>
  <!-- Speciality Group Collapse -->
  <div
    id="collapseBasic"
    class="ms-3"
    [collapse]="specialityIsCollapsed"
    [isAnimated]="true"
  >
    <!-- Speciality Options -->
    <ng-container *ngFor="let option of specialityOptions">
      <div class="form-check mt-2">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          [(ngModel)]="option.checked"
          (change)="onChangeCheckbox()"
        />
        <p class="form-check-label f-font m-0">{{ option.description }}</p>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #noResults>
  <div class="d-flex justify-content-center align-items-center p-2">
    <p class="f-font m-0">No Results</p>
  </div>
</ng-template>
