import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IAnnouncement } from 'src/app/models/announcement';
import { AnnouncementsService } from 'src/app/services/announcements/announcements.service';
import { GoogleAnalyticsService } from 'src/app/services/common/google-analytics.service';

@Component({
  selector: 'app-site-messages',
  templateUrl: './site-messages.component.html',
})
export class SiteMessagesComponent implements OnInit {
  @Input() siteMessages: IAnnouncement[] = [];

  subscription: Subscription = new Subscription();

  announcements: IAnnouncement[] = [];
  showError: boolean = false;
  constructor(
    private announcementsService: AnnouncementsService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {}

  onClose(siteMessage: IAnnouncement) {
    if (!siteMessage) {
      return null;
    }
    siteMessage.isNew = false;
    this.googleAnalyticsService.trackEvent(
      'whats_new',
      'Acknowledge',
      siteMessage.title
    );
    this.subscription.add(
      this.announcementsService.seenAnnouncement(siteMessage.id).subscribe({
        next: () => {},
        error: (err) => {
          console.error(err);
        },
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
