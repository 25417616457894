<div
  class="d-flex align-items-center justify-content-center p-0 f-font"
  [ngClass]="{ 'border-secondary border-start border-1': separator }"
>
  <ng-container *ngIf="data && data!.count > 0; else blankSpace">
    <p *ngIf="data!.count > 0" class="my-0 mx-2">
      {{ data.title }} <span class="mx-1">|</span> {{ data.count }} Purchase
      Orders
    </p>
    <button class="btn btn-sm btn-danger mx-2 f-font" (click)="onClick(data)">
      View
    </button>
  </ng-container>
</div>
<!-- Empty Space -->
<ng-template #blankSpace>
  <button class="btn btn-sm mx-2 f-font opacity-0" disabled>&nbsp;</button>
</ng-template>
