<div class="notifications-dialog_container">
  <div class="blockscreen"></div>
  <div class="notifications-dialog">
    <div class="mb-3 mb-sm-0">
      <h1 class="f-font f-bold">{{ title }}</h1>
      <h3 class="f-font">{{ description }}</h3>
    </div>
    <div class="d-flex justify-content-end">
      <button
        class="f-btn f-xs me-2"
        (click)="onConfirmation(false)"
        [attr.data-cy]="'notifications-dialog-decline'"
      >
        No
      </button>
      <button
        class="f-btn f-xs"
        (click)="onConfirmation(true)"
        [attr.data-cy]="'notifications-dialog-confirm'"
      >
        Yes
      </button>
    </div>
  </div>
</div>
