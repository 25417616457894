<span>{{ showString }}</span>
<span *ngIf="!showAll && hiddenQty > 0">
  <a
    class="f-font cursor-pointer show-more-less-button"
    (click)="setShowAll(true)"
    >...+{{ hiddenQty }} <span>{{ "More" }}</span></a
  >
</span>
<span *ngIf="showAll && hiddenQty > 0">
  <a
    class="f-font cursor-pointer show-more-less-button"
    (click)="setShowAll(false)"
    >...<span>{{ "Less" }}</span></a
  >
</span>
