<section class="widgets-manager">
  <div class="row widgets-container">
    <ng-container>
      <app-widget>
          <div>
              <!-- Dashboard Widgets -->
              here goes  widget
              <!-- End Finance Widgets -->
          </div>
        </app-widget>
  </ng-container>
  </div>
</section>
