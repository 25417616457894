<!-- (onProfile) - SET -->
<div *ngIf="isProfile && !editing" class="edit-select-position">
  <a
    class="f-font text-decoration-none cursor-pointer"
    (click)="setEditing()"
    >{{ selectedValuesList ? selectedValuesList : "Select" }}</a
  >
</div>
<!-- Editing -->
<ng-container *ngIf="!isProfile || (isProfile && editing)">
  <div class="btn-group w-100" dropdown [insideClick]="true">
    <!-- Select Input -->
    <div class="input-group" [ngClass]="{ 'input-group-sm': isSmall }">
      <input
        class="form-control bg-white input-with-chevron cursor-default text-dark"
        value=" {{ selectedValuesList ? selectedValuesList : 'Select' }}"
        [ngClass]="{ 'po-multi-select ': false }"
        readonly
        disabled
        dropdownToggle
      />
      <!-- (onProfile) - Select Buttons -->
      <ng-container *ngIf="isProfile">
        <button class="btn f-btn f-blue" type="button" (click)="saveEdition()">
          Save
        </button>
        <button
          class="btn btn-outline-secondary"
          type="button"
          (click)="cancelEditing()"
        >
          Cancel
        </button>
      </ng-container>
    </div>
    <!-- Dropdown Options -->
    <ul
      id="dropdown-basic"
      *dropdownMenu
      class="dropdown-menu w-100"
      role="menu"
      aria-labelledby="button-basic"
    >
      <ng-container *ngFor="let checkbox of checkboxesObject; let last = last">
        <li role="menuitem" [hidden]="checkbox.hidden">
          <div class="d-flex align-items-center px-3">
            <div>
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="email-opt-in-{{ checkbox.name }}"
                [checked]="checkbox.status"
                (change)="onChangeCheckbox(checkbox.value, $event)"
              />
            </div>
            <span
              class="form-check-label ms-2 f-font lh-1"
              for="flexCheckDefault"
            >
              {{ checkbox.name }}
            </span>
          </div>
          <div>
            <hr
              *ngIf="!last"
              class="my-2 mx-2"
              [ngClass]="{ 'po-multi-select-separator ': true }"
            />
          </div>
        </li>
      </ng-container>
      <!-- [START] Loading -->
      <ng-container *ngIf="loadingOptions">
        <div class="d-flex justify-content-center new-ui-small-img py-2">
          <app-fender-tone-loading-div
            [inline]="true"
            [black]="true"
          ></app-fender-tone-loading-div>
        </div>
      </ng-container>
      <!-- [END] Loading -->
    </ul>
  </div>
</ng-container>
