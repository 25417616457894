<div class="modal-header border-bottom-0">
  <h3 class="modal-title pull-left f-font f-bold">Request Date Change</h3>
  <div type="button" aria-label="Close" (click)="closeDateChangeModal()">
    <i class="fa-regular fa-circle-xmark fa-xl"></i>
  </div>
</div>
<!-- Modal Body -->
<div class="modal-body">
  <div class="row">
    <div class="col-4">
      <div>
        <p class="f-font f-bold">Original Ship Date</p>
      </div>
      <div>
        <p class="f-font f-bold">New Ship Date</p>
      </div>
    </div>
    <div class="col-8">
      <div>
        <p class="f-font">
          {{ originalShipDate | date : userDateFormat }}
        </p>
      </div>
      <div>
        <p class="f-font d-inline me-3">
          <span [class.empty-section-text]="!requestedDateChange">
            {{
              requestedDateChange
                ? (requestedDateChange | date : userDateFormat)
                : "Select Date"
            }}</span
          >
        </p>
        <!-- DATE INPUT -->
        <input
          type="text"
          [bsConfig]="datePickerConfig"
          [formControl]="dateChangeControl"
          class="form-control invisible-date-input pb-2 d-inline"
          #dp="bsDatepicker"
          bsDatepicker
          [minDate]="minDate"
          (bsValueChange)="onDateValueChange($event)"
        />
        <button class="f-btn btn-xs f-blue btn-std" (click)="dp.toggle()">
          <i class="fa-regular fa-calendar fa-white"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Modal Footer -->
<div class="modal-footer border-top-0">
  <div class="d-flex">
    <button class="f-btn f-black f-xs me-2" (click)="closeDateChangeModal()">
      Cancel
    </button>
    <button
      class="f-btn f-blue f-xs"
      [disabled]="!dateChangeControl.valid || dateChangeControl.pristine"
      (click)="saveDateChangeRequest()"
    >
      <i class="fa-regular fa-paper-plane"></i>
      <span class="me-2">Submit</span>
    </button>
  </div>
</div>
