export interface IPurchaseOrdersListResponse {
  poList: IPurchaseOrder[];
  total: number;
  totalOpenQuantity: number;
  totalOpenValue: string;
}

export interface IPurchaseOrder {
  id?: string;
  poNumber?: string;
  poItem?: string;
  scheduleLine?: string;
  createDate?: string;
  supplierNumber?: string;
  supplierName?: string;
  modelNumber?: string;
  netPrice?: string;
  modelName?: string;
  plant?: string;
  quantity?: string;
  openQuantity?: string;
  openValue?: string;
  shippedQuantity?: string;
  deliveryDate?: string;
  originalShipDate?: string;
  currentShipDate?: string;
  confirmDate?: string;
  destinationName?: string;
  destinationAddress?: string;
  destinationCity?: string;
  destinationRegion?: string;
  destinationZip?: string;
  destinationCountry?: string;
  customerPickup?: string;
  status?: PoStatus;
  note?: string;
  emailId?: string;
  purchaserName?: string;
  currency?: string;
  statusUserEmail?: string;
  statusCreatedAt?: string;
  statusUserName?: string;
  productionMonth?: string;
  poValue?: string;
  productLines?: number[];
}

export interface IDestinationData {
  city: string;
  name: string;
  region: string;
  country: string;
  zip: string;
  address: string;
  emailId: string;
  purchaserName: string;
}

export interface IPoStatusData {
  status: string;
  note: string;
  updatedOn: string;
  updatedByName: string;
  updatedByEmail: string;
  requestedShipDate: string;
  items: IPoItemStatusChanged[];
}

export interface IPoItemStatusChanged {
  poItem: string;
  openQuantity: number;
  netPrice: number;
  comment: string;
}

export interface IStatusParams {
  note: string;
  requestedShipDate: string;
  items: ILineItemParam[];
}

export interface ILineItemParam {
  poItem: string;
  openQuantity: string;
  netPrice: string;
  comment: string;
}

export interface ILineItemCompValues {
  openQuantity?: { original: string; new: string };
  unitPrice?: { original: string; new: string };
  comment?: string;
}

export interface IPoAlertsResponse {
  supplierResponse?: number;
  updateNeeded?: number;
  upcomingShipDate?: number;
}
export interface IPoAlertsObject {
  supplierResponseRequired?: IPoAlertsForUi;
  updateNeeded?: IPoAlertsForUi;
  upcomingShipDate?: IPoAlertsForUi;
}
export interface IPoAlertsForUi {
  title: string;
  key: string;
  count: number;
}

export interface IPoPurchaserResponse {
  emailId: string;
  username?: string;
  purchaserName?: string;
}
export interface IPoFilterParams {
  supplierNumber?: string;
  search?: string;
  createDate?: string;
  plant?: string[];
  status?: string;
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortType?: string;
  groupBy?: string;
  poNumber?: string | null;
  productionMonth?: string;
  originalShipDate?: string;
  currentShipDate?: string;
  emailId?: string;
  alert?: string;
  productLine?: string;
}

// ======= ENUMS ========

export enum CurrencyCode {
  USD = 'USD',
  EUR = 'EUR',
  JPY = 'JPY',
  RMB = 'RMB',
  CNY = 'CN¥',
}

export enum PoStatus {
  ACCEPT = 'Accepted',
  REJECT = 'Rejected',
  UPDATE = 'Updated',
  NEW = 'New',
  REQ_DATE_CHANGE = 'Requested Date Change',
  REQ_LINE_ITEM_CHANGE = 'Requested Line Item Change',
}

export enum PoStatusEndpointValue {
  ACCEPT = 'accept',
  REJECT = 'reject',
  UPDATE = 'update',
  NEW = 'new',
  REQ_DATE_CHANGE = 'request-date-change',
  REQ_LINE_ITEM_CHANGE = 'request-line-item-change',
}

export enum PurchaseOrdersRoutes {
  BY_PO = 'by-po',
  BU_MODEL = 'by-model',
  SUMMARY = 'summary',
  DETAILS = ':poNumber',
}

export enum PurchaseOrdersAlerts {
  SUPPLIER_RESPONSE = 'supplier-response',
  UPDATE_NEEDED = 'update-needed',
  UPCOMMING_SHIP_DATE = 'upcoming-ship-date',
}
export enum PurchaseOrdersAlertsTitles {
  SUPPLIER_RESPONSE = 'Supplier Response Required',
  UPDATE_NEEDED = 'Update Needed',
  UPCOMMING_SHIP_DATE = 'Upcoming Ship Date',
}

// CONSTS
export const purchaseOrdersAlertsArray = [
  'supplier-response',
  'update-needed',
  'upcoming-ship-date',
];
