import { Component, Input, OnInit } from '@angular/core';
import { VideoService } from 'src/app/services/common/video.service';

const DEFAULT_HEIGHT = '470';
const DEFAULT_WIDTH = '900';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
})
export class VideoPlayerComponent implements OnInit {
  @Input() videoTitle: string = '';
  @Input() videoCategory = 'Featured Video';
  @Input() url: string = '';
  @Input() autoplay: boolean = false;
  @Input() width: string = '';
  @Input() height: string = '';

  loading: boolean = true;
  videoId: string = '';
  isPlayList: boolean = false;
  serviceType: string = '';

  constructor(private videoService: VideoService) {}

  ngOnInit(): void {
    this.renderVideo();
  }

  ngOnChanges(changes: any) {
    if (changes.width) {
      this.width = this.width || DEFAULT_WIDTH;
    }
    if (changes.height) {
      this.height = this.height || DEFAULT_HEIGHT;
    }
    if (changes.url) {
      if (changes.url.currentValue) {
        this.renderVideo();
      } else {
        this.clearRender();
      }
    }
  }

  clearRender() {
    this.loading = false;
    this.getVideoTemplate();
  }

  renderVideo() {
    this.loading = true;
    this.width = this.width || DEFAULT_WIDTH;
    // this.serviceType =
    //   this.serviceType || this.videoService.getServiceVideo(this.url);
    this.getVideoTemplate(this.serviceType);
  }

  getVideoTemplate(serviceType?: any) {
    const videoTemplate = {
      cloudfront: () => {
        this.videoService.getDefaultTemplate(
          this.url,
          this.height,
          this.width,
          this.autoplay,
          this.videoCategory,
          this.videoTitle
        );
        this.loading = false;
      },
    };
    return videoTemplate['cloudfront']();
  }
}
