import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  IPoAlertsObject,
  PurchaseOrdersAlerts,
  purchaseOrdersAlertsArray,
} from 'src/app/models/purchase-orders';
import { PurchaseOrdersService } from '../../../services/purchase-orders/purchase-orders.service';
import {
  IPoAlertsForUi,
  IPoAlertsResponse,
} from 'src/app/models/purchase-orders';
import { Subscription } from 'rxjs';
import { PoHelperService } from '../../../services/purchase-orders/po-helper.service';
import { GoogleAnalyticsService } from '../../../services/common/google-analytics.service';

@Component({
  selector: 'app-po-alert-banner',
  templateUrl: './po-alert-banner.component.html',
  styles: [],
})
export class PoAlertBannerComponent implements OnInit {
  @Output() onHasAlerts = new EventEmitter<boolean>();
  @Output() onClickView = new EventEmitter();

  public hasAlerts: boolean = false;
  public alertsObject: IPoAlertsObject = {};

  subscription: Subscription = new Subscription();

  constructor(
    private purchaseOrdersService: PurchaseOrdersService,
    private poHelperService: PoHelperService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {
    this.getSummaryAlerts();
  }

  getSummaryAlerts() {
    const alertArray = Object.values(purchaseOrdersAlertsArray);
    alertArray.forEach((alertName: string) => {
      this.subscription.add(
        this.purchaseOrdersService.getSummaryAlerts(alertName).subscribe({
          next: (response: IPoAlertsResponse) => {
            this.createAlertArray(alertName, Object.values(response)[0]);
          },
          complete: () => {
            this.ifShowBanner();
          },
        })
      );
    });
  }

  createAlertArray(alertName: string, alertCount: number) {
    switch (alertName) {
      case PurchaseOrdersAlerts.SUPPLIER_RESPONSE:
        this.alertsObject.supplierResponseRequired = this.createObjectForUI(
          alertName,
          alertCount
        );
        break;
      case PurchaseOrdersAlerts.UPDATE_NEEDED:
        this.alertsObject.updateNeeded = this.createObjectForUI(
          alertName,
          alertCount
        );
        break;
      case PurchaseOrdersAlerts.UPCOMMING_SHIP_DATE:
        this.alertsObject.upcomingShipDate = this.createObjectForUI(
          alertName,
          alertCount
        );
        break;
    }
  }

  createObjectForUI(alertName: string, alertCount: number) {
    return {
      title: this.poHelperService.returnAlertTitle(alertName),
      key: alertName,
      count: alertCount,
    };
  }

  ifShowBanner() {
    this.hasAlerts = !(
      this.alertsObject.supplierResponseRequired!.count === 0 &&
      this.alertsObject.updateNeeded!.count === 0 &&
      this.alertsObject.upcomingShipDate!.count === 0
    );

    this.onHasAlerts.emit(this.hasAlerts);
  }

  onFilterByAlert(alert: IPoAlertsForUi) {
    this.setGA4AlertPOEvent(alert.title);
    this.onClickView.emit(alert.key);
  }

  setGA4AlertPOEvent(title: string): void {
    this.googleAnalyticsService.trackEvent('purchase_orders', 'View', title);
  }
}
