<div class="main-menu-list-mobile">
  <div id="mobileMenuCollapse" [collapse]="!menuIsOpen" [isAnimated]="true">
    <div class="d-flex">
      <ul>
        <ng-container *ngFor="let menuItem of menuArray">
          <li
            class="d-flex justify-content-between"
            [ngClass]="{
              'has-events':
                menuItem.subItems &&
                menuItem.subItems.length &&
                menuItem.name === 'Events'
            }"
            (click)="onMenuItemClick(menuItem)"
            *ngIf="
              (!menuItem.needsSupplier ||
                (menuItem.needsSupplier && supplierNumber)) &&
              menuItem.visible
            "
          >
            <span class="main-menu-list-mobile-lables f-font">
              {{ menuItem.name }}
            </span>
            <i
              class="fa-solid fa-white m-0 mt-1 me-4"
              *ngIf="menuItem.subItems && menuItem.subItems.length > 1"
              [ngClass]="eventsIsCollapsed ? 'fa-sort-down' : 'fa-sort-up'"
            ></i>
          </li>
          <!-- Sub Items -->
          <ng-container *ngIf="menuItem.subItems">
            <div
              id="subItemsCollapse"
              [collapse]="!menuItem.collapsed"
              [isAnimated]="true"
            >
              <ul class="main-menu-subitem-list-mobile">
                <ng-container *ngFor="let subItem of menuItem.subItems">
                  <ng-container *ngIf="subItem.visible">
                    <li (click)="onSubItemMenuClick(menuItem, subItem)">
                      <span class="main-menu-list-mobile-lables f-font">
                        {{ subItem.title }}
                      </span>
                    </li>
                  </ng-container>
                </ng-container>
              </ul>
            </div>
          </ng-container>
        </ng-container>
        <!-- Custom Pages Option -->
        <ng-container *ngIf="customPagesArray.length">
          <ng-container *ngFor="let customPage of customPagesArray">
            <ng-container *ngIf="customPage.visible">
              <app-mobile-custom-page-option
                [menuItem]="customPage"
                (navigatePage)="onMenuItemClick($event)"
              ></app-mobile-custom-page-option>
            </ng-container>
          </ng-container>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
