<div
  class="btn-group w-100"
  dropdown
  *ngIf="!loadingStatusChange; else statusLoader"
>
  <!-- Select Input -->
  <div class="input-group">
    <input
      class="form-control bg-white input-with-chevron cursor-default text-dark table-min-width-90"
      value=" {{
        selectedOption
          ? formatTextValueForSelect(selectedOption.text)
          : 'Select'
      }}"
      [ngClass]="{
        'form-control-sm': isSmall,
        'new-ui-small': isSmall
      }"
      size="20"
      readonly
      disabled
      dropdownToggle
    />
  </div>
  <!-- Dropdown Options -->
  <div
    *dropdownMenu
    class="dropdown-menu new-ui-select-dropdown overflow-auto w-100 p-1"
  >
    <div class="list-group">
      <ng-container *ngFor="let item of options; let odd = odd">
        <a
          *ngIf="selectableStatus(item.value)"
          class="list-group-item list-group-item-action border-0 rounded f-font text-decoration-none text-dark cursor-pointer"
          [ngClass]="{
            'new-odd-select ': !odd,
            'new-ui-select-selected': item.selected,
            'new-ui-small': isSmall
          }"
          (click)="onChangeStatus(item)"
          >{{ item.text || item.value }}</a
        >
      </ng-container>
    </div>
  </div>
</div>

<!-- Loader -->
<ng-template #statusLoader>
  <div class="d-flex justify-content-center new-ui-small-img">
    <app-fender-tone-loading-div
      [black]="true"
      [inline]="true"
    ></app-fender-tone-loading-div>
  </div>
</ng-template>

<!-- Toast - [Error] -->
<app-toast-alert
  #errorToast
  [selector]="'error'"
  [title]="'Error'"
  [description]="'Something wrong happend. The status was not changed'"
  [style]="'bg-warning'"
></app-toast-alert>

<!-- Modal -->
<ng-template #noteModal>
  <app-note-modal
    [poNumber]="poInfo.poNumber"
    (onSaveNote)="onSaveReject($event)"
    (onCloseNoteModal)="closeModal()"
  ></app-note-modal>
</ng-template>

<ng-template #changeDateModal>
  <app-date-change-modal
    [originalShipDate]="poInfo.originalShipDate"
    (onSaveDateChangeRequest)="onSaveDateChange($event)"
    (conCloseModal)="closeModal()"
  ></app-date-change-modal>
</ng-template>
