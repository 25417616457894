import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { fromEvent, Subscription } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-image-crop-modal',
  templateUrl: './image-crop-modal.component.html',
})
export class ImageCropModalComponent implements OnInit {
  @Input() forceCrop = false;
  @Input() imageInput!: HTMLInputElement;
  @Input() aspectRatio: number = 1.22;
  @Input() imageUrl?: string;

  @Output() change = new EventEmitter();

  maxSize = 1000000;

  maxSizeError: boolean = false;
  invalidFormatError: boolean = false;
  originalImage: string = '';

  imageChangedEvent: any;

  croppedImage: any = '';
  inputImage: string = '';

  modalRef!: BsModalRef;

  private defaultTexts: { [key: string]: string } = {
    'Crop Image': 'Crop Image',
    Cancel: 'Cancel',
    'Save Without Changes': 'Save Without Changes',
    'Save Changes': 'Save Changes',
  };

  translatedText: { [key: string]: string } = this.defaultTexts;
  private subscriptions: Subscription = new Subscription();

  @ViewChild('imageCropModal', { static: true })
  imageCropModal!: TemplateRef<ElementRef>;

  constructor(private modalService: BsModalService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['imageInput'] && changes['imageInput'].currentValue) {
      this.subscriptions.add(
        fromEvent(this.imageInput, 'input').subscribe((event: any) => {
          this.imageChangedEvent = event;
          this.loadOriginalImage(event.currentTarget.files[0]);
        })
      );
    }
  }

  ngOnInit() {}

  show() {
    this.modalRef = this.modalService.show(this.imageCropModal, {
      backdrop: 'static',
      keyboard: false,
    });
  }

  loadOriginalImage(file: File) {
    if (file) {
      const fileReader = new FileReader();
      this.maxSizeError = false;

      this.subscriptions.add(
        fromEvent(fileReader, 'load').subscribe((loadEvent: any) => {
          this.originalImage = (<FileReader>loadEvent.target).result as string;
        })
      );

      fileReader.readAsDataURL(file);
    }
  }

  setOriginal() {
    this.change.emit(this.originalImage);
    this.modalRef.hide();
  }

  setCroppedImg() {
    this.change.emit(this.croppedImage);
    this.modalRef.hide();
  }

  setCancelModal() {
    this.imageInput.value = '';
    this.modalRef.hide();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event ? event.base64 : '';
  }

  imageLoaded(image: LoadedImage) {
    if (this.imageUrl) {
      this.originalImage = image.original.base64;
    }
  }

  loadImageFailed() {
    this.invalidFormatError = true;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
