<div class="btn-group w-100" dropdown [insideClick]="true">
  <!-- Select Input -->
  <div class="input-group">
    <input
      class="form-control bg-white input-with-chevron cursor-default text-dark text-truncate"
      value=" {{ filterPlaceholder }}"
      readonly
      disabled
      dropdownToggle
    />
  </div>
  <!-- Dropdown Options -->
  <ul
    id="dropdown-basic"
    *dropdownMenu
    class="dropdown-menu dropdown-menu-right dropdown-menu-250"
    role="menu"
  >
    <app-product-line-checkbox
      *ngIf="!loadingOptions"
      #productLineCheckbox
      [fenderOptions]="fenderOptions"
      [specialityOptions]="specialityOptions"
      (checkboxChange)="onChangeCheckbox($event)"
    ></app-product-line-checkbox>
    <!-- [START] Loading -->
    <ng-container *ngIf="loadingOptions">
      <div class="d-flex justify-content-center new-ui-small-img py-2">
        <app-fender-tone-loading-div
          [inline]="true"
          [black]="true"
        ></app-fender-tone-loading-div>
      </div>
    </ng-container>
    <!-- [END] Loading -->
  </ul>
</div>
