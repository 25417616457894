<div
  class="toast-container position-fixed pt-5 pe-1 pe-sm-3 top-0 end-0"
  id="toastPlacement"
>
  <div
    id="toastElement"
    class="toast bg-success text-white f-font"
    [class]="style"
  >
    <div class="toast-body">
      <h2>
        <label class="me-auto text-white">{{ title }}</label>
      </h2>
      <div class="d-flex align-items-center">
        <span class="me-2">{{ description }}</span>
        <i class="fa-solid fa-check fa-lg"></i>
      </div>
    </div>
  </div>
</div>
