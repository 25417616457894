<div class="container-fluid py-5">
  <div class="card py-5 px-4 lookup">
    <!-- Search Section -->
    <div class="row mb-3">
      <div class="col-12 col-md-5">
        <div class="d-flex align-items-center position-relative">
          <i class="fa-solid fa-barcode fa-2xl me-3"></i>
          <div class="form-group w-100 d-flex">
            <div class="input-group">
              <input
                #search
                type="text"
                class="form-control f-font"
                data-cy="lookup_page-search_input"
                [formControl]="searchQueryControl"
                placeholder="Scan or Type Model/SKU # or UPC"
                (keyup.enter)="navigateToMaterial(searchQueryControl.value)"
              />
            </div>
            <button
              class="f-btn f-xs f-blue ms-2"
              data-cy="lookup_page-search_btn"
              (click)="navigateToMaterial(searchQueryControl.value)"
            >
              <i class="fa-solid fa-magnifying-glass"></i>Search
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-7 mt-3 mt-md-0"></div>
    </div>

    <!-- ====== Results Section ====== -->
    <!-- BEFORE SERACH -->
    <ng-container
      *ngIf="!selectedProduct && !noResultsWarning && !loadingMaterial"
    >
      <div class="d-flex justify-content-center mt-5">
        <div class="py-5 my-5 text-center f-font">
          <p>Scan or Type to start search</p>
          <i class="fa-solid fa-magnifying-glass fa-2xl fa-silver-grey"></i>
        </div>
      </div>
    </ng-container>
    <!-- Loading -->
    <ng-container *ngIf="loadingMaterial">
      <div class="d-flex justify-content-center mt-5">
        <div class="py-5 my-5 text-center f-font">
          <app-fender-tone-loading-div
            [black]="true"
          ></app-fender-tone-loading-div>
        </div>
      </div>
    </ng-container>
    <!-- NO RESULTS -->
    <ng-container *ngIf="!selectedProduct && noResultsWarning">
      <div class="d-flex justify-content-center mt-5">
        <div class="py-5 my-5 text-center f-font">
          <p>No Results</p>
          <i class="fa-solid fa-magnifying-glass fa-2xl fa-silver-grey"></i>
        </div>
      </div>
    </ng-container>
    <!-- RESULT -->
    <ng-container *ngIf="selectedProduct && !loadingMaterial">
      <div class="row my-4">
        <!-- Name & Image -->
        <div class="col-12 my-4">
          <!-- <div class="f-font text-center mb-4">
            <h3 class="f-uppercase f-bold mb-1">
              {{ selectedProduct.productDisplayName }}
            </h3>
            <p class="m-0">
              SAP NAME: {{ selectedProduct.productSpecs.sapName }}
            </p>
            <p class="m-0">SKU: {{ selectedProduct.selectedSku.skuNumber }}</p>
          </div> -->
          <app-image-slideshow
            [images]="selectedProduct.selectedSku.images"
            [lookupArrangement]="true"
            [mainMaterialInfo]="mainMaterialInfo"
            (thumbnailClick)="thumbnailClick($event)"
            (zoomClick)="zoomClick($event)"
          ></app-image-slideshow>
        </div>
        <div class="col-12">
          <div class="d-flex justify-content-lg-center my-5 position-relative">
            <p class="f-font f-uppercase f-bold m-0">Specs</p>
            <div class="position-absolute end-0" *ngIf="selectedProduct">
              <button
                class="f-btn f-blue f-xs"
                data-cy="lookup_page-download_btn"
                (click)="
                  downloadMaterialSpecs(selectedProduct.selectedSku.skuNumber)
                "
                [disabled]="loadingDownload.loader"
              >
                <i class="f-icon f-download-arrow f-white"></i>
                Download
                <ng-container *ngIf="loadingDownload.loader">
                  <app-fender-tone-loading-div
                    class="ms-2"
                    [inline]="true"
                  ></app-fender-tone-loading-div>
                </ng-container>
              </button>
            </div>
          </div>
          <div class="row f-font">
            <!-- ========= RIGHT COLUMN ========== -->
            <div class="col-12 col-xl-6">
              <!-- GENERAL -->
              <app-product-specs
                [title]="'General'"
                [icon]="'general-icon.svg'"
                [categorySpecs]="categorySpecs.General"
                [productSpecs]="selectedProduct.productSpecs"
                [background]="true"
              ></app-product-specs>
              <!-- Neck -->
              <app-product-specs
                [title]="'Neck'"
                [icon]="'neck-icon.svg'"
                [categorySpecs]="categorySpecs.Neck"
                [productSpecs]="selectedProduct.productSpecs"
              ></app-product-specs>
              <!-- Body -->
              <app-product-specs
                [title]="'Body'"
                [icon]="'body-icon.svg'"
                [categorySpecs]="categorySpecs.Body"
                [productSpecs]="selectedProduct.productSpecs"
              ></app-product-specs>
              <!-- Tubes -->
              <app-product-specs
                [title]="'Tubes'"
                [icon]="'tubes-icon.svg'"
                [categorySpecs]="categorySpecs.Tubes"
                [productSpecs]="selectedProduct.productSpecs"
              ></app-product-specs>
              <!-- Measurements -->
              <app-product-specs
                [title]="'Measurements'"
                [icon]="'meassurements-icon.svg'"
                [categorySpecs]="categorySpecs.Measurements"
                [productSpecs]="selectedProduct.productSpecs"
                [background]="true"
              ></app-product-specs>
            </div>
            <!-- ========= LEFT COLUMN ========== -->
            <div class="col-12 col-xl-6">
              <!-- Hardware -->
              <app-product-specs
                [title]="'Hardware'"
                [icon]="'hardware-icon.svg'"
                [categorySpecs]="categorySpecs.Hardware"
                [productSpecs]="selectedProduct.productSpecs"
              ></app-product-specs>
              <!-- Electronics -->
              <app-product-specs
                [title]="'Electronics'"
                [icon]="'electronics-icon.svg'"
                [categorySpecs]="categorySpecs.Electronics"
                [productSpecs]="selectedProduct.productSpecs"
              ></app-product-specs>
              <!-- Speakers -->
              <app-product-specs
                [title]="'Speakers'"
                [icon]="'speakers-icon.svg'"
                [categorySpecs]="categorySpecs.Speakers"
                [productSpecs]="selectedProduct.productSpecs"
              ></app-product-specs>
              <!-- Accessories -->
              <app-product-specs
                [title]="'Accessories'"
                [icon]="'accesories-icon.svg'"
                [categorySpecs]="categorySpecs.Accessories"
                [productSpecs]="selectedProduct.productSpecs"
                [background]="true"
              ></app-product-specs>
              <!-- Miscellaneous -->
              <app-product-specs
                [title]="'Miscellaneous'"
                [icon]="'miscellaneous-icon.svg'"
                [categorySpecs]="categorySpecs.Miscellaneous"
                [productSpecs]="selectedProduct.productSpecs"
                [background]="true"
              ></app-product-specs>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<!-- ============= Toast Alerts ============ -->
<!-- Error Toast -->
<app-toast-alert
  #noDocumentFoundToast
  [selector]="'error'"
  [title]="'Alert'"
  [description]="toastErrorText"
  [style]="'bg-warning'"
></app-toast-alert>
