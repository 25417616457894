<div class="col-xs-12 text-center">
  <div class="iframe-container">
    <!-- 1. The <iframe> (and video player) will replace this <div> tag. -->
    <div id="player"></div>
  </div>
</div>
<div class="row loading" [hidden]="!loading">
  <div class="col-xs-12">
    <span>Loadng</span>
  </div>
</div>
